// RequiredLabel.js
import React from "react";

const RequiredLabel = ({ label }) => {
  return (
    <>
      {label}
      <span
        style={{margin:'0px', padding:'2px' ,color:'#FF0000' }}
      >
        *
      </span>
    </>
  );
};

export default RequiredLabel;
