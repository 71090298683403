import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from "react";

// @mui material components
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";

// Images & Icon
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";

// Formik & Yup React Form Validation
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

// Material Dashboard 2 React components
import FileUploader from "components/MDFileUpload";
import Loader from "components/MDLoader";

// Apollo Client
import { useLazyQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

// Custom Context
import { useNotification, AuthContext } from "context";

// Graphql query & mutation
import {
  GET_VENDOR_TAB_DETAILS_BY_ID,
  CREATE_BRAND_METADATA,
  UPDATE_BRAND_METADATA,
} from "services/brand-service";

// Yup Form Validation Schema
const validationSchema = yup.object().shape({
  address1: yup.string().required("Address 1 is required"),
  // address2: yup.string().required("Address 2 is required "),
  addressProof: yup.string().required("Address Proof is required"),
  city: yup.string().required("City Name is required"),
  state: yup.string().required("State Name is required"),
  pincode: yup
    .string()
    .required("Pincode is required")
    .max(6, "Maximum 6 characters allowed")
    .matches(/^\d+$/, "Pincode must be a number")
    .matches(/^[1-9]{1}[0-9]{5}$/, "Invalid Pincode"),
  addressProofPath: yup.string().required("Address Proof image is required"),
  addressProofUrl: yup.string().required("Address Proof image is required"),
  serviceProvided: yup.string().required("Product/Service is required"),
  companyType: yup.string().required("Type of Company is required"),
  panCardNo: yup
    .string()
    .required("PAN Card No. is required")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format"),
  panCardPath: yup.string().required("Pan Card Image is required"),
  panCardUrl: yup.string().required("Pan Card Image is required"),
  gstNo: yup
    .string()
    .required("GST No. is Required")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST Number format"
    ),
  gstPath: yup.string().required("GST Proof Image is required"),
  gstUrl: yup.string().required("GST Proof Image is required"),
  tinNo: yup.string().optional("TIN No. is required"),
  tinPath: yup.string().optional("TIN Proof Image is required"),
  tinUrl: yup.string().optional("TIN Proof Image is required"),
  tdsDeductible: yup.boolean().required("TDS Deductible is required"),
  isMsme: yup.boolean().optional(),
  msmeCertificatePath: yup.string().when("isMsme", {
    is: true,
    then: () => yup.string().required("MSME Certificate is required"),
    otherwise: () => yup.string().optional(),
  }),
  msmeCertificateUrl: yup.string().when("isMsme", {
    is: true,
    then: () => yup.string().required("MSME Certificate is required"),
    otherwise: () => yup.string().optional(),
  }),
});

import { useFormik } from "formik";
import RequiredLabel from "layouts/stores/requiredLabel";

const AddressAutocomplete = ({ formik }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // Load Google Places API
    const loadGoogleMaps = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBbisoFUQBoPH2uM1i_oOv43tC6Bvq9lm8&libraries=places`;
      script.async = true;
      script.onload = () => {
        // Initialize Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );
        
        const observer = new MutationObserver((mutationsList, observer) => {
          const pacContainer = document.querySelector('.pac-container');
          if (pacContainer) {
            // Set the z-index because the options were not visible 
            pacContainer.style.zIndex = '9999';
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (formik.values.address1) {
            inputRef.current.value = place.formatted_address;
          }
          if (place) {
            console.log("place", place);
            const formattedAddress = formatAddress1(place);
            formik.setFieldValue("address1", place.name + " " + formattedAddress);
            // formik.setFieldValue("address2", place.name);
            // You can extract additional info from the place object
            formik.setFieldValue(
              "city",
              place?.address_components?.find((comp) =>
                comp.types.includes("locality")
              )?.long_name || ""
            );
            formik.setFieldValue(
              "state",
              place?.address_components?.find((comp) =>
                comp.types.includes("administrative_area_level_1")
              )?.long_name || ""
            );
            formik.setFieldValue(
              "pincode",
              place?.address_components?.find((comp) =>
                comp.types.includes("postal_code")
              )?.long_name || ""
            );
          }
        });
      };
      document.body.appendChild(script);
    };

    loadGoogleMaps();
  }, []);

  const formatAddress1 = (place) => {
    // Extract address components to filter out city, state, country, and pincode
    const addressParts = place?.address_components?.filter((component) => {
      const types = component.types;
      return !(
        types.includes("locality") || // City
        types.includes("administrative_area_level_1") || // State
        types.includes("administrative_area_level_2") || // State
        types.includes("administrative_area_level_3") || // State
        types.includes("country") || // Country
        types.includes("postal_code") // Pincode
      );
    });

    // Combine the remaining address parts into a string
    return addressParts?.map((component) => component.long_name).join(", ");
  };

  return (
    <TextField
      label={<RequiredLabel label="Select Address 1"/>}
      inputRef={inputRef}
      value={formik?.values?.address1 || ""}
      onChange={(e) => formik?.setFieldValue("address1", e.target.value)}
      fullWidth
      error={formik.touched.address1 && Boolean(formik.errors.address1)}
      helperText={formik.touched.address1 && formik.errors.address1}
      variant="outlined"
    />
  );
};

/**
 *
 * @param {*} vendorDetail desc: payload details
 * @param {*} setNotification desc: user feedback notification
 * @param {*} handleTabChange desc: callback function to chnage tab value to next
 * @param {*} refetchQuery desc: query to refetch after successful mutation
 * @returns handleSubmit callback function (values) => { implementation of submit function }
 */
const useMetadataMutation = (
  vendorDetail,
  setNotification,
  handleTabChange,
  refetchQuery,
  setFormTabValue,
  tabValueFlag
) => {
  const brandMetadata =
    vendorDetail?.brandMetaData?.find(
      (data) => !data.isDeleted && data.key === "CompanyDetails"
    ) || {};
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQuery,
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
            metaDataInput: {
              id: brandMetadata.id,
              key: "CompanyDetails",
              value: { ...brandMetadata.value, ...values },
            },
          }
        : {
            metaDataInput: {
              brandId: vendorDetail.id,
              key: "CompanyDetails",
              value: { ...values },
            },
          };

    mutation({ variables: mutationInput })
      .then(() => {
        // handleTabChange(null, 2);
        tabValueFlag && setFormTabValue(2);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Company data updated successfully"
              : "Brand Company data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const CompanyVerificationForm = forwardRef(({ handleTabChange, brandData, setDirtyData, setShowDialog, setFormTabValue }, ref) => {
  const { setNotification } = useNotification();
  const { user } = useContext(AuthContext);

  const [vendorDetail, setVendorDetail] = useState(null);
  const [comapanyDetail, setCompanyDetail] = useState(null);

  const [ tabValueFlag, setTabValueFlag ] = useState(false)
  useEffect(()=>{
    setShowDialog(false)
    setTabValueFlag(false)
  },[])

  const [
    getVendorDetail,
    {
      loading: vendorDetailLoading,
      error: vendorDetailError,
      refetch: vendorDetailRefetch,
    },
  ] = useLazyQuery(GET_VENDOR_TAB_DETAILS_BY_ID, {
    client: dashboardServiceClient,
    onCompleted: (response) => {
      const vendorDetail = response.brandlist.results[0];
      const companyData =
        vendorDetail?.brandMetaData.find(
          (data) => data.key === "CompanyDetails"
        ) || {};
      setVendorDetail(vendorDetail);
      setCompanyDetail(companyData);
    },
    onError: (error) => {
      console.error(error);
      setNotification({
        color: "error",
        isVisible: true,
        message: "Error: fetching company details",
      });
    },
  });

  useEffect(() => {
    if (user) {
      getVendorDetail({
        variables: {
          take: 1,
          skip: 0,
          filter: {
            id: brandData ? brandData?.id : user.userId,
            brandMetaData: {
              key: "CompanyDetails",
            },
          },
        },
      });
    }
  }, [user]);

  const handleSubmit = useMetadataMutation(
    vendorDetail || { id: brandData ? brandData?.id : user.userId }, // * if vendor is undefined incase for new entry
    setNotification,
    handleTabChange,
    vendorDetailRefetch,
    setFormTabValue,
    tabValueFlag
  );

  if (vendorDetailLoading) return <Loader />;
  if (vendorDetailError) return <>Error: {vendorDetailError.message}</>;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        isMsme: comapanyDetail?.value?.isMsme || false,
        msmeCertificatePath:
          comapanyDetail?.value?.msmeCertificatePath ||
          comapanyDetail?.value?.msmeCertificateUrl ||
          "",
        msmeCertificateUrl: comapanyDetail?.value?.msmeCertificateUrl || "",
        address1: comapanyDetail?.value?.address1 || "",
        address2: comapanyDetail?.value?.address2 || "",
        addressProof: comapanyDetail?.value?.addressProof || "",
        city: comapanyDetail?.value?.city || "",
        state: comapanyDetail?.value?.state || "",
        pincode: comapanyDetail?.value?.pincode || "",
        addressProofPath:
          comapanyDetail?.value?.addressProofPath ||
          comapanyDetail?.value?.addressProofUrl ||
          "",
        addressProofUrl: comapanyDetail?.value?.addressProofUrl || "",
        serviceProvided: comapanyDetail?.value?.serviceProvided || "",
        companyType: comapanyDetail?.value?.companyType || "",
        panCardNo: comapanyDetail?.value?.panCardNo || "",
        panCardPath:
          comapanyDetail?.value?.panCardPath ||
          comapanyDetail?.value?.panCardUrl ||
          "",
        panCardUrl: comapanyDetail?.value?.panCardUrl || "",
        gstNo: comapanyDetail?.value?.gstNo || "",
        gstPath:
          comapanyDetail?.value?.gstPath || comapanyDetail?.value?.gstUrl || "",
        gstUrl: comapanyDetail?.value?.gstUrl || "",
        tinNo: comapanyDetail?.value?.tinNo || "",
        tinPath:
          comapanyDetail?.value?.tinPath || comapanyDetail?.value?.tinUrl || "",
        tinUrl: comapanyDetail?.value?.tinUrl || "",
        tdsDeductible: comapanyDetail?.value?.tdsDeductible || false,
        tdsSection: comapanyDetail?.value?.tdsSection || "",
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        if (values.tdsDeductible && !values.tdsSection) {
          errors.tdsSection = "This field is required";
        }
        return errors;
      }}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(formik) => {
        useEffect(() => {
          // return () => {
            // * will check if unsave data then show warning data loss
            // TODO: implement confirmation block to save or discard data
            // if (formik.dirty) {
              //   setNotification({
                //     color: "warning",
                //     isVisible: true,
                //     message: "Warning: Unsaved data was lost",
                //   });
                // }
                // };
                if (formik.dirty) {
                  setDirtyData(true)
                }
                else {
                  setDirtyData(false)
                }
              }, [formik.dirty]);
              useImperativeHandle(ref, () => ({
                handleSubmitCompanyVerification: formik.handleSubmit,
                validateFormCompanyVerification: () => { 
                  formik.setTouched(Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true })));
                  return formik.errors
                },
              }));

        return (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{
                      fontFamily: "Montserrat",
                      color: "#000000",
                      display: "inline",
                    }}
                    control={
                      <Field
                        as={Checkbox}
                        checked={formik.values.isMsme}
                        name="isMsme"
                      />
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      display: "inline",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    MSME Certificate if applicable
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  {formik.values.isMsme && (
                    <>
                      <Grid
                        item
                        container
                        spacing={1}
                        alignItems="center"
                        xs={12}
                      >
                        <Grid item>
                          <Typography
                            variant="h7"
                            fontWeight="bold"
                            fontSize="12px"
                            style={{ color: "#000", fontFamily: "Montserrat" }}
                          >
                            MSME Certificate Upload <span style={{color:"red"}}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h7"
                            fontSize="10px"
                            style={{
                              color: "#9e9e9e",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Please upload JPEG, PNG or PDF files only.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mt={-2}>
                        <FileUploader
                          hasDisplayName
                          isMultiple={false}
                          isPdf={true}
                          fieldName={"MSME Certificate Upload"}
                          preLoadedFile={
                            !!formik.values.msmeCertificateUrl
                              ? [
                                  {
                                    fileName: formik.values.msmeCertificateUrl,
                                    displayName:
                                      formik.values.msmeCertificatePath,
                                  },
                                ]
                              : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("msmeCertificatePath", newUrl);
                            formik.setFieldValue(
                              "msmeCertificateUrl",
                              publicUrl
                            );
                          }}
                          isFieldValid={
                            formik.touched?.msmeCertificateUrl &&
                            formik.errors?.msmeCertificateUrl
                          }
                        />
                        {formik.touched.msmeCertificateUrl &&
                          formik.errors.msmeCertificateUrl && (
                            <Typography fontSize={12} color="error">
                              {formik.errors.msmeCertificateUrl}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Company Verification
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <AddressAutocomplete formik={formik} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="address2"
                      label="Address 2"
                      fullWidth
                      InputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.address2 && !!formik.errors.address2
                      }
                      helpertext={
                        formik.touched.address2 ? formik.errors.address2 : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="state"
                      label={<RequiredLabel label="State"/>}
                      value={formik.values.state}
                      fullWidth
                      InputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.state && !!formik.errors.state}
                      helpertext={
                        formik.touched.state ? formik.errors.state : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="city"
                      label={<RequiredLabel label="City"/>}
                      value={formik.values.city}
                      fullWidth
                      InputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.city && !!formik.errors.city}
                      helpertext={formik.touched.city ? formik.errors.city : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="pincode"
                      label={<RequiredLabel label="Pin Code"/>}
                      value={formik.values.pincode}
                      fullWidth
                      InputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.pincode && !!formik.errors.pincode}
                      helpertext={
                        formik.touched.pincode ? formik.errors.pincode : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="addressProof"
                        sx={{ fontFamily: "Montserrat" }}
                      >
                        Select Address Proof Type<span style={{color:"red"}}>*</span>
                      </InputLabel>
                      <Field
                        as={Select}
                        value={formik.values.addressProof}
                        label="Select Address Proof Type"
                        name="addressProof"
                        id="addressProof"
                        onChange={formik.handleChange}
                        fullWidth
                        IconComponent={() => (
                          <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                        )}
                        sx={{
                          height: "40px",
                          fontFamily: "Montserrat",
                        }}
                        error={
                          formik.touched.addressProof &&
                          !!formik.errors.addressProof
                        }
                        helpertext={
                          formik.touched.addressProof
                            ? formik.errors.addressProof
                            : ""
                        }
                      >
                        <MenuItem
                          value="aadhaarCard"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Aadhaar Card
                        </MenuItem>
                        <MenuItem
                          value="rentAggrement"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Rent Aggrement
                        </MenuItem>
                        <MenuItem
                          value="serviceBill"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Maintainance Bill / Electricity Bill / Water Bill
                        </MenuItem>
                        <MenuItem
                          value="gstDocument"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          GST Document
                        </MenuItem>
                      </Field>
                      {formik.touched?.addressProof &&
                        formik.errors?.addressProof && (
                          <Typography fontSize={12} color="error">
                            {formik.errors?.addressProof}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item container spacing={1} alignItems="center" xs={12}>
                    <Grid item>
                      <Typography
                        variant="h7"
                        fontWeight="bold"
                        fontSize="12px"
                        style={{ color: "#000", fontFamily: "Montserrat" }}
                      >
                        Address Proof Upload<span style={{color:"red"}}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h7"
                        fontSize="10px"
                        style={{ color: "#9E9E9E", fontFamily: "Montserrat" }}
                      >
                        Please upload JPEG, PNG or PDF files only.
                      </Typography>
                    </Grid>
                  </Grid>
                  {formik.values.addressProof && (
                    <Grid item xs={12} mt={-2}>
                      <FileUploader
                        isMultiple={false}
                        isPdf={true}
                        fieldName={"Address Proof Upload"}
                        hasDisplayName
                        preLoadedFile={
                          !!formik.values.addressProofUrl
                            ? [
                                {
                                  fileName: formik.values.addressProofUrl,
                                  displayName: formik.values.addressProofPath,
                                },
                              ]
                            : null
                        }
                        signedUrlCallback={(newUrl, index, publicUrl) => {
                          formik.setFieldValue("addressProofPath", newUrl);
                          formik.setFieldValue("addressProofUrl", publicUrl);
                        }}
                        isFieldValid={
                          formik.touched?.addressProofUrl &&
                          formik.errors?.addressProofUrl
                        }
                      />
                      {formik.touched.addressProofUrl &&
                        formik.errors.addressProofUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.addressProofUrl}
                          </Typography>
                        )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="serviceProvided"
                      label={<RequiredLabel label="Product/Service"/>}
                      fullWidth
                      value={formik.values.serviceProvided}
                      InputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.serviceProvided &&
                        !!formik.errors.serviceProvided
                      }
                      helpertext={
                        formik.touched.serviceProvided
                          ? formik.errors.serviceProvided
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="companyType"
                        sx={{ fontFamily: "Montserrat" }}
                      >
                        Type of Company<span style={{color:"red"}}>*</span>
                      </InputLabel>
                      <Field
                        as={Select}
                        value={formik.values.companyType}
                        label="Type of Company"
                        name="companyType"
                        id="companyType"
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{
                          height: "40px",
                          fontFamily: "Montserrat",
                        }}
                        IconComponent={() => (
                          <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                        )}
                      >
                        <MenuItem value="llp" sx={{ fontFamily: "Montserrat" }}>
                          Limited liability Partnership (LLP)
                        </MenuItem>
                        <MenuItem
                          value="proprietorship"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Proprietorship
                        </MenuItem>
                        <MenuItem
                          value="partnership"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Partnership
                        </MenuItem>
                        <MenuItem
                          value="publiclimited"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Public Limited
                        </MenuItem>
                        <MenuItem
                          value="privatelimited"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Private Limited
                        </MenuItem>
                      </Field>
                      {formik.touched?.companyType &&
                        formik.errors?.companyType && (
                          <Typography fontSize={12} color="error">
                            {formik.errors?.companyType}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ color: "#000", fontFamily: "Montserrat" }}
                    >
                      Tax Details
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label={<RequiredLabel label="PAN Card No."/>}
                        fullWidth
                        name="panCardNo"
                        value={formik.values.panCardNo}
                        onChange={formik.handleChange}
                        InputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={
                          formik.touched.panCardNo && !!formik.errors.panCardNo
                        }
                        helpertext={
                          formik.touched.panCardNo
                            ? formik.errors.panCardNo
                            : ""
                        }
                      />
                    </Grid>
                    {formik.values.panCardNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          required={true}
                          fieldName={"PAN Card Upload"}
                          isPdf={true}
                          hasDisplayName
                          preLoadedFile={
                            !!formik.values.panCardUrl
                              ? [
                                  {
                                    fileName: formik.values.panCardUrl,
                                    displayName: formik.values.panCardPath,
                                  },
                                ]
                              : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("panCardPath", newUrl);
                            formik.setFieldValue("panCardUrl", publicUrl);
                          }}
                          isFieldValid={
                            formik.touched?.panCardUrl &&
                            formik.errors?.panCardUrl
                          }
                        />
                        {formik.touched.panCardUrl && formik.errors.panCardUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.panCardUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label={<RequiredLabel label="GST No."/>}
                        fullWidth
                        name="gstNo"
                        value={formik.values.gstNo}
                        onChange={formik.handleChange}
                        InputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={formik.touched.gstNo && !!formik.errors.gstNo}
                        helpertext={
                          formik.touched.gstNo ? formik.errors.gstNo : ""
                        }
                      />
                    </Grid>
                    {formik.values.gstNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          required={true}
                          fieldName={"GST Upload"}
                          hasDisplayName
                          isPdf={true}
                          preLoadedFile={
                            !!formik.values.gstUrl
                              ? [
                                  {
                                    fileName: formik.values.gstUrl,
                                    displayName: formik.values.gstPath,
                                  },
                                ]
                              : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("gstPath", newUrl);
                            formik.setFieldValue("gstUrl", publicUrl);
                          }}
                          isFieldValid={
                            formik.touched?.gstUrl && formik.errors?.gstUrl
                          }
                        />
                        {formik.touched.gstUrl && formik.errors.gstUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.gstUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="TIN No."
                        fullWidth
                        name="tinNo"
                        value={formik.values.tinNo}
                        onChange={formik.handleChange}
                        InputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={formik.touched.tinNo && !!formik.errors.tinNo}
                        helpertext={
                          formik.touched.tinNo ? formik.errors.tinNo : ""
                        }
                      />
                    </Grid>
                    {formik.values.tinNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          isPdf={true}
                          fieldName={"TIN Upload"}
                          hasDisplayName
                          preLoadedFile={
                            !!formik.values.tinUrl
                              ? [
                                  {
                                    fileName: formik.values.tinUrl,
                                    displayName: formik.values.tinPath,
                                  },
                                ]
                              : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("tinPath", newUrl);
                            formik.setFieldValue("tinUrl", publicUrl);
                          }}
                        />
                        {formik.touched.tinUrl && formik.errors.tinUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.tinUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="tdsDeductible"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          TDS Deductible<span style={{color:"red"}}>*</span>
                        </InputLabel>
                        <Field
                          as={Select}
                          value={formik.values.tdsDeductible}
                          label="TDS Deductible"
                          name="tdsDeductible"
                          id="tdsDeductible"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("tdsSection", "");
                          }}
                          fullWidth
                          sx={{
                            height: "40px",
                            fontFamily: "Montserrat",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px" }}
                            />
                          )}
                        >
                          <MenuItem
                            value={true}
                            sx={{ fontFamily: "Montserrat" }}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            value={false}
                            sx={{ fontFamily: "Montserrat" }}
                          >
                            No
                          </MenuItem>
                        </Field>
                        {formik.touched?.tdsDeductible &&
                          formik.errors?.tdsDeductible && (
                            <Typography fontSize={12} color="error">
                              {formik.errors?.tdsDeductible}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    {formik.values.tdsDeductible === true ? (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label={<RequiredLabel  label="TDS Section"/>}
                          fullWidth
                          name="tdsSection"
                          value={formik.values.tdsSection}
                          onChange={formik.handleChange}
                          InputProps={{
                            style: { fontFamily: "Montserrat" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Montserrat" },
                          }}
                          error={
                            formik.touched.tdsSection &&
                            !!formik.errors.tdsSection
                          }
                          helpertext={
                            formik.touched.tdsSection
                              ? formik.errors.tdsSection
                              : ""
                          }
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ mt: 3 }}
                justifyContent={"flex-end"}
                gap={3}
              >
                <Button
                  sx={{
                    color: "#000000",
                    borderRadius: "50px",
                    borderColor: "#000000",
                    fontFamily: "Montserrat",
                  }}
                  variant="outlined"
                  onClick={() => {
                    formik.resetForm({
                      values: {
                        // You can set default or empty values here
                        isMsme: false,
                        msmeCertificatePath: "",
                        msmeCertificateUrl: "",
                        address1: "",
                        address2: "",
                        addressProof: "",
                        city: "",
                        state: "",
                        pincode: "",
                        addressProofPath: "",
                        addressProofUrl: "",
                        serviceProvided: "",
                        companyType: "",
                        panCardNo: "",
                        panCardPath: "",
                        panCardUrl: "",
                        gstNo: "",
                        gstPath: "",
                        gstUrl: "",
                        tinNo: "",
                        tinPath: "",
                        tinUrl: "",
                        tdsDeductible: "",
                        tdsSection: "",
                      },
                    });
                  }}
                >
                  Reset
                </Button>
                <Button
                  // type="submit"
                  onClick={()=>{
                    setTabValueFlag(true)
                    formik.handleSubmit();
                  }}
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#000000",
                    color: "#fff",
                    borderRadius: "50px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});

export default CompanyVerificationForm;
