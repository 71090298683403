import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Icon,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import dayjs from "dayjs";
import { useLazyQuery } from "@apollo/client";
import { GET_PROMOTION_LIST } from "services/promotion-service";
import { dashboardServiceClient } from "graphql/client";
import { getPromoNameLabel } from "./promotionLandingPage";

const CommentsDialog = ({ promotionId }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [
    getPromotionList,
    { loading: promotionLoading, error: promotionError },
  ] = useLazyQuery(GET_PROMOTION_LIST, {
    client: dashboardServiceClient,
    onError: (error) => {
      console.error("Error: fetching promotions", error);
    },
  });
  const [promotionData, setPromotionData] = useState(null);

  // const [getPromotionDetails, { loading, data, error}] = useLazyQuery()

  useEffect(() => {
    // TODO: need to call promotion details form to fetch tab form details
    if (open && promotionId != null) {
      getPromotionList({
        variables: {
          skip: 0,
          take: 1,
          filter: {
            id: promotionId,
          },
        },
      }).then((res) => {
        if (res.data.promotions.results.length > 0) {
          setPromotionData(res.data.promotions.results[0]);
        }
      });
    }
  }, [promotionId, open]);

  return (
    <div>
      {/* IconButton to open the dialog */}
      <IconButton
        aria-label="save"
        onClick={(e) => {
          e.preventDefault();
          handleOpen();
        }}
      >
        <Icon component={commentIcon} fontSize="small" />
      </IconButton>

      {/* Dialog Component */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        {promotionLoading ? (
          <div>loading...</div>
        ) : (
          <>
            <DialogTitle>
              { getPromoNameLabel(promotionData?.promoName)}
              {/* Close Button in the title */}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: "absolute", right: 8, top: 8 }}
              >
                ✖
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              {!promotionData ||
              !promotionData.comments ||
              !promotionData?.comments?.length ? (
                <h4>No comments yet</h4>
              ) : (
                <h4>Comments</h4>
              )}
              {promotionData?.comments?.map((comment) => (
                <Card sx={{ marginBottom: 1 }}>
                  <CardHeader
                    sx={{ "& .MuiCardHeader-title": { fontSize: 16 } }}
                    title={comment.commentText}
                  />
                  <CardContent
                    sx={{
                      fontSize: 12,
                      fontWeight: 600,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ marginRight: 12, fontStyle: "italic" }}>
                      {comment.user}
                    </span>
                    <span style={{ fontWeight: 400, color: "#9E9E9E" }}>
                      {dayjs(comment.createdAt).format("DD/MM/YY HH:mm a")}
                    </span>
                  </CardContent>
                </Card>
              ))}
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default CommentsDialog;
