import React, { useContext, useRef, useState ,useEffect } from "react";
// Custom Imports
import { AuthContext } from "context";
import Loader from "components/MDLoader";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import ProductNavbar from "./ProductNavbar";
import ProductData from "./productTable/ProductData";
import AddProductForms from "./forms/AddProductForms";
import { ProductContextProvider } from "./context/productContext";
import { AvailableTabs } from "./productTable/tableData";

//Apollo Imports
import { GET_COMPANY_VENDOR_ID_BY_USERNAME } from "services/product-service";
import { useLazyQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

//MUI Imports
import { Box, Typography } from "@mui/material";

function Product() {
  const [form, setForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState(AvailableTabs.singleProduct);
  const { user } = useContext(AuthContext);

  const gridRef = useRef(null);

  const [productContext, setProductContext] = useState({
    addedSingleProduct: false,
    addedBulkProduct: false,
    gridRef,
  });
  const [vendorId, setVendorId] = useState('');

  const [getCompanyDetails, 
    {
      loading: vendorDetailLoading, 
      error: vendorDetailError,
    }
    ] = useLazyQuery(
      GET_COMPANY_VENDOR_ID_BY_USERNAME,
      {
        client: dashboardServiceClient,
        onCompleted: (response) => {
          const vendor = response?.brand?.vendorId;
          setVendorId(vendor)
        },
        onError: (error) => {
          console.error(error);
        }
      },
    );
  useEffect(() => {
    if (user && user.role === 'brand_admin') {
      getCompanyDetails({
        variables: {
          brandByUsernameDto: {
            username: user &&  user.username || '',
          }
        },
      });
    }
  }, [user]);

  if(vendorDetailLoading) return<Loader/>
  if(vendorDetailError) return <h1>Error: {vendorDetailError.message || "Error"}</h1>
  return (
    <DashboardLayout>
     { user && user.role === 'brand_admin' && !vendorId ? 
     <div style={{border:"2px solid black",margin:"10%", borderRadius:"10px",backgroundColor:"#FFFFFF"}}>
       <Typography style={{fontSize:"16px" , margin:"2%"}} className="typography-header">
        Broadway ERP ID for the Company is not yet Updated , Kindly Connect with Your SPOC to get the same updated to start with Product Creation
       </Typography>
     </div>
     : (<ProductContextProvider value={{ productContext, setProductContext }}>
        <ProductNavbar
          setForm={setForm}
          gridRef={gridRef}
          selectedTab={selectedTab}
        />
        <ProductData
          setForm={setForm}
          gridRef={gridRef}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <AddProductForms form={form} setForm={setForm} />
      </ProductContextProvider>)}
    </DashboardLayout>
  );
}

export default Product;
