import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Grid,
  TextField,
  FormControlLabel,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  GET_PROMOTION_LIST,
  CREATE_PROMOTION_MUTATION,
  UPDATE_PROMOTION_MUTATION,
} from "services/promotion-service";

import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { useNotification } from "context";
import "react-quill/dist/quill.snow.css"; // import styles
import "./promotion.css";
import MDButton from "components/MDButton";

import { GET_PRODUCT_LIST } from "services/events-service";
import { productServiceClient } from "graphql/client";
import addIcon from "../../assets/images/add-icon.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import galleryIcon from "../../assets/images/gallery-icon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import * as XLSX from "xlsx";
import { CustomErrorTextField } from "layouts/product/forms/AddBulkProduct";
import FileUploaderContainer from "./excelFileUpload";
import { PromotionFormContext } from ".";
import { CREATE_PROMOTION_ASSORTMENT } from "services/promotion-service";
import { UPDATE_PROMOTION_ASSORTMENT } from "services/promotion-service";

const validationSchema = Yup.object().shape({
  id: Yup.string().nullable(), // Optional field
  buyOption: Yup.string()
    .required("Buy option is required")
    .oneOf(["allProducts", "inc/exc"], "Invalid Buy option"),
  buyInclusionType: Yup.string()
    .required("Buy Inclusion Type is required")
    .oneOf(["specificProducts", "uploadExcel"], "Invalid Inclusion Type"),
  buyInclusionSpecificProducts: Yup.array()
    .of(Yup.string())
    .when("buyInclusionType", {
      is: (buyInclusionType) => buyInclusionType == "specificProducts",
      then: () =>
        Yup.array()
          .min(1, "At least one specific product must be selected")
          .required("Specific products are required"),
      otherwise: () => Yup.array().notRequired(),
    }),
  buyInclusionUploadedFile: Yup.mixed().nullable(),
  buyInclusionUploadedFilePath: Yup.string().nullable(),
  buyInclusionUploadedFilePublicUrl: Yup.string().nullable()
  .test(
    "either-file-required",
    "Either inclusion or exclusion file must be uploaded",
    function (value) {
      const { buyExclusionUploadedFilePublicUrl, buyOption } = this.parent;
      // Validate only if buyOption is "inc/exc"
      if (buyOption === "inc/exc") {
        return value || buyExclusionUploadedFilePublicUrl;
      }
      return true; // Pass if not "inc/exc"
    }
  ),
  buyExclusionType: Yup.string()
    .required("Buy Exclusion Type is required")
    .oneOf(["specificProducts", "uploadExcel"], "Invalid Exclusion Type"),
  buyExclusionUploadedFile: Yup.mixed().nullable(),
  buyExclusionUploadedFilePath: Yup.string().nullable(),
  buyExclusionUploadedFilePublicUrl: Yup.string().nullable(),
  getOption: Yup.string()
    .required("Get option is required")
    .oneOf(["discount", "allProducts", "inc/exc"], "Invalid Get option"),
  getInclusionType: Yup.string()
    .required("Get Inclusion Type is required")
    .oneOf(["specificProducts", "uploadExcel"], "Invalid Inclusion Type"),
  getInclusionSpecificProducts: Yup.array()
    .of(Yup.string())
    .when("getInclusionType", {
      is: "specificProducts",
      then: () =>
        Yup.array()
          .min(1, "At least one specific product must be selected")
          .required("Specific products are required"),
      otherwise: () => Yup.array().notRequired(),
    }),
  getInclusionUploadedFile: Yup.mixed().nullable(), // Allow nullable values initially
  getInclusionUploadedFilePath: Yup.string().nullable(),
  getInclusionUploadedFilePublicUrl: Yup.string().nullable()
  .test(
    "either-file-required",
    "Either inclusion or exclusion file must be uploaded",
    function (value) {
      const { getExclusionUploadedFilePublicUrl, getOption } = this.parent;
      // Validate only if buyOption is "inc/exc"
      if (getOption === "inc/exc") {
        return value || getExclusionUploadedFilePublicUrl;
      }
      return true; // Pass if not "inc/exc"
    }
  ),
  getExclusionType: Yup.string()
    .required("Get Exclusion Type is required")
    .oneOf(["specificProducts", "uploadExcel"], "Invalid Exclusion Type"),
  getExclusionUploadedFile: Yup.mixed().nullable(),
  getExclusionUploadedFilePath: Yup.string().nullable(),
  getExclusionUploadedFilePublicUrl: Yup.string().nullable(),
});

const usePromotionMutations = (
  promotionData,
  handleClose,
  handleChange,
  setNotification,
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
  };
  const [createAssortmentMutation] = useMutation(
    CREATE_PROMOTION_ASSORTMENT,
    mutationOptions
  );
  const [updateAssortmentMutation] = useMutation(
    UPDATE_PROMOTION_ASSORTMENT,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      id:values.id || undefined,
      promotionId: values.promotionId,
      getAssortmentType:
        values.getOption == "inc/exc"
          ? values.getInclusionUploadedFilePath != null
            ? "include__Products"
            : "exclude_Products"
          : values.getOption,
      getAssortmentFile : values.getOption == "inc/exc"
      ? values.getInclusionUploadedFilePath != null
        ? { path: values.getInclusionUploadedFilePath, publicUrl: values.getInclusionUploadedFilePublicUrl}
        : { path: values.getExclusionUploadedFilePath, publicUrl: values.getExclusionUploadedFilePublicUrl}
      : null,
      buyAssortmentType:
        values.buyOption == "inc/exc"
          ? values.buyInclusionUploadedFilePath != null
            ? "include__Products"
            : "exclude_Products"
          : values.buyOption,
      buyAssortmentFile : values.buyOption == "inc/exc"
      ? values.buyInclusionUploadedFilePath != null
        ? { path: values.buyInclusionUploadedFilePath, publicUrl: values.buyInclusionUploadedFilePublicUrl}
        : { path: values.buyExclusionUploadedFilePath, publicUrl: values.buyExclusionUploadedFilePublicUrl}
      : null,
    };
    
    if(payload.id){
      updateAssortmentMutation({ variables: { updateAssortmentInput: payload } })
        .then((res) => {
          if(res.data){
            handleChange(null, 2);
            setNotification({
              color: "success",
              isVisible: true,
              message: "Promotion assortment updated successfully",
            });
          } else {
            setNotification({
              color: "error",
              isVisible: true,
              message: res.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error?.message);
          setNotification({
            color: "error",
            isVisible: true,
            message: error?.message || "Something went wrong",
          });
        });
    } else {
      createAssortmentMutation({
        variables: { assortmentInput: payload },
      })
        .then((res) => {
          if(res.data){
            handleChange(null, 2);
            setNotification({
              color: "success",
              isVisible: true,
              message: "Promotion assortment created successfully",
            });
          } else {
            setNotification({
              color: "error",
              isVisible: true,
              message: res.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error?.message);
          setNotification({
            color: "error",
            isVisible: true,
            message: error?.message || "Something went wrong",
          });
        });
    }
  };
  return handleSubmit;
};
const AssortmentForm = ({
  handleClose,
  handleChange,
}) => {
  const { setNotification } = useNotification();
  const { promotionId, setPromotionId } = useContext(PromotionFormContext);
  if(!promotionId){
    handleChange(null, 0);
  }
  const [getPromotionList, { loading:promotionLoading, error:promotionError }] = useLazyQuery(
    GET_PROMOTION_LIST,
    {
      client: dashboardServiceClient,
      onError: (error) => {
        console.error("Error: fetching promotions", error);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Error: fetching promotions",
        });
      }
    }
  );
  const [promotionData, setPromotionData] = useState(null);

  useEffect(() => {
    // TODO: need to call promotion details form to fetch tab form details
    if(promotionId != null){
      getPromotionList({
        variables: {
          skip: 0,
          take: 1,
          filter:{
            id: promotionId
          }
        }
      }).then(res => {
        if(res.data.promotions.results.length > 0){
          setPromotionData(res.data.promotions.results[0])
        }
      })
    }
  }, [promotionId])
  
  const handleSubmit = usePromotionMutations(
    promotionData,
    handleClose,
    handleChange,
    setNotification
  );

  // const {
  //   loading: productLoading,
  //   error: productError,
  //   data: productData,
  //   refetch,
  // } = useQuery(GET_PRODUCT_LIST, {
  //   client: productServiceClient,
  //   variables: {
  //     limit: 99999,
  //     skip: 0,
  //     status: "G_SYNCED",
  //   },
  // });
  const productData=null;
  const productLoading = false;
  

  const productList = productData?.getVariants?.variants || [];

  const downloadTemplate = (filename) => {
    const worksheet = XLSX.utils.json_to_sheet([
      {
        "Barcode (Mandatory)": null,
        "BW Article Id": null,
        "ERP SKU": null,
        "Vendor Article Code": null,
      },
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  return (
    promotionData && (
      <Formik
        key={promotionData ? promotionData.id : "new"} // Key the component to the promotion ID or 'new' for new promotions
        initialValues={{
          id: promotionData.assortmentPromotion?.[0]?.id,
          promotionId: promotionData?.id,
          buyOption: promotionData?.assortmentPromotion
            ? ["include__Products", "exclude_Products"].includes(
                promotionData.assortmentPromotion?.[0]?.buyAssortmentType
              )
              ? "inc/exc"
              : promotionData.assortmentPromotion?.[0]?.buyAssortmentType
            : null,
          buyInclusionType: "uploadExcel",
          buyInclusionSpecificProducts: [],
          buyInclusionUploadedFile: null,
          buyInclusionUploadedFilePath:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.buyAssortmentType ==
              "include__Products"
              ? promotionData.assortmentPromotion?.[0]?.buyAssortmentFile?.path
              : null,
          buyInclusionUploadedFilePublicUrl:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.buyAssortmentType ==
              "include__Products"
              ? promotionData.assortmentPromotion?.[0]?.buyAssortmentFile
                  ?.publicUrl
              : null,
          buyExclusionType: "uploadExcel",
          buyExclusionSpecificProducts: [],
          buyExclusionUploadedFile: null,
          buyExclusionUploadedFilePath:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.buyAssortmentType ==
              "exclude_Products"
              ? promotionData.assortmentPromotion?.[0]?.buyAssortmentFile?.path
              : null,
          buyExclusionUploadedFilePublicUrl:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.buyAssortmentType ==
              "exclude_Products"
              ? promotionData.assortmentPromotion?.[0]?.buyAssortmentFile
                  ?.publicUrl
              : null,
          getOption: promotionData?.assortmentPromotion
            ? ["include__Products", "exclude_Products"].includes(
                promotionData.assortmentPromotion?.[0]?.getassortmentType
              )
              ? "inc/exc"
              : promotionData.assortmentPromotion?.[0]?.getassortmentType
            : null,
          getInclusionType: "uploadExcel",
          getInclusionSpecificProducts: [],
          getInclusionUploadedFile: null,
          getInclusionUploadedFilePath:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.getassortmentType ==
              "include__Products"
              ? promotionData.assortmentPromotion?.[0]?.getAssortmentFile?.path
              : null,
          getInclusionUploadedFilePublicUrl:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.getassortmentType ==
              "include__Products"
              ? promotionData.assortmentPromotion?.[0]?.getAssortmentFile
                  ?.publicUrl
              : null,
          getExclusionType: "uploadExcel",
          getExclusionSpecificProducts: [],
          getExclusionUploadedFile: null,
          getExclusionUploadedFilePath:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.getassortmentType ==
              "exclude_Products"
              ? promotionData.assortmentPromotion?.[0]?.getAssortmentFile?.path
              : null,
          getExclusionUploadedFilePublicUrl:
            promotionData &&
            promotionData.assortmentPromotion?.[0]?.getassortmentType ==
              "exclude_Products"
              ? promotionData.assortmentPromotion?.[0]?.getAssortmentFile
                  ?.publicUrl
              : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (promotionData && promotionData.status !== "draft") {
            handleChange(null, 1);
          } else {
            handleSubmit(values);
          }
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          validateField,
          isSubmitting,
          handleChange,
          handleBlur,
        }) => {
          return (
            <Form>
              <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#000000", fontFamily: "Montserrat" }}
                  >
                    Buy
                  </Typography>
                  <hr color="#EAEAEA" />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    disabled={promotionData?.status != "draft"}
                  >
                    <RadioGroup
                      row
                      name="option"
                      defaultValue={values.buyOption}
                      onChange={(event) =>
                        setFieldValue("buyOption", event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="allProducts"
                        control={<Radio />}
                        label="All Products"
                      />
                      <FormControlLabel
                        value="inc/exc"
                        control={<Radio />}
                        label="Include/Exclude Products"
                      />
                    </RadioGroup>
                    {touched?.buyOption && errors?.buyOption && (
                      <Typography fontSize={12} color="error">
                        {errors?.buyOption}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {values.buyOption === "inc/exc" && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Include
                      </Typography>
                      <hr color="#EAEAEA" />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={
                          touched.buyInclusionType &&
                          Boolean(errors.buyInclusionType)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          Inclusion Type
                        </InputLabel>
                        <Select
                          as={Select}
                          name="buyInclusionType"
                          label="Inclusion Type"
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px", marginTop: "10px" }}
                            />
                          )}
                          value={values.buyInclusionType}
                          onChange={(e) => {
                            setFieldValue("buyInclusionType", e.target.value);
                            // setFieldTouched(`buyInclusionType`, true, false);
                            // validateField(`buyInclusionType`, e.target.value);
                          }}
                          error={
                            touched.buyInclusionType &&
                            Boolean(errors.buyInclusionType)
                          }
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="specificProduct">
                            Specific Products
                          </MenuItem>
                          <MenuItem value="uploadExcel">Upload Excel</MenuItem>
                        </Select>
                        {touched.buyInclusionType && errors.buyInclusionType ? (
                          <FormHelperText>
                            {errors.buyInclusionType}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid> */}
                    {values.buyInclusionType === "specificProduct" && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            value={values?.buyInclusionSpecificProducts}
                            options={
                              productList?.map((item) => ({
                                value: item?.id,
                                label: item?.itemName,
                              })) || []
                            }
                            getOptionLabel={(option) => option.label || ""}
                            loading={productLoading}
                            onChange={(event, products) => {
                              setFieldValue(
                                "buyInclusionSpecificProducts",
                                products
                              );
                              // setSelectedBrand(newValue?.value);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                px: 2,
                                backgroundColor: "white.main",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Products"
                                variant="outlined"
                                fullWidth
                                error={
                                  touched.buyInclusionSpecificProducts &&
                                  Boolean(errors.buyInclusionSpecificProducts)
                                }
                                helperText={
                                  touched.buyInclusionSpecificProducts
                                    ? errors.buyInclusionSpecificProducts
                                    : ""
                                }
                              />
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={option?.label}
                                  size="small"
                                />
                              ));
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {values.buyInclusionType === "uploadExcel" && (
                      <>
                        {/* <Grid item xs={12}>
                          
                        </Grid> */}
                        <Grid item xs={12}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontSize: "15px",
                              }}
                            >
                              Upload Excel File
                            </span>
                            <span
                              style={{
                                color: "#9E9E9E",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {" "}
                              Please upload .xlsx or .csv files only
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                startIcon={
                                  <img
                                    src={downloadIcon}
                                    alignItems={"center"}
                                  />
                                }
                                component={"label"}
                                onClick={() => {
                                  downloadTemplate(
                                    "include_product_template.xlsx"
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#000000",
                                  }}
                                >
                                  Include Product Template Excel
                                </Typography>
                              </Button>
                            </span>
                          </Typography>
                          <FileUploaderContainer
                            disabled={
                              promotionData?.status != "draft" ||
                              values.buyExclusionUploadedFilePublicUrl != null
                            }
                            keyPrefix="buyInclude"
                            isMultiple={false}
                            fieldName={"Choose File"}
                            preLoadedFile={
                              !!values.buyInclusionUploadedFilePublicUrl
                                ? [
                                    {
                                      fileName:
                                        values.buyInclusionUploadedFilePublicUrl,
                                      displayName:
                                        values.buyInclusionUploadedFilePath,
                                    },
                                  ]
                                : null
                            }
                            hasDisplayName
                            signedUrlCallback={(newUrl, index, publicUrl) => {
                              setFieldValue(
                                "buyInclusionUploadedFilePath",
                                newUrl
                              );
                              setFieldValue(
                                "buyInclusionUploadedFilePublicUrl",
                                publicUrl
                              );
                            }}
                            isFieldValid={
                              touched?.buyInclusionUploadedFilePublicUrl &&
                              errors?.buyInclusionUploadedFilePublicUrl
                            }
                          />
                          {touched?.buyInclusionUploadedFilePublicUrl &&
                            errors?.buyInclusionUploadedFilePublicUrl && (
                              <Typography fontSize={12} color="error">
                                {errors?.buyInclusionUploadedFilePublicUrl}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                    {/* EXCLUDE */}
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Exclude
                      </Typography>
                      <hr color="#EAEAEA" />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={
                          touched.buyExclusionType &&
                          Boolean(errors.buyExclusionType)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          Exclusion Type
                        </InputLabel>
                        <Select
                          as={Select}
                          name="buyExclusionType"
                          label="Exclusion Type"
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px", marginTop: "10px" }}
                            />
                          )}
                          value={values.buyExclusionType}
                          onChange={(e) => {
                            setFieldValue("buyExclusionType", e.target.value);
                            // setFieldTouched(`buyExclusionType`, true, false);
                            // validateField(`buyExclusionType`, e.target.value);
                          }}
                          error={
                            touched.buyExclusionType &&
                            Boolean(errors.buyExclusionType)
                          }
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="specificProduct">
                            Specific Products
                          </MenuItem>
                          <MenuItem value="uploadExcel">Upload Excel</MenuItem>
                        </Select>
                        {touched.buyExclusionType && errors.buyExclusionType ? (
                          <FormHelperText>
                            {errors.buyExclusionType}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid> */}
                    {values.buyExclusionType === "specificProduct" && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            value={values?.buyExclusionSpecificProducts}
                            options={
                              productList?.map((item) => ({
                                value: item?.id,
                                label: item?.itemName,
                              })) || []
                            }
                            getOptionLabel={(option) => option.label || ""}
                            loading={productLoading}
                            onChange={(event, products) => {
                              setFieldValue(
                                "buyExclusionSpecificProducts",
                                products
                              );
                              // setSelectedBrand(newValue?.value);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                px: 2,
                                backgroundColor: "white.main",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Products"
                                variant="outlined"
                                fullWidth
                                error={
                                  touched.buyExclusionSpecificProducts &&
                                  Boolean(errors.buyExclusionSpecificProducts)
                                }
                                helperText={
                                  touched.buyExclusionSpecificProducts
                                    ? errors.buyExclusionSpecificProducts
                                    : ""
                                }
                              />
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={option?.label}
                                  size="small"
                                />
                              ));
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {values.buyExclusionType === "uploadExcel" && (
                      <>
                        <Grid item xs={12}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontSize: "15px",
                              }}
                            >
                              Upload Excel File
                            </span>
                            <span
                              style={{
                                color: "#9E9E9E",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {" "}
                              Please upload .xlsx or .csv files only
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                startIcon={
                                  <img
                                    src={downloadIcon}
                                    alignItems={"center"}
                                  />
                                }
                                component={"label"}
                                onClick={() => {
                                  downloadTemplate(
                                    "exclude_product_template.xlsx"
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#000000",
                                  }}
                                >
                                  Exclude Product Template Excel
                                </Typography>
                              </Button>
                            </span>
                          </Typography>
                          <FileUploaderContainer
                            keyPrefix="buyExclude"
                            isMultiple={false}
                            fieldName={"Choose File"}
                            disabled={
                              promotionData?.status != "draft" ||
                              values.buyInclusionUploadedFilePublicUrl != null
                            }
                            preLoadedFile={
                              !!values.buyExclusionUploadedFilePublicUrl
                                ? [
                                    {
                                      fileName:
                                        values.buyExclusionUploadedFilePublicUrl,
                                      displayName:
                                        values.buyExclusionUploadedFilePath,
                                    },
                                  ]
                                : null
                            }
                            hasDisplayName
                            signedUrlCallback={(newUrl, index, publicUrl) => {
                              setFieldValue(
                                "buyExclusionUploadedFilePath",
                                newUrl
                              );
                              setFieldValue(
                                "buyExclusionUploadedFilePublicUrl",
                                publicUrl
                              );
                            }}
                            isFieldValid={
                              touched?.buyExclusionUploadedFilePublicUrl &&
                              errors?.buyExclusionUploadedFilePublicUrl
                            }
                          />
                          {touched?.buyExclusionUploadedFilePublicUrl &&
                            errors?.buyExclusionUploadedFilePublicUrl && (
                              <Typography fontSize={12} color="error">
                                {errors?.buyExclusionUploadedFilePublicUrl}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {/* GET */}
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#000000", fontFamily: "Montserrat" }}
                  >
                    Get
                  </Typography>
                  <hr color="#EAEAEA" />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    disabled={promotionData?.status != "draft"}
                  >
                    <RadioGroup
                      row
                      name="option"
                      defaultValue={values.getOption}
                      onChange={(event) =>
                        setFieldValue("getOption", event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="discount"
                        control={<Radio />}
                        label="Discount"
                      />
                      <FormControlLabel
                        value="allProducts"
                        control={<Radio />}
                        label="All Products"
                      />
                      <FormControlLabel
                        value="inc/exc"
                        control={<Radio />}
                        label="Include/Exclude Products"
                      />
                    </RadioGroup>
                    {touched?.getOption && errors?.getOption && (
                      <Typography fontSize={12} color="error">
                        {errors?.getOption}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {values.getOption === "inc/exc" && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Include
                      </Typography>
                      <hr color="#EAEAEA" />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={
                          touched.getInclusionType &&
                          Boolean(errors.getInclusionType)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          Inclusion Type
                        </InputLabel>
                        <Select
                          as={Select}
                          name="getInclusionType"
                          label="Inclusion Type"
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px", marginTop: "10px" }}
                            />
                          )}
                          value={values.getInclusionType}
                          onChange={(e) => {
                            setFieldValue("getInclusionType", e.target.value);
                            // setFieldTouched(`getInclusionType`, true, false);
                            // validateField(`getInclusionType`, e.target.value);
                          }}
                          error={
                            touched.getInclusionType &&
                            Boolean(errors.getInclusionType)
                          }
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="specificProduct">
                            Specific Products
                          </MenuItem>
                          <MenuItem value="uploadExcel">Upload Excel</MenuItem>
                        </Select>
                        {touched.getInclusionType && errors.getInclusionType ? (
                          <FormHelperText>
                            {errors.getInclusionType}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid> */}
                    {values.getInclusionType === "specificProduct" && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            value={values?.getInclusionSpecificProducts}
                            options={
                              productList?.map((item) => ({
                                value: item?.id,
                                label: item?.itemName,
                              })) || []
                            }
                            getOptionLabel={(option) => option.label || ""}
                            loading={productLoading}
                            onChange={(event, products) => {
                              setFieldValue(
                                "getInclusionSpecificProducts",
                                products
                              );
                              // setSelectedBrand(newValue?.value);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                px: 2,
                                backgroundColor: "white.main",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Products"
                                variant="outlined"
                                fullWidth
                                error={
                                  touched.getInclusionSpecificProducts &&
                                  Boolean(errors.getInclusionSpecificProducts)
                                }
                                helperText={
                                  touched.getInclusionSpecificProducts
                                    ? errors.getInclusionSpecificProducts
                                    : ""
                                }
                              />
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={option?.label}
                                  size="small"
                                />
                              ));
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {values.getInclusionType === "uploadExcel" && (
                      <>
                        <Grid item xs={12}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontSize: "15px",
                              }}
                            >
                              Upload Excel File
                            </span>
                            <span
                              style={{
                                color: "#9E9E9E",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {" "}
                              Please upload .xlsx or .csv files only
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                startIcon={
                                  <img
                                    src={downloadIcon}
                                    alignItems={"center"}
                                  />
                                }
                                component={"label"}
                                onClick={() => {
                                  downloadTemplate(
                                    "include_product_template.xlsx"
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#000000",
                                  }}
                                >
                                  Include Product Template Excel
                                </Typography>
                              </Button>
                            </span>
                          </Typography>
                          <FileUploaderContainer
                            keyPrefix="getInclude"
                            isMultiple={false}
                            fieldName={"Choose File"}
                            disabled={
                              promotionData?.status != "draft" ||
                              values.getExclusionUploadedFilePublicUrl != null
                            }
                            preLoadedFile={
                              !!values.getInclusionUploadedFilePublicUrl
                                ? [
                                    {
                                      fileName:
                                        values.getInclusionUploadedFilePublicUrl,
                                      displayName:
                                        values.getInclusionUploadedFilePath,
                                    },
                                  ]
                                : null
                            }
                            hasDisplayName
                            signedUrlCallback={(newUrl, index, publicUrl) => {
                              setFieldValue(
                                "getInclusionUploadedFilePath",
                                newUrl
                              );
                              setFieldValue(
                                "getInclusionUploadedFilePublicUrl",
                                publicUrl
                              );
                            }}
                            isFieldValid={
                              touched?.getInclusionUploadedFilePublicUrl &&
                              errors?.getInclusionUploadedFilePublicUrl
                            }
                          />
                          {touched?.getInclusionUploadedFilePublicUrl &&
                            errors?.getInclusionUploadedFilePublicUrl && (
                              <Typography fontSize={12} color="error">
                                {errors?.getInclusionUploadedFilePublicUrl}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                    {/* EXCLUDE */}
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Exclude
                      </Typography>
                      <hr color="#EAEAEA" />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={
                          touched.getExclusionType &&
                          Boolean(errors.getExclusionType)
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          Exclusion Type
                        </InputLabel>
                        <Select
                          as={Select}
                          name="getExclusionType"
                          label="Exclusion Type"
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px", marginTop: "10px" }}
                            />
                          )}
                          value={values.getExclusionType}
                          onChange={(e) => {
                            setFieldValue("getExclusionType", e.target.value);
                            // setFieldTouched(`getExclusionType`, true, false);
                            // validateField(`getExclusionType`, e.target.value);
                          }}
                          error={
                            touched.getExclusionType &&
                            Boolean(errors.getExclusionType)
                          }
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="specificProduct">
                            Specific Products
                          </MenuItem>
                          <MenuItem value="uploadExcel">Upload Excel</MenuItem>
                        </Select>
                        {touched.getExclusionType && errors.getExclusionType ? (
                          <FormHelperText>
                            {errors.getExclusionType}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid> */}
                    {values.getExclusionType === "specificProduct" && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            value={values?.getExclusionSpecificProducts}
                            options={
                              productList?.map((item) => ({
                                value: item?.id,
                                label: item?.itemName,
                              })) || []
                            }
                            getOptionLabel={(option) => option.label || ""}
                            loading={productLoading}
                            onChange={(event, products) => {
                              setFieldValue(
                                "getExclusionSpecificProducts",
                                products
                              );
                              // setSelectedBrand(newValue?.value);
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                px: 2,
                                backgroundColor: "white.main",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Products"
                                variant="outlined"
                                fullWidth
                                error={
                                  touched.getExclusionSpecificProducts &&
                                  Boolean(errors.getExclusionSpecificProducts)
                                }
                                helperText={
                                  touched.getExclusionSpecificProducts
                                    ? errors.getExclusionSpecificProducts
                                    : ""
                                }
                              />
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={option?.label}
                                  size="small"
                                />
                              ));
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {values.getExclusionType === "uploadExcel" && (
                      <>
                        <Grid item xs={12}>
                          <Typography>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontSize: "15px",
                              }}
                            >
                              Upload Excel File
                            </span>
                            <span
                              style={{
                                color: "#9E9E9E",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {" "}
                              Please upload .xlsx or .csv files only
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                startIcon={
                                  <img
                                    src={downloadIcon}
                                    alignItems={"center"}
                                  />
                                }
                                component={"label"}
                                onClick={() => {
                                  downloadTemplate(
                                    "exclude_product_template.xlsx"
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#000000",
                                  }}
                                >
                                  Exclude Product Template Excel
                                </Typography>
                              </Button>
                            </span>
                          </Typography>
                          <FileUploaderContainer
                            keyPrefix="getExclude"
                            isMultiple={false}
                            fieldName={"Choose File"}
                            disabled={
                              promotionData?.status != "draft" ||
                              values.getInclusionUploadedFilePublicUrl != null
                            }
                            preLoadedFile={
                              !!values.getExclusionUploadedFilePublicUrl
                                ? [
                                    {
                                      fileName:
                                        values.getExclusionUploadedFilePublicUrl,
                                      displayName:
                                        values.getExclusionUploadedFilePath,
                                    },
                                  ]
                                : null
                            }
                            hasDisplayName
                            signedUrlCallback={(newUrl, index, publicUrl) => {
                              setFieldValue(
                                "getExclusionUploadedFilePath",
                                newUrl
                              );
                              setFieldValue(
                                "getExclusionUploadedFilePublicUrl",
                                publicUrl
                              );
                            }}
                            isFieldValid={
                              touched?.getExclusionUploadedFilePublicUrl &&
                              errors?.getExclusionUploadedFilePublicUrl
                            }
                          />
                          {touched?.getExclusionUploadedFilePublicUrl &&
                            errors?.getExclusionUploadedFilePublicUrl && (
                              <Typography fontSize={12} color="error">
                                {errors?.getExclusionUploadedFilePublicUrl}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                  </>
                )}

                <Grid
                  container
                  xs={12}
                  sx={{ mt: 2 }}
                  flexDirection={"row-reverse"}
                  gap={3}
                >
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="dark"
                  >
                    Next
                  </MDButton>
                  <MDButton
                    color="dark"
                    variant="outlined"
                    circular={true}
                    // style={{ backgroundColor: "#FFFFFF" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    )
  );
};

export default AssortmentForm;
