import { useMutation } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { type } from "@testing-library/user-event/dist/type";
import { useNotification } from "context";
import { useFormik } from "formik";
import { dashboardServiceClient } from "graphql/client";
import React from "react";
import { CREATE_PROMOTION_COMMENT } from "services/promotion-service";
import * as Yup from "yup";
import { getStatusChipProps } from "./promotionLandingPage";

const validationSchema = Yup.object().shape({
  promotionId: Yup.string().required(),
  type: Yup.string().required(), // Optional field
  commentText: Yup.string().required().max(50, "max 50 characters allowed")
});

const getTypeFromStatus = (status) => {
  switch (status) {
    case "rejectedByOps":
      return "promoRejectedByOps";
    case "rejectedByMDM":
      return "promoRejectedByMDM";
    case "live":
      return "promoLive";
    default:
      return null;
  }
};

export const AddCommentForm = ({
  promoId,
  status,
  label,
  open,
  setOpen,
  callback,
}) => {
  const type = getTypeFromStatus(status);
  const { setNotification } = useNotification();
  const mutationOptions = {
    client: dashboardServiceClient,
  };
  const [createCommentMutation] = useMutation(
    CREATE_PROMOTION_COMMENT,
    mutationOptions
  );
  const formik = useFormik({
    initialValues: {
      commentText: "",
      type,
      promotionId: promoId,
    },
    enableReinitialize:true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const mutationInput = {
        commentInput: {...values,commentText: label + " : " + values.commentText},
      };
      setOpen(false);
      if (callback) callback(mutationInput.commentInput);
      // createCommentMutation({ variables: mutationInput })
      //   .then((res) => {
      //     if (res.data?.createPromotionComment?.id) {
      //       setNotification({
      //         color: "success",
      //         isVisible: true,
      //         message: "Promotion comment created successfully",
      //       });
      //       handleClose();
      //       callback(res);
      //     } else {
      //       setNotification({
      //         color: "error",
      //         isVisible: true,
      //         message: res?.errors?.[0]?.message || "Something went wrong",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error?.message);
      //     setNotification({
      //       color: "error",
      //       isVisible: true,
      //       message: error?.message || "Something went wrong",
      //     });
      //   });
      
    },
  });

  const handleClose = () => {
    formik.resetForm(); // Reset the form on close
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{fontSize:16}}>
        <p style={{textAlign:'center'}}>Add comment</p>
        <Divider />
        {/* <p>Promo ID: {promoId}</p> */}
        <p>Status change to: <span style={{textDecoration:'underline'}}>{getStatusChipProps(status).label}</span></p>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            name="commentText"
            label={label}
            value={formik.values.commentText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.commentText && Boolean(formik.errors.commentText)
            }
            helperText={formik.touched.commentText && formik.errors.commentText}
            margin="normal"
          />
          {/* {JSON.stringify(formik.errors)} */}
        </form>
      </DialogContent>
      <DialogActions>
        <MDButton
          type="submit"
          onClick={formik.submitForm}
          variant="contained"
          circular={true}
          color="dark"
        >
          Submit
        </MDButton>
        <MDButton
          color="dark"
          variant="outlined"
          circular={true}
          // style={{ backgroundColor: "#FFFFFF" }}
          onClick={handleClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
