import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";

// @mui material components
import { Button, Grid, TextField, Typography } from "@mui/material";

// Formik & Yup React Form Validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Material Dashboard 2 React components
import Loader from "components/MDLoader";

// Apollo Client
import { useLazyQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

// Custom Context
import { useNotification, AuthContext } from "context";

// Graphql query & mutation
import {
  GET_VENDOR_TAB_DETAILS_BY_ID,
  CREATE_BRAND_METADATA,
  UPDATE_BRAND_METADATA,
} from "services/brand-service";
import RequiredLabel from "layouts/stores/requiredLabel";

// Yup Form Validation Schema
const validationSchema = Yup.object({
  nameSurnameBusiness: Yup.string().required("Business Name is required"),
  phoneNoBusiness: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  mobileNoBusiness: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  emailBusiness: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  nameSurnameAccount: Yup.string().required("Account/Finance Name is required"),
  phoneNoAccount: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  mobileNoAccount: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  emailAccount: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  nameSurnameOperations: Yup.string().required("Operation Name is required"),
  phoneNoOperations: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  mobileNoOperations: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  emailOperations: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  bwSpocName: Yup.string().required("SPOC Name is Required"),
  bwsSpocMobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  spocPhone: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  bwSpocEmail: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  authorizedSignatoryName: Yup.string().required("Authorized Signatory Name is Required"),
  authorizedSignatoryMobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  authorizedSignatoryPhone: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  authorizedSignatoryEmail: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
  founderName: Yup.string().required("Founder Name is required"),
  founderPhone: Yup.string().matches(
    /^[6-9][0-9]{9}$/,
    "Phone number is not valid"
  ),
  founderMobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid")
    .required("Mobile number is required"),
  founderEmail: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("Email is required"),
});

/**
 *
 * @param {*} vendorDetail desc: payload details
 * @param {*} setNotification desc: user feedback notification
 * @param {*} handleTabChange desc: callback function to chnage tab value to next
 * @param {*} refetchQuery desc: query to refetch after successful mutation
 * @returns handleSubmit callback function (values) => { implementation of submit function }
 */
const useMetadataMutation = (
  vendorDetail,
  setNotification,
  handleTabChange,
  refetchQuery,
  setFormTabValue,
  tabValueFlag
) => {
  const brandMetadata =
    vendorDetail?.brandMetaData?.find(
      (data) => !data.isDeleted && data.key === "ContactDetails"
    ) || {};
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQuery,
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
            metaDataInput: {
              id: brandMetadata.id,
              key: "ContactDetails",
              value: { ...brandMetadata.value, ...values },
            },
          }
        : {
            metaDataInput: {
              brandId: vendorDetail.id,
              key: "ContactDetails",
              value: { ...values },
            },
          };

    mutation({ variables: mutationInput })
      .then(() => {
        // handleTabChange(null, 3);
        tabValueFlag && setFormTabValue(3);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Contact data updated successfully"
              : "Brand Contact data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const BrandContactDetailsFormComponent = forwardRef(({ handleTabChange, brandData, setDirtyData, setShowDialog, setFormTabValue }, ref) => {
  const { setNotification } = useNotification();
  const { user } = useContext(AuthContext);

  const [vendorDetail, setVendorDetail] = useState(null);
  const [comapanyDetail, setCompanyDetail] = useState(null);
  const [ tabValueFlag, setTabValueFlag ] = useState(false)

  useEffect(()=>{
    setShowDialog(false)
    setTabValueFlag(false)
  },[])

  const [
    getVendorDetail,
    {
      loading: vendorDetailLoading,
      error: vendorDetailError,
      refetch: vendorDetailRefetch,
    },
  ] = useLazyQuery(GET_VENDOR_TAB_DETAILS_BY_ID, {
    client: dashboardServiceClient,
    onCompleted: (response) => {
      const vendorDetail = response.brandlist.results[0];
      const companyData =
        vendorDetail?.brandMetaData.find(
          (data) => data.key === "ContactDetails"
        ) || {};
      setVendorDetail(vendorDetail);
      setCompanyDetail(companyData);
    },
    onError: (error) => {
      console.error(error);
      setNotification({
        color: "error",
        isVisible: true,
        message: "Error: fetching company details",
      });
    },
  });

  useEffect(() => {
    if (user) {
      getVendorDetail({
        variables: {
          take: 1,
          skip: 0,
          filter: {
            id: brandData ? brandData?.id : user.userId,
            brandMetaData: {
              key: "ContactDetails",
            },
          },
        },
      });
    }
  }, [user]);

  const handleSubmit = useMetadataMutation(
    vendorDetail || { id: brandData ? brandData?.id : user.userId }, // * if vendor is undefined incase for new entry
    setNotification,
    handleTabChange,
    vendorDetailRefetch,
    setFormTabValue,
    tabValueFlag
  );

  if (vendorDetailLoading) return <Loader />;
  if (vendorDetailError) return <>Error: {vendorDetailError.message}</>;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        username: brandData?.username || user?.username || "",
        nameSurnameBusiness: comapanyDetail?.value?.nameSurnameBusiness || "",
        phoneNoBusiness: comapanyDetail?.value?.phoneNoBusiness || "",
        mobileNoBusiness: comapanyDetail?.value?.mobileNoBusiness || "",
        emailBusiness: comapanyDetail?.value?.emailBusiness || "",
        nameSurnameAccount: comapanyDetail?.value?.nameSurnameAccount || "",
        phoneNoAccount: comapanyDetail?.value?.phoneNoAccount || "",
        mobileNoAccount: comapanyDetail?.value?.mobileNoAccount || "",
        emailAccount: comapanyDetail?.value?.emailAccount || "",
        nameSurnameOperations:
          comapanyDetail?.value?.nameSurnameOperations || "",
        phoneNoOperations: comapanyDetail?.value?.phoneNoOperations || "",
        mobileNoOperations: comapanyDetail?.value?.mobileNoOperations || "",
        emailOperations: comapanyDetail?.value?.emailOperations || "",
        bwSpocName: comapanyDetail?.value?.bwSpocName || "",
        bwsSpocMobile: comapanyDetail?.value?.bwsSpocMobile || "",
        spocPhone: comapanyDetail?.value?.spocPhone || "",
        bwSpocEmail: comapanyDetail?.value?.bwSpocEmail || "",
        authorizedSignatoryName: comapanyDetail?.value?.authorizedSignatoryName || "",
        authorizedSignatoryMobile: comapanyDetail?.value?.authorizedSignatoryMobile || "",
        authorizedSignatoryPhone: comapanyDetail?.value?.authorizedSignatoryPhone || "",
        authorizedSignatoryEmail: comapanyDetail?.value?.authorizedSignatoryEmail || "",
        founderName: comapanyDetail?.value?.founderName || "",
        founderMobile: comapanyDetail?.value?.founderMobile || "",
        founderPhone: comapanyDetail?.value?.founderPhone || "",
        founderEmail: comapanyDetail?.value?.founderEmail || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(formik) => {
        useEffect(() => {
          // return () => {
            // * will check if unsave data then show warning data loss
            // TODO: implement confirmation block to save or discard data
            // if (formik.dirty) {
              //   setNotification({
                //     color: "warning",
                //     isVisible: true,
                //     message: "Warning: Unsaved data was lost",
                //   });
                // }
                // };
            if (formik.dirty) {
              setDirtyData(true)
            }
            else {
              setDirtyData(false)
            }
          }, [formik.dirty]);
          useImperativeHandle(ref, () => ({
            handleSubmitContactDetail
: formik.handleSubmit,
            validateFormContactDetail: () => { 
              formik.setTouched(Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true })));
              return formik.errors
            },
          }));

        return (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container sx={{mb:2}} >
                  <Grid item xs={12}>
                    <Field
                      name="username"
                      as={TextField}
                      label="Email Id for login"
                      fullWidth
                      helperText={
                        formik.touched.username &&
                        formik.errors.username
                      }
                      error={
                        formik.touched.username &&
                        !!formik.errors.username
                      }
                      disabled={user?.role === 'brand_admin'}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Authorized Signatory Contact
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="authorizedSignatoryName"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.authorizedSignatoryName && formik.errors.authorizedSignatoryName
                      }
                      error={
                        formik.touched.authorizedSignatoryName && !!formik.errors.authorizedSignatoryName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="authorizedSignatoryMobile"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e)=>{
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                            formik.setFieldValue('authorizedSignatoryMobile', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.authorizedSignatoryMobile && formik.errors.authorizedSignatoryMobile
                      }
                      error={
                        formik.touched.authorizedSignatoryMobile && !!formik.errors.authorizedSignatoryMobile
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="authorizedSignatoryPhone"
                      as={TextField}
                      label="Phone No."
                      onChange={(e)=>{
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                            formik.setFieldValue('authorizedSignatoryPhone', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.authorizedSignatoryPhone && formik.errors.authorizedSignatoryPhone
                      }
                      error={
                        formik.touched.authorizedSignatoryPhone && !!formik.errors.authorizedSignatoryPhone
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="authorizedSignatoryEmail"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      fullWidth
                      helperText={
                        formik.touched.authorizedSignatoryEmail && formik.errors.authorizedSignatoryEmail
                      }
                      error={
                        formik.touched.authorizedSignatoryEmail && !!formik.errors.authorizedSignatoryEmail
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{mt:2}}>
                  <Grid item xs={12} >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Founder Contact
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="founderName"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.founderName && formik.errors.founderName
                      }
                      error={
                        formik.touched.founderName &&
                        !!formik.errors.founderName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="founderMobile"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('founderMobile', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.founderMobile &&
                        formik.errors.founderMobile
                      }
                      error={
                        formik.touched.founderMobile &&
                        !!formik.errors.founderMobile
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="founderPhone"
                      as={TextField}
                      label="Phone No."
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('founderPhone', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.founderPhone &&
                        formik.errors.founderPhone
                      }
                      error={
                        formik.touched.founderPhone &&
                        !!formik.errors.founderPhone
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="founderEmail"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      fullWidth
                      helperText={
                        formik.touched.founderEmail &&
                        formik.errors.founderEmail
                      }
                      error={
                        formik.touched.founderEmail &&
                        !!formik.errors.founderEmail
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#000000", fontFamily: "Montserrat" }}
                  >
                    Business Contact
                  </Typography>
                  <hr color="#EAEAEA" />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="nameSurnameBusiness"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.nameSurnameBusiness &&
                        formik.errors.nameSurnameBusiness
                      }
                      error={
                        formik.touched.nameSurnameBusiness &&
                        !!formik.errors.nameSurnameBusiness
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mobileNoBusiness"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('mobileNoBusiness', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.mobileNoBusiness &&
                        formik.errors.mobileNoBusiness
                      }
                      error={
                        formik.touched.mobileNoBusiness &&
                        !!formik.errors.mobileNoBusiness
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="phoneNoBusiness"
                      as={TextField}
                      label="Phone No."
                      fullWidth
                      helperText={
                        formik.touched.phoneNoBusiness &&
                        formik.errors.phoneNoBusiness
                      }
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('phoneNoBusiness', e.target.value);
                        }
                      }}
                      error={
                        formik.touched.phoneNoBusiness &&
                        !!formik.errors.phoneNoBusiness
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="emailBusiness"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      fullWidth
                      helperText={
                        formik.touched.emailBusiness &&
                        formik.errors.emailBusiness
                      }
                      error={
                        formik.touched.emailBusiness &&
                        !!formik.errors.emailBusiness
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Account/Finance Contact
                      <span
                        style={{
                          color: "#9E9E9E",
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {" "}
                        ( For payment related queries )
                      </span>
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="nameSurnameAccount"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.nameSurnameAccount &&
                        formik.errors.nameSurnameAccount
                      }
                      error={
                        formik.touched.nameSurnameAccount &&
                        !!formik.errors.nameSurnameAccount
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mobileNoAccount"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('mobileNoAccount', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.mobileNoAccount &&
                        formik.errors.mobileNoAccount
                      }
                      error={
                        formik.touched.mobileNoAccount &&
                        !!formik.errors.mobileNoAccount
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="phoneNoAccount"
                      as={TextField}
                      label="Phone No."
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('phoneNoAccount', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.phoneNoAccount &&
                        formik.errors.phoneNoAccount
                      }
                      error={
                        formik.touched.phoneNoAccount &&
                        !!formik.errors.phoneNoAccount
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="emailAccount"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      type="email"
                      fullWidth
                      helperText={
                        formik.touched.emailAccount &&
                        formik.errors.emailAccount
                      }
                      error={
                        formik.touched.emailAccount &&
                        !!formik.errors.emailAccount
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Operations Contact
                      <span
                        style={{
                          color: "#9E9E9E",
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {" "}
                        ( For logistics or warehouse related queries )
                      </span>
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="nameSurnameOperations"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.nameSurnameOperations &&
                        formik.errors.nameSurnameOperations
                      }
                      error={
                        formik.touched.nameSurnameOperations &&
                        !!formik.errors.nameSurnameOperations
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mobileNoOperations"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('mobileNoOperations', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.mobileNoOperations &&
                        formik.errors.mobileNoOperations
                      }
                      error={
                        formik.touched.mobileNoOperations &&
                        !!formik.errors.mobileNoOperations
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="phoneNoOperations"
                      as={TextField}
                      label="Phone No."
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                          formik.setFieldValue('phoneNoOperations', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.phoneNoOperations &&
                        formik.errors.phoneNoOperations
                      }
                      error={
                        formik.touched.phoneNoOperations &&
                        !!formik.errors.phoneNoOperations
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="emailOperations"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      fullWidth
                      helperText={
                        formik.touched.emailOperations &&
                        formik.errors.emailOperations
                      }
                      error={
                        formik.touched.emailOperations &&
                        !!formik.errors.emailOperations
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Broadway SPOC Contact
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="bwSpocName"
                      as={TextField}
                      label={<RequiredLabel label="Name Surname"/>}
                      fullWidth
                      helperText={
                        formik.touched.bwSpocName && formik.errors.bwSpocName
                      }
                      error={
                        formik.touched.bwSpocName && !!formik.errors.bwSpocName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="bwsSpocMobile"
                      as={TextField}
                      label={<RequiredLabel label="Mobile No."/>}
                      onChange={(e)=>{
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                            formik.setFieldValue('bwsSpocMobile', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.bwsSpocMobile && formik.errors.bwsSpocMobile
                      }
                      error={
                        formik.touched.bwsSpocMobile && !!formik.errors.bwsSpocMobile
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="spocPhone"
                      as={TextField}
                      label="Phone No."
                      onChange={(e)=>{
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                            formik.setFieldValue('spocPhone', e.target.value);
                        }
                      }}
                      fullWidth
                      helperText={
                        formik.touched.spocPhone && formik.errors.spocPhone
                      }
                      error={
                        formik.touched.spocPhone && !!formik.errors.spocPhone
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="bwSpocEmail"
                      as={TextField}
                      label={<RequiredLabel label="Email"/>}
                      fullWidth
                      helperText={
                        formik.touched.bwSpocEmail && formik.errors.bwSpocEmail
                      }
                      error={
                        formik.touched.bwSpocEmail && !!formik.errors.bwSpocEmail
                      }
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{ mt: 3 }}
                    justifyContent={"flex-end"}
                    gap={3}
                  >
                    <Button
                      sx={{
                        color: "#000000",
                        borderRadius: "50px",
                        borderColor: "#000000",
                        fontFamily: "Montserrat",
                      }}
                      variant="outlined"
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            nameSurnameBusiness: "",
                            phoneNoBusiness: "",
                            mobileNoBusiness: "",
                            emailBusiness: "",
                            nameSurnameAccount: "",
                            phoneNoAccount: "",
                            mobileNoAccount: "",
                            emailAccount: "",
                            nameSurnameOperations: "",
                            phoneNoOperations: "",
                            mobileNoOperations: "",
                            emailOperations: "",
                            authorizedSignatoryName: "",
                            authorizedSignatoryMobile: "",
                            authorizedSignatoryPhone: "",
                            authorizedSignatoryEmail: "",
                            bwSpocName: "",
                            bwsSpocMobile: "",
                            spocPhone: "",
                            bwSpocEmail: "",
                            founderName: "",
                            founderEmail: "",
                            founderMobile: "",
                            founderPhone: "",
                          },
                        })
                      }
                    >
                      Reset
                    </Button>
                    <Button
                      // type="submit"
                      onClick={()=>{
                        setTabValueFlag(true)
                        formik.handleSubmit();
                      }}
                      variant="contained"
                      style={{
                        marginRight: "10px",
                        backgroundColor: "#000000",
                        color: "#fff",
                        borderRadius: "50px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});

export default BrandContactDetailsFormComponent;
