import React from 'react';
// Formik Imports
import { Field, FieldArray } from 'formik';
// MUI Components
import { Box, Grid, TextField, Typography } from '@mui/material';
// Custom Components
import SelectComponent from './selectComponent'; 
import MDButton from "components/MDButton";

const CartLevelDiscountForm = ({ values, setFieldValue, errors, touched, disabled=false }) => {
    return (
      <>
        <Grid item xs={12}>
          <SelectComponent
            disabled={disabled}
            label="Promotion Trigger"
            name="promotionTrigger"
            value={values.promotionTrigger}
            options={[
              { value: "directPromotion", label: "Direct Promotion" },
              { value: "couponBased", label: "Coupon Based" },
            ]}
            setFieldValue={setFieldValue}
            error={errors.promotionTrigger}
            helperText={errors.promotionTrigger}
            touched={touched.promotionTrigger}
          />
        </Grid>

        {values.promotionTrigger == "couponBased" && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="voucherQuantity"
              label="Voucher Quantity"
              type="number"
              minValue={1}
              value={values.voucherQuantity}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only positive numbers and prevent "e" or negative values
                if (/^\d*\.?\d*$/.test(value)) {
                  setFieldValue("voucherQuantity", value);
                }
              }}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                // Prevent input of ".", "-", "e", or any non-numeric keys
                if ([".", "-", "e", "E"].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={disabled}
              error={Boolean(errors.voucherQuantity)}
              helperText={errors.voucherQuantity}
              touched={touched.voucherQuantity}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SelectComponent
            disabled={disabled}
            label="Promotion Type"
            name="promotionType"
            value={values.promotionType}
            options={[
              {
                value: "cartValueDisc",
                label: "Cart Value Based Discount",
              },
            ]}
            setFieldValue={setFieldValue}
            error={errors.promotionType}
            helperText={errors.promotionType}
            touched={touched.promotionType}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "600" }}
            gutterBottom
          >
            Buy Specification
          </Typography>
          <hr color="#EAEAEA" />
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            disabled={disabled}
            label="Condition Type"
            name="conditionType"
            value={values.conditionType}
            options={[
              {
                value: "buySpecifiedAssortment",
                label: "Buy From Specified Assortment",
              },
            ]}
            error={errors.conditionType}
            helperText={errors.conditionType}
            touched={touched.conditionType}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setFieldValue("conditionType", selectedValue);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "600" }}
            gutterBottom
          >
            Get Specification
          </Typography>
          <hr color="#EAEAEA" />
        </Grid>
        <Grid item xs={12}>
          <SelectComponent
            disabled={disabled}
            label="Benifit Type"
            name="benifitType"
            value={values.benifitType}
            options={[
              { value: "singleSlab", label: "Single Slab" },
              { value: "multiSlabs", label: "Multi Slabs" },
            ]}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setFieldValue("benifitType", selectedValue);
              // Clear slabs when changing to singleSlab
              if (selectedValue === "singleSlab") {
                setFieldValue("slabs", [
                  {
                    slabfromCartValue: "",
                    slabtoCartValue: "",
                    discountType: "",
                    value: "",
                  },
                ]);
              }
            }}
            error={errors.benifitType}
            helperText={errors.benifitType}
            touched={touched.benifitType}
          />
        </Grid>
        <Grid item xs={12}>
          {values.benifitType === "multiSlabs" && (
            <Typography
              sx={{ fontFamily: "montserrat", color: "#000", fontSize: 12 }}
            >
              <span style={{ color: "red" }}>Note:</span> Create minimum 2 slabs
              for multi slabs get benefit type
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            border:
              typeof errors.slabs === "string" &&
              !Array.isArray(errors.slabs) &&
              errors.slabs !== null
                ? "1px dotted red"
                : "",
          }}
          padding={1}
        >
          <FieldArray name="slabs">
            {({ push, remove }) => (
              <>
                {Array.isArray(values.slabs) &&
                  values.slabs.map((slab, index) => (
                    <Grid
                      container
                      spacing={2}
                      sx={{ backgroundColor: "black" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          fontSize={14}
                          ml={2}
                          sx={{
                            fontFamily: "montserrat",
                            color: "#000",
                            fontWeight: "600",
                          }}
                          gutterBottom
                        >{`Slab ${index + 1}`}</Typography>
                        <hr color="#EAEAEA" />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name={`slabs[${index}].slabfromCartValue`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabled}
                              type="number"
                              fullWidth
                              label="From Cart Value"
                              error={Boolean(
                                errors?.slabs?.[index]?.slabfromCartValue
                              )}
                              helperText={
                                errors?.slabs?.[index]?.slabfromCartValue
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name={`slabs[${index}].slabtoCartValue`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              fullWidth
                              label="To Cart Value"
                              error={Boolean(
                                errors?.slabs?.[index]?.slabtoCartValue
                              )}
                              helperText={
                                errors?.slabs?.[index]?.slabtoCartValue
                              }
                              disabled={
                                disabled || values.benifitType === "singleSlab"
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <SelectComponent
                          disabled={disabled}
                          label="Discount Type"
                          name={`slabs[${index}].discountType`}
                          value={values.slabs[index].discountType}
                          options={[
                            {
                              value: "percentageDiscount",
                              label: "Percentage Discount",
                            },
                            { value: "priceDiscount", label: "Price Discount" },
                          ]}
                          error={errors?.slabs?.[index]?.discountType}
                          helperText={errors?.slabs?.[index]?.discountType}
                          touched={touched?.slabs?.[index]?.discountType}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFieldValue(
                              `slabs[${index}].discountType`,
                              selectedValue
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name={`slabs[${index}].value`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              disabled={disabled}
                              type="number"
                              fullWidth
                              label={
                                values.slabs[index].discountType ===
                                "percentageDiscount"
                                  ? "Percent"
                                  : "Value"
                              }
                              error={Boolean(
                                errors?.slabs?.[index]?.value
                              )}
                              helperText={errors?.slabs?.[index]?.value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        container
                        sx={{ display: "flex", justifyContent: "end" }}
                        gap={2}
                      >
                        {values?.benifitType === "multiSlabs" &&
                          index === values.slabs.length - 1 && (
                            <Grid item xs={4}>
                              <MDButton
                                disabled={disabled}
                                fullWidth
                                type="button"
                                variant="outlined"
                                color="black"
                                circular={true}
                                onClick={() => {
                                  push({
                                    slabfromCartValue: "",
                                    slabtoCartValue: "",
                                    discountType: "",
                                    value: "",
                                  });
                                }}
                              >
                                + ADD ANOTHER
                              </MDButton>
                            </Grid>
                          )}
                        {values.benifitType === "multiSlabs" && index > 1 && (
                          <Grid item xs={2}>
                            <MDButton
                              disabled={disabled}
                              fullWidth
                              type="button"
                              variant="outlined"
                              color="black"
                              circular={true}
                              onClick={() => remove(index)}
                            >
                              Remove
                            </MDButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
              </>
            )}
          </FieldArray>
        </Grid>
        {typeof errors.slabs === "string" &&
          !Array.isArray(errors.slabs) &&
          errors.slabs !== null && (
            <Typography
              sx={{ fontFamily: "montserrat", color: "#000", fontSize: 12 }}
            >
              <span style={{ color: "red" }}>{errors.slabs}</span>
            </Typography>
          )}
      </>
    );
};

export default CartLevelDiscountForm;
