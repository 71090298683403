import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Grid, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import ItemLevelDiscountForm from "./itemLevelForm";
import CartLevelDiscountForm from "./cartLevelForm";
import SelectComponent from "./selectComponent";
import { dashboardServiceClient } from "graphql/client";
import { CREATE_PROMOTION_CONDITIONS } from "services/promotion-service";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNotification } from "context";
import { PromotionFormContext } from ".";
import { GET_PROMOTION_LIST } from "services/promotion-service";

// Example Yup validation schema
const validationSchema = Yup.object().shape({
  promotionApplicability: Yup.string().required("Required"),
  promotionTrigger: Yup.string().required("Required"),
  voucherQuantity: Yup.number().min(1, "value cannot be negative or zero").when("promotionTrigger", {
    is: (val) => val === "couponBased",
    then: () => Yup.number().required("Required").min(1, "value cannot be negative or zero"),
    otherwise: () => Yup.number().notRequired().min(1, "value cannot be negative or zero"),
  }),
  promotionType: Yup.string().required("Required"),
  conditionType: Yup.string().required("Required"),
  quantity: Yup.number().min(1, "value cannot be negative or zero").when("promotionApplicability", {
    is: (val) => val === "itemLevel",
    then: () => Yup.number().required("Required").min(1, "value cannot be negative or zero"),
    otherwise: () => Yup.number().notRequired().min(1, "value cannot be negative or zero"),
  }),
  benifitType: Yup.string().required("Required"),
  discountType: Yup.string().when("promotionApplicability", {
    is: (val) => val === "itemLevel",
    then: () => Yup.string().required("Required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  value: Yup.number().typeError("value must be a number").when("promotionApplicability", {
    is: (val) => val === "itemLevel",
    // then: () => Yup.number().required('Required'),
    then: () =>
      Yup.number().typeError("").when("discountType", {
        is: (val) => val === "percentageDiscount",
        then: () =>
          Yup.number().typeError("value must be a number")
            .required("Required")
            .min(0, "Percentage cannot be negative")
            .max(100, "Percentage cannot exceed 100%"),
        otherwise: () => Yup.number().typeError("value must be a number").required("Required").min(0.001, "value cannot be negative or 0"),
      }),
    otherwise: () => Yup.number().notRequired().min(0.001, "value cannot be negative or 0"),
  }),
  price: Yup.string().when("promotionApplicability", {
    is: (val) => val === "itemLevel",
    then: () => 
      Yup.number().when("discountType", {
        is: (val) => val === "fixedPrice",
        then: () =>
          Yup.string().notRequired(),
        otherwise: () => Yup.string().required("Required")
      }),
    otherwise: () => Yup.string().notRequired(),
  }),
  slabs: Yup.array().when("promotionApplicability", {
    is: (val) => {
      return val === "cartLevel";
    },
    then: () =>
      Yup.array().when("benifitType", {
        is: (val) => {
          return val === "singleSlab";
        },
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                slabfromCartValue: Yup.number().required("Required").min(0.0001, "value cannot be negative or 0"),
                slabtoCartValue: Yup.number().notRequired().min(0.0001, "value cannot be negative or 0"),
                discountType: Yup.string().required("Required"),
                value: Yup.number().typeError("value must be a number").when("discountType", {
                  is: (val) => val === "percentageDiscount",
                  then: () =>
                    Yup.number().typeError("value must be a number")
                      .required("Required")
                      .min(0, "Percentage cannot be negative")
                      .max(100, "Percentage cannot exceed 100%"),
                  otherwise: () => Yup.number().required("Required").min(0.0001, "value cannot be negative or zero"),
                }),
              })
            )
            .min(1, "min 1 is required"),
        otherwise: () => {
          return Yup.array()
            .of(
              Yup.object().shape({
                slabfromCartValue: Yup.number().required("Required").min(0.001, "value cannot be negative or 0"),
                slabtoCartValue: Yup.number()
                  .required("Required")
                  .min(0.0001, "value cannot be negative or zero")
                  .test(
                    "lower-than-from",
                    "To cart value cannot be lower than From cart value",
                    function (value) {
                      const { slabfromCartValue } = this.parent;
                      // Validate only if buyOption is "inc/exc"
                      if (slabfromCartValue) {
                        return value >= slabfromCartValue;
                      }
                      return true; // Pass if not "inc/exc"
                    }
                  ),
                discountType: Yup.string().required("Required"),
                value: Yup.number().typeError("Value must be a number").when("discountType", {
                  is: (val) => val === "percentageDiscount",
                  then: () =>
                    Yup.number().typeError("Percentage must be a valid number")
                      .required("Required")
                      .min(0, "Percentage cannot be negative")
                      .max(100, "Percentage cannot exceed 100%"),
                  otherwise: () => Yup.number().required("Required").min(0.001, "value cannot be negative or zero"),
                }),
              })
            )
            .min(2, "min 2 is required");
        },
      }),
    otherwise: () => {
      console.log("no validation required");
      return Yup.array().notRequired();
    },
  }),
});



const usePromotionMutations = (
  handleClose,
  setNotification
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
  };

  // Only using the create mutation now as per business requirement
  const [createPromotionConditions] = useMutation(
    CREATE_PROMOTION_CONDITIONS,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const payload = {
      promotionId:values.promotionId,
      promotionApplicability:values.promotionApplicability,
      promotionTrigger:values.promotionTrigger,
      voucherQuantity:+values.voucherQuantity,
      buySpecCondition:values.conditionType,
      buySpecConditionQuantity:+values.quantity,
      promotionType:values.promotionType,
      getSpecBenefitType:values.benifitType,
      discountType:values.discountType,
      discountPercentageOrValue:+values.value || 0,
      discountOnPrice:values.price,
      slabs:values.slabs?.map(slab=>({
        slabfromCartValue: +slab.slabfromCartValue,
        slabtoCartValue: +slab.slabtoCartValue,
        discountType: slab.discountType,
        value: +slab.value,
      })),
    }
    const payload1 = {
      promoName: values?.promoName || "",
      description: values?.promoDesc || "",
      start: values?.start,
      end: values?.end,
      startTime: values?.startTime || "",
      endTime: values?.endTime || "",
      brand: values?.brand?.value || "",
      promotionStore: values?.brandStoreId?.map((item) => item.store.id) || [],
      isCustomTime: values?.customTime || false,
      // sponsoredBy: values?.sponsoredBy
    };

    const mutationInput = { conditionDTO: payload };

    createPromotionConditions({ variables: mutationInput })
      .then((res) => {
        if(res.data){
          // setPromotionId(res.data.createGeneralInformationPromotion?.promotionId)
          handleClose()
          setNotification({
            color: "success",
            isVisible: true,
            message: "Promotion Conditions created successfully",
          });
        } else {
          setNotification({
            color: "error",
            isVisible: true,
            message: res.errors?.[0]?.message || "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const Conditions = ({
  handleClose,
  handleChange
}) => {
  const { setNotification } = useNotification();
  const { promotionId, setPromotionId } = useContext(PromotionFormContext);
  
  const [getPromotionList, { loading:promotionLoading, error:promotionError }] = useLazyQuery(
    GET_PROMOTION_LIST,
    {
      client: dashboardServiceClient,
      onError: (error) => {
        console.error("Error: fetching promotions", error);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Error: fetching promotions",
        });
      }
    }
  );
  const [promotionData, setPromotionData] = useState(null);
  const [initialValues, setInitialValues] = useState({
    promotionId,
    promotionApplicability: "itemLevel",
    promotionTrigger: "",
    promotionType: "",
    conditionType: "",
    quantity: "",
    benifitType: "",
    discountType: "",
    value: "",
    price: "",
    slabs: null,
  });

  // const [getPromotionDetails, { loading, data, error}] = useLazyQuery()

  useEffect(() => {
    // TODO: need to call promotion details form to fetch tab form details
    if(promotionId != null){
      getPromotionList({
        variables: {
          skip: 0,
          take: 1,
          filter:{
            id: promotionId
          }
        }
      }).then(res => {
        if(res.data.promotions.results.length > 0){
          setPromotionData(res.data.promotions.results[0])
        }
      })
    } else {
      handleChange(null, 1);
    }
  }, [promotionId])

  useEffect(()=>{
    if(promotionId && promotionData && !promotionData.assortmentPromotion?.length){
      handleChange(null, 1);
    }
  },[promotionData])

  useEffect(() => {
    if (promotionData) {
      setInitialValues({
        promotionId,
        promotionApplicability:
          promotionData?.conditions?.[0]?.promotionApplicability || "itemLevel",
        promotionTrigger: promotionData?.conditions?.[0]?.promotionTrigger,
        voucherQuantity: promotionData?.conditions?.[0]?.voucherQuantity,
        promotionType: promotionData?.conditions?.[0]?.promotionType,
        conditionType: promotionData?.conditions?.[0]?.buySpecCondition,
        quantity: promotionData?.conditions?.[0]?.buySpecConditionQuantity,
        benifitType: promotionData?.conditions?.[0]?.getSpecBenefitType,
        discountType: promotionData?.conditions?.[0]?.discountType,
        value: promotionData?.conditions?.[0]?.discountPercentageOrValue,
        price: promotionData?.conditions?.[0]?.discountOnPrice,
        slabs: promotionData?.conditions?.[0]?.slabs,
      });
    }
  }, [promotionData]);

  const handleSubmit =usePromotionMutations(
    handleClose,
    setNotification
  );

  // const initialValues1 = {
  //   promotionId,
  //   promotionApplicability: promotionData?.conditions?.[0]?.promotionApplicability || "itemLevel",
  //   promotionTrigger: promotionData?.conditions?.[0]?.promotionTrigger,
  //   promotionType: promotionData?.conditions?.[0]?.promotionType,
  //   conditionType: promotionData?.conditions?.[0]?.buySpecCondition,
  //   quantity: promotionData?.conditions?.[0]?.buySpecConditionQuantity,
  //   benifitType: promotionData?.conditions?.[0]?.getSpecBenefitType,
  //   discountType: promotionData?.conditions?.[0]?.discountType,
  //   value: promotionData?.conditions?.[0]?.discountPercentageOrValue,
  //   price: promotionData?.conditions?.[0]?.discountOnPrice,
  //   slabs: promotionData?.conditions?.[0]?.slabs
  // };
  return (
    promotionData && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (promotionData && promotionData.status !== "draft") {
            handleClose();
          } else {
            handleSubmit(values);
          }
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, errors, touched, resetForm }) => (
          <Form>
            {/* {console.log(errors, "errors")} */}
            <Grid container gap={2} p={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "montserrat",
                    color: "#000",
                    fontWeight: "600",
                  }}
                  gutterBottom
                >
                  Promotion Definition
                </Typography>
                <hr color="#EAEAEA" />
              </Grid>
              <Grid item xs={12}>
                <SelectComponent
                  disabled={promotionData?.status != "draft"}
                  label="Promotion Applicability"
                  name="promotionApplicability"
                  value={values.promotionApplicability}
                  options={[
                    { value: "itemLevel", label: "Item Level Discount" },
                    { value: "cartLevel", label: "Cart Level Discount" },
                  ]}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    resetForm({
                      values: {
                        ...initialValues,
                        promotionApplicability: selectedValue,
                        slabs:
                          selectedValue === "cartLevel"
                            ? [
                                {
                                  slabfromCartValue: "",
                                  slabtoCartValue: "",
                                  discountType: "",
                                  value: "",
                                },
                              ]
                            : [],
                      },
                    });
                  }}
                  error={errors.promotionApplicability}
                  helperText={errors.promotionApplicability}
                  touched={touched.promotionApplicability}
                />
              </Grid>

              {values.promotionApplicability === "itemLevel" ? (
                <ItemLevelDiscountForm
                  disabled={promotionData?.status != "draft"}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              ) : (
                <CartLevelDiscountForm
                  disabled={promotionData?.status != "draft"}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              )}
              <Grid
                container
                xs={12}
                sx={{ mt: 2 }}
                flexDirection={"row-reverse"}
                gap={3}
              >
                {promotionData?.status == "draft" && (
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="black"
                  >
                    Save
                  </MDButton>
                )}
                <MDButton
                  color="black"
                  variant="outlined"
                  circular={true}
                  // style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    )
  );
};

export default Conditions;
