import { Button, Popover, Typography } from "@mui/material";
import React, { useState } from "react";

export const SlabInfo = ({ slabs }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <div style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
      <Typography sx={{ fontSize: 14 }}>
        {slabs?.[0]?.slabfromCartValue} -{" "}
        {slabs?.[slabs.length - 1]?.slabtoCartValue || '\u221e'}
        <a href="#" style={{ marginLeft: 8 }} onClick={handleOpen}>
          {isOpen ? "See less" : "See more"}
        </a>
      </Typography>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "16px",
            backgroundColor: "white",
            width: "100%",
            fontSize: 14,
          }}
        >
          {slabs?.map((slab) => (
            <p>
              {slab.slabfromCartValue}-{slab.slabtoCartValue || "inf"} : {slab.value}
              {slab.discountType == "percentageDiscount" ? "%" : "/-"}
            </p>
          ))}
        </div>
      </Popover>
    </div>
  );
};
