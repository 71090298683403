import { gql } from "@apollo/client";

export const GET_PROMOTION_LIST = gql`
  query GetPromotionList(
    $take: Int
    $skip: Int
    $search: String
    $filter: JSONObject
  ) {
    promotions(
      listPromotionFilter: {
        take: $take
        skip: $skip
        search: $search
        filter: $filter
      }
    ) {
      total
      results {
        id
        autoId
        promoName
        isDeleted
        status
        createdAt
        updatedAt
        createdBy
        generalInfoPromotion {
          id
          promoName
          start
          end
          startTime
          endTime
          description
          sponsoredBy
          promotionStore {
            id
            store {
              id
              name
            }
          }
          promotionBrand {
            id
            brand {
              id
              name
              vendorId
              usp
            }
          }
          isCustomeTime
        }
        assortmentPromotion {
          id
          getassortmentType
          buyAssortmentType
          buyAssortmentFile
          getAssortmentFile
          promotionId
        }
        conditions {
          id
          promotionId
          promotionApplicability
          promotionTrigger
          promotionType
          buySpecCondition
          buySpecConditionQuantity
          getSpecBenefitType
          discountType
          discountPercentageOrValue
          discountOnPrice
          slabs {
            slabfromCartValue
            slabtoCartValue
            discountType
            value
          }
        }
        comments {
          id
          user
          type
          commentText
          createdAt
          updatedAt
          createdBy
          updatedBy
        }
        promotionChannel {
          id
          isDeleted
          channel {
            id
            name
            channelType
            channelStore {
              store {
                name
                primaryText
                secondaryText
              }
            }
          }
        }
        promotionCategory {
          id
          isDeleted
          category {
            id
            title
            isNodeItem
          }
        }
        promotionProduct {
          id
          isDeleted
          isExcluded
          product {
            id
            title
            metaTitle
          }
        }
      }
    }
  }
`;
export const GET_LIST_OF_CATEGORIES = gql`
  query categoryProduct($listCategoryProductFilter: ListCategoryProductFilter!) {
    categoryProduct(listCategoryProductFilter: $listCategoryProductFilter) {
      total
      results {
        id
        title
        metaTitle
        isDeleted
        isMenuItem
        isNodeItem
      }
    }
  }
`;
export const GET_LIST_OF_CHANNEL = gql`
query GetChannelList($take: Int, $skip: Int, $search: String, $filter:JSONObject) {
    channels(listChannelFilter: {take: $take, skip: $skip, search: $search,,filter: $filter}) {
      total
      results {
        id
        name
        channelType
        displayOrder
        isDeleted
        createdAt
        updatedAt
        channelStore {
          store {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export const GET_LIST_OF_BRAND_PRODUCT = gql`
query listProduct($listProductFilter: ListProductFilter!) {
  products(listProductFilter: $listProductFilter) {
    total
    results {
      id
      title
    }
  }
}
`;
export const GET_LIST_OF_BRAND_EXCLUDED_PRODUCT = gql`
query listProduct($listProductFilter: ListProductFilter!) {
  products(listProductFilter: $listProductFilter) {
    total
    results {
      id
      title
    }
  }
}
`;
export const CREATE_PROMOTION_MUTATION =gql`
mutation createPromotion( $createPromotionInput : CreatePromotionInput!)  {
    createPromotion (createPromotionInput :$createPromotionInput) {
        id
       name
       badge
       description
       tnc
       channelType
       start
       end
       isDeleted
       promotionBrand {
         id
         isDeleted
         brand {
           id
           username
           name
         }
       }
       promotionChannel {
         id
         isDeleted
         channel {
           id
           name
           channelType
           channelStore {
             store {
               name
               primaryText
               secondaryText
             }
           }
         }
       }
       promotionCategory {
         id
         isDeleted
         category {
           id
           title
           isNodeItem
         }
       }
       promotionProduct {
         id
         isDeleted
         isExcluded
         product {
           id
           title
           metaTitle
         }
       }
     }
   }
   
`
export const UPDATE_PROMOTION_MUTATION =gql`
mutation updatePromotion( $updatePromotionInput : UpdatePromotionInput!)  {
    updatePromotion (updatePromotionInput :$updatePromotionInput) {
       id
       name
       badge
       description
       tnc
       channelType
       start
       end
       isDeleted
       promotionBrand {
         id
         isDeleted
         brand {
           id
           username
           name
         }
       }
       promotionChannel {
         id
         isDeleted
         channel {
           id
           name
           channelType
           channelStore {
             store {
               name
               primaryText
               secondaryText
             }
           }
         }
       }
       promotionCategory {
         id
         isDeleted
         category {
           id
           title
           isNodeItem
         }
       }
       promotionProduct {
         id
         isDeleted
         isExcluded
         product {
           id
           title
           metaTitle
         }
       }
     }
   }
   
`

export const UPDATE_PROMOTION_STATUS = gql`
mutation UpdatePromotionStatus($updatePromotionInput : UpdatePromotionInput!) {
    updatePromotionStatus(updatePromotionInput: $updatePromotionInput) {
        id
    }
}
`

export const CREATE_PROMOTION_GENERAL_INFORMATION = gql`
mutation createGeneralInformationPromotion($createPromotionGeneralInfoInput : CreatePromotionGeneralInfoInput!) {
    createGeneralInformationPromotion(CreatePromotionGeneralInfoInput: $createPromotionGeneralInfoInput) {
        id
        promotion {
          id
        }
    }
}
`

export const CREATE_PROMOTION_ASSORTMENT = gql`
mutation CreateAssortmentPromotion($assortmentInput : AssortmentInput!) {
    createAssortmentPromotion(AssortmentInput: $assortmentInput) {
        id
    }
}
`

export const CREATE_PROMOTION_CONDITIONS = gql`
mutation createPromotionConditions($conditionDTO : ConditionDTO!) {
    createPromotionConditions(ConditionDTO: $conditionDTO) {
        id
    }
}
`

export const CREATE_PROMOTION_COMMENT = gql`
mutation CreatePromotionComment($commentInput : CommentInput!) {
    createPromotionComment(CommentInput: $commentInput) {
        id
    }
}
`
export const UPDATE_PROMOTION_GENERAL_INFORMATION = gql`
mutation updateGeneralInformationPromotion($updatePromotionGeneralInfoInput : UpdatePromotionGeneralInfoInput!) {
    updateGeneralInformationPromotion(updatePromotionGeneralInfoInput: $updatePromotionGeneralInfoInput) {
        id
    }
}
`

export const UPDATE_PROMOTION_ASSORTMENT = gql`
mutation UpdateAssortmentPromotion($updateAssortmentInput : UpdateAssortmentInput!) {
    updateAssortmentPromotion(updateAssortmentInput: $updateAssortmentInput) {
        id
    }
}
`

// export const UPDATE_PROMOTION_CONDITIONS = gql`
// mutation updatePromotionConditions($conditionDTO : ConditionDTO!) {
//     updatePromotionConditions(ConditionDTO: $conditionDTO) {
//         id
//     }
// }
// `