import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { Card, IconButton, InputAdornment, TextField, Typography } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";


// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import AuthService from "services/auth-service";
import '../style.css';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { position } from "stylis";


const initialValues = {
    password: "",
    password_confirmation: "",
};

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .required("Confirm Password is required"),
});

const PasswordReset = () => {
    const navigate = useNavigate();

    const [token, setToken] = useState(null);
    const [notification, setNotification] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

    const handleClickShowConfirmationPassword = () => setShowConfirmationPassword((show) => !show);

    const handleMouseDownPassword = (event) => event.preventDefault();

    const [errors, setErrors] = useState({
        passwordError: false,
        confirmationError: false,
        error: false,
        textError: "",
    });

    useEffect(() => {
        // get the token and email sent in the url
        const queryParams = new URLSearchParams(window.location.search);
        setToken(queryParams.get("token"));
    }, []);

    return (
        <CoverLayout image={bgImage}>
            <Card sx={{ borderRadius: "50px", width: "500px" }}>
                <MDBox
                    borderRadius="lg"
                    mx={2}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDBox mt={10} pb={1}>
                        <img src={"/images/broadwayLogo.png"} />
                    </MDBox>
                    <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} sx={{fontFamily:"Montserrat"}}>
                        New Password
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={async (values, { setSubmitting }) => {
                        const data = {
                            resetPasswordToken: token,
                            newpassword: values.password
                        }

                        try {
                            await AuthService.resetPassword(data);
                            
                            if (errors.passwordError === false && errors.confirmationError === false) {
                                setNotification(true);
                                setTimeout(() => { setNotification(false);
                                    navigate('/auth/login');
                                 }, 1500)
                                
                            }
                        } catch (err) {
                            if (err.hasOwnProperty("errors")) {
                                setErrors({ ...errors, error: true, textError: err.errors.password[0] });
                            }
                            return null;
                        }
                    }}>
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            as={TextField}
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            label="Password"
                                            error={touched.password && !!errors.password}
                                            helperText={touched.password ? errors.password : ""}
                                            fullWidth
                                            InputProps={{
                                                fontFamily:"Montserrat",
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                    </IconButton>
                                                </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{style: {fontFamily: 'Montserrat'}}}
                                            onChange={(e) => {
                                                const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
                                                setFieldValue("password", valueWithoutSpaces);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            as={TextField}
                                            type={showConfirmationPassword ? "text" : "password"}
                                            name="password_confirmation"
                                            label="Password Confirmation"
                                            error={touched.password_confirmation && !!errors.password_confirmation}
                                            helperText={touched.password_confirmation ? errors.password_confirmation : ""}
                                            fullWidth
                                            InputProps={{
                                                fontFamily:"Montserrat",
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmationPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {showConfirmationPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                    </IconButton>
                                                </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{style: {fontFamily: 'Montserrat'}}}
                                            onChange={(e) => {
                                                const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
                                                setFieldValue("password_confirmation", valueWithoutSpaces);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={10} mx="auto">
                                        <MDButton variant="gradient" color="dark" fullWidth type="submit" sx={{ borderRadius: "50px",fontFamily:"Montserrat" }}>
                                            Change Password
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>
            {notification && (
                <MDAlert color="success" mt="20px" dismissible sx={{position:"absolute", top:5, right:20}}>
                    <MDTypography variant="body2" color="white"  sx={{fontFamily:"Montserrat"}}>
                        Success: Password reset successfully
                    </MDTypography>
                </MDAlert>
            )}
        </CoverLayout>
    );
};

export default PasswordReset;
