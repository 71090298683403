import { gql } from "@apollo/client";

export const GET_GMV_REPORT = gql`
  query GetGmvReport($filter: GetGMVReportFilter!) {
    getGmvReport(filter: $filter) {
      total
      data {
        id
        date
        brand
        brandName
        salesByMrp
        sellingPrice
        promotionDiscount
        couponDiscount
        rewardsBurn
        amountCollected
      }
    }
  }
`;

export const GET_SALES_REPORT = gql`
  query GetSalesReports(
    $take: Int
    $skip: Int
    $startDate: DateTime
    $endDate: DateTime
    $searchCols: [String!]
    $searchBy: [String!]
    $filter: GetSalesReportFilter
    $orderType: OrderType!
  ) {
    getSalesReports(
      take: $take
      skip: $skip
      startDate: $startDate
      endDate: $endDate
      searchCols: $searchCols
      searchBy: $searchBy
      filter: $filter
      orderType: $orderType
    ) {
      total
      totalPages
      currentPage
      results {
        ginesysStoreCode
        storeCode
        storeName
        billDate
        billNo
        billGuid
        division
        section
        department
        node
        brand
        vendor
        vendorCode
        barcode
        icode
        vendorArticleNumber
        itemName
        size
        mrp
        rsp
        qty
        basicAmt
        promoAmt
        couponAmt
        netAmt
        customerId,
        MOPName
        MOPType
      }
    }
  }
`;

export const GET_SALES_SUMMARY = gql`
  query GetSalesSummary(
    $startDate: DateTime
    $endDate: DateTime
    $filter: GetSalesReportFilter
    $orderType: OrderType!
  ) {
    getSalesSummary(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
      orderType: $orderType
    ) {
      totalOrders
      totalQtySold
      totalNetSalesValue
      avgOrderValue
      basketSize
      footfall
      creditNoteSale
      uniqueCustomerCount
    }
  }
`;

export const GET_RETURN_AND_REFUND_REPORT = gql`
  query GetReturnAndRefund($filter: GetReturnAndRefundFilter!) {
    getReturnAndRefund(filter: $filter) {
      total
      data {
        id
        productID
        productName
        amount
        refundStatus
        refundDate
        returnedReason
        salesOrderID
      }
    }
  }
`;

export const GET_STOCK_REPORT = gql`
  query GetStockReports(
    $take: Int
    $skip: Int
    $startDate: DateTime
    $endDate: DateTime
    $searchCols: [String!]
    $searchBy: [String!]
    $filter: GetStockReportFilter
  ) {
    getStockReports(
      take: $take
      skip: $skip
      startDate: $startDate
      endDate: $endDate
      searchCols: $searchCols
      searchBy: $searchBy
      filter: $filter
    ) {
      total
      totalPages
      currentPage
      results {
        itemName
        vendorName
        vendorCode
        itemCode
        closingStockQty
        lastModifiedOn
      }
    }
  }
`;

export const GET_EVENT_REPORT = gql`
  query GetEventReports($filter: GetEventsReportFilter!) {
    getEventReports(filter: $filter) {
      total
      data {
        id
        eventName
        eventDate
        eventTime
        ticketType
        category
        ticketsSold
        ticketAmount
        rewardsBurn
        numberOfParticipants
        samplesDistributed
      }
    }
  }
`;

export const GET_FOOTFALL_REPORT = gql`
  query Footfalls ($listFootfallFilter : ListFootfallFilterDto!){
    Footfalls(listFootfallFilter: $listFootfallFilter) {
        paginationTotal
        totalFootFall
        storeWiseFootfall {
            storeId
            storeName
            totalFootFallForStore
            dateWiseFootfall {
                totalFootfallForDay
                date
                timeWiseFootfalls {
                    timeWiseTotal
                    tillTime
                    entranceWiseFootfall {
                        id
                        name
                        count
                        imageUrl
                        date
                        fromTime
                        tillTime
                        createdBy
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
}

`;
export const GET_STORE_ENTRANCE_LIST = gql`
query Stores($listStoreFilter: ListStoreFilter!) {
  stores(listStoreFilter: $listStoreFilter) {
      results {
          id
          storeMetaData {
              id
              key
              value
              isDeleted
              createdAt
              updatedAt
          }
      }
  }
}
`;
