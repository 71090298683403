import React, { useContext, useEffect, useState } from "react";

//MUI Components
import { IconButton, Icon, Chip, FormControl, Select, MenuItem, Box } from "@mui/material";

//Icons and Images
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as copyIcon } from "assets/images/copyIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";

//Apollo Clients
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PROMOTION_LIST } from "services/promotion-service";
import { dashboardServiceClient } from "graphql/client";

//Custom Components
import Loader from "components/MDLoader";
import AgGrid from "components/MDGrid";

// Custom Context
import { AuthContext, useNotification } from "context";

// Custom Library
import dayjs from "dayjs";
import { promoOptions } from "./promotionForm";
import { AddComment, KeyboardArrowDown } from "@mui/icons-material";
import JSZip, { filter } from "jszip";
import * as XLSX from "xlsx";
import { SlabInfo } from "./slabInfo";
import { PromotionFormContext } from ".";
import CommentsDialog from "./commentsDialog";
import { AddCommentForm } from "./AddCommentForm";
import { UPDATE_PROMOTION_STATUS } from "services/promotion-service";

export const getPromoNameLabel = (promoName) => {
  const promoValue = promoName || ""; 
  const promo = promoOptions.find(option => option.value === promoValue);
  return promo ? promo.label : ""; // Return the corresponding label or an empty string if not found
};

export const getStatusChipProps = (status) => {
  switch (status) {
    case "draft":
      return { label: "Draft", color: "default" };
    case "pendingApproval":
      return { label: "Pending Approval", color: "warning" };
    case "approvedByOps":
      return { label: "Approved by Ops", color: "success" };
    case "approvedByCommercial":
      return { label: "Approved by Commercial", color: "success" };
    case "rejectedByOps":
      return { label: "Rejected by Ops", color: "error" };
    case "rejectedByCommercial":
      return { label: "Rejected by Commercial", color: "error" };
    case "live":
      return { label: "Live", color: "primary" };
    default:
      return { label: status, color: "default" };
  }
};

function PromotionLandingPage({
  onRowClick,
  selectedPromotionId,
  onDuplicate,
}) {
  const { user } = useContext(AuthContext);
  const isAdmin = user && user.role === "admin";
  const { setNotification } = useNotification();
  const [commentFormProps, setCommentFormProps] = useState({
    promoId:'',
    status:'',
    open:false,
    label:'',
    setOpen: ()=>{}
  });

  const [getPromotionList, { data, loading, error }] = useLazyQuery(
    GET_PROMOTION_LIST,
    {
      client: dashboardServiceClient,
      variables: {
        take: 10000,
        skip: 0,
        search: "",
        filter: {
          status:
            user?.role == "ops_manager" || user?.role == "business_manager"
              ? [
                  "inactive",
                  "pendingApproval",
                  "approvedByOps",
                  "rejectedByOps",
                  "approvedByCommercial",
                  "rejectedByCommercial",
                  "live",
                ]
              : user?.role == "commercial_manager"
              ? [
                  "inactive",
                  "approvedByOps",
                  "approvedByCommercial",
                  "rejectedByCommercial",
                  "live",
                ]
              : user?.role == "mdm_manager"
              ? ["inactive", "approvedByCommercial", "live"]
              : undefined,
          generalInfoPromotion:
            user?.role != "brand_admin"
              ? undefined
              : {
                  promotionBrand: {
                    brand: { id: user?.userId },
                  },
                },
        },
      },
      onError: (error) => {
        console.error("Error: fetching promotions", error);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Error: fetching promotions",
        });
      },
    }
  );

  useEffect(()=>{
    getPromotionList();
  },[])

  const [updatePromotionStatus] = useMutation(UPDATE_PROMOTION_STATUS, {
    client: dashboardServiceClient,
  });

  const {gridRef} = useContext(PromotionFormContext)

  const handleStatusChange = (id, status) => {
    const updateStatus = (comment = null) => {
      updatePromotionStatus({
        variables: {
          updatePromotionInput: {
            id,
            status,
            comment,
          },
        },
      })
        .then((res) => {
          if (res.data) {
            setNotification({
              color: "success",
              isVisible: true,
              message: "Promotion status updated successfully",
            });
            getPromotionList();
          } else {
            setNotification({
              color: "error",
              isVisible: true,
              message: res.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          setNotification({
            color: "error",
            isVisible: true,
            message: error.message || "Something went wrong",
          });
        });
    };
    if(status == "rejectedByOps" || status == "rejectedByMDM"){
      setCommentFormProps({
        promoId:id,
        status,
        open:true,
        label:'Reason for rejection',
        setOpen: (open)=>setCommentFormProps({...commentFormProps, open}),
        callback: updateStatus
      });
    } else if(status == "live"){
      setCommentFormProps({
        promoId:id,
        status,
        open:true,
        label:'ERP Id',
        setOpen: (open)=>setCommentFormProps({...commentFormProps, open}),
        callback: updateStatus
      });
    } else {
      updateStatus();
    }
  };

  const handleDownload = async (columns,promoData) => {
    const generateKeyValuePairs = (columns, data) => {
      const result = {};
    
      columns.forEach((column) => {
        if (!column.field) return;
    
        if (column.valueGetter) {
          // Execute valueGetter with params including data
          result[column.field] = column.valueGetter({ data });
        } else if (data[column.field] !== undefined) {
          // Fallback to directly extracting field value
          result[column.field] = data[column.field];
        } else {
          result[column.field] = "N/A"; // Default value for undefined fields
        }
      });
    
      return result;
    };
    const promotionTypeMap = {
      cartValueDisc: 'Cart Value Based Discount',
      qntDirectDisc: 'Quantity Based Direct Discount'
    }
    const jsonData = {
      id: promoData?.id,
      ...generateKeyValuePairs(columns, promoData),
      vendorName: promoData?.data?.generalInfoPromotion?.[0]?.promotionBrand?.brand?.name,
      discountOnPrice: promoData?.conditions?.[0]?.discountOnPrice,
      promotionType: promotionTypeMap[promoData?.conditions?.[0]?.promotionType],
      vendorId : promoData?.generalInfoPromotion?.[0]?.promotionBrand?.brand?.vendorId,
      buy: promoData?.assortmentPromotion?.[0]?.buyAssortmentType,
      get: promoData?.assortmentPromotion?.[0]?.getassortmentType,
      comments: JSON.stringify(promoData.comments.map(comment=>({
        // id:comment.id,
        user: comment.user,
        type: comment.type,
        commentText: comment.commentText,
        date: comment.createdAt
      })))
    }
    console.log(jsonData);
    let data = promoData?.conditions?.[0]?.slabs != null ? promoData?.conditions?.[0]?.slabs.map(slab=>({...jsonData, slabData: slab})) : [jsonData]
    // const worksheet = XLSX.utils.json_to_sheet([
    //   jsonData
    // ]);
    const worksheet = XLSX.utils.aoa_to_sheet([
      // Top-level headers
      [
        "Brand Details",
        null,
        null,
        "Promotion Information",
        null,
        null,
        null,
        null,
        null,
        "Assortment",
        null,
        "Promotion Definition",
        null,
        null,
        "Buy Specification",
        "Get Specification",
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      // Data headers
      [
        "Vendor Name",
        "Brand Name",
        "Vendor ID ERP",
        "Name",
        "Description",
        "Start Date",
        "Start time",
        "End Date",
        "End Time",
        "Buy",
        "Get",
        "Applicability",
        "Trigger conditon",
        "Type",
        "Condition",
        "Benefit Type",
        "Slab Number",
        "From Value",
        "To Value",
        "Discount Type",
        "Discount Value",
        "Discount On",
      ],
      // Data rows
      ...data.map((row, index) => [
        row.vendorName,
        row.promotionBrand,
        row.vendorId,
        row.promoName,
        row.description,
        row.start,
        row.startTime,
        row.end,
        row.endTime,
        row.buy,
        row.get,
        row.promoApplicability,
        row.promoTrigger,
        row.promotionType,
        row.buyCondition,
        row.benefitType,
        row.slabData ? index + 1 : "N/A",
        row.slabData ? row.slabData.slabfromCartValue : "N/A",
        row.slabData ? row.slabData.slabtoCartValue || "N/A" : "N/A",
        row.slabData ? row.slabData.discountType : row.discountType,
        row.slabData ? row.slabData.value : row.discountPercentageOrValue,
        row.slabData ? "RSP" : row.discountOnPrice,
      ]),
    ]);
    worksheet["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // "Brand Details" spans columns A-C
      { s: { r: 0, c: 3 }, e: { r: 0, c: 8 } }, // "Promotion Information" spans columns D-I
      { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } }, // "Assortment" spans columns J-K
      { s: { r: 0, c: 11 }, e: { r: 0, c: 13 } }, // "Promotion Definition" spans columns L-N
      { s: { r: 0, c: 14 }, e: { r: 0, c: 14 } }, // "Buy Specification" spans only column O
      { s: { r: 0, c: 15 }, e: { r: 0, c: 21 } }, // "Get Specification" spans columns P-V
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, 'promo_download.xlsx');
    const promoDataExcel = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    const zip = new JSZip();
    zip.file('promo_details.xlsx', promoDataExcel);
    const buyType = promoData?.assortmentPromotion?.[0]?.buyAssortmentType
    const getType = promoData?.assortmentPromotion?.[0]?.getassortmentType

    if(["include__Products", "exclude_Products"].includes(buyType)){
      try {
        // Fetch the Excel file from the URL
        const buyFileUrl = promoData?.assortmentPromotion?.[0]?.buyAssortmentFile?.publicUrl
        const response = await fetch(buyFileUrl);
        if (!response.ok) throw new Error(`Failed to fetch file: ${response.statusText}`);
        const fileBlob = await response.blob();
  
        // Add the fetched file to the ZIP
        zip.file(`buyAssortment-${buyType}.xlsx`, fileBlob);
      } catch (error) {
        console.error("Error downloading or processing the external file:", error);
      }
    }
    if(["include__Products", "exclude_Products"].includes(getType)){
      try {
        // Fetch the Excel file from the URL
        const getFileUrl = promoData?.assortmentPromotion?.[0]?.getAssortmentFile?.publicUrl
        const response = await fetch(getFileUrl);
        if (!response.ok) throw new Error(`Failed to fetch file: ${response.statusText}`);
        const fileBlob = await response.blob();
  
        // Add the fetched file to the ZIP
        zip.file(`getAssortment-${getType}.xlsx`, fileBlob);
      } catch (error) {
        console.error("Error downloading or processing the external file:", error);
      }
    }

    try {
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Trigger download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = "promo_download.zip";
      downloadLink.click();
    } catch (error) {
      console.error("Error downloading or processing the external file:", error);
    }
  }

  

  const columns = [
    // { field: "id", headerName: "ID", width: 160 },
    {
      field: "promoName",
      headerName: "Promotion Name",
      width: 250,
      valueGetter: (params) => getPromoNameLabel(params?.data?.promoName),
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      valueGetter: (params) => params?.data?.generalInfoPromotion?.[0]?.description
    },
    {
      field: "start",
      headerName: "From Date",
      width: 160,
      valueGetter: (params) => {
        return params?.data?.generalInfoPromotion?.[0]?.start
          ? dayjs(params?.data?.generalInfoPromotion[0]?.start).format(
              "DD/MM/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 160,
      valueGetter: (params) => {
        return params?.data?.generalInfoPromotion?.[0]?.startTime || "N/A";
      },
    },
    {
      field: "end",
      headerName: "To Date",
      width: 160,
      valueGetter: (params) =>
        params?.data?.generalInfoPromotion?.[0]?.end
          ? dayjs(params?.data?.generalInfoPromotion[0]?.end).format(
              "DD/MM/YYYY"
            )
          : "N/A",
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 160,
      valueGetter: (params) => {
        return params?.data?.generalInfoPromotion?.[0]?.endTime || "N/A";
      },
    },
    {
      field: "promotionBrand",
      headerName: "Brand",
      width: 200,
      hide: user?.role == "brand_admin",
      valueGetter: (params) =>
        params?.data?.generalInfoPromotion?.[0]?.promotionBrand?.brand?.usp,
    },

    {
      field: "promoApplicability",
      headerName: "Promotion Applicability",
      width: 160,
      valueGetter: (params) => {
        const applicabilityOptions = [
          { value: "itemLevel", label: "Item Level Discount" },
          { value: "cartLevel", label: "Cart Level Discount" },
        ];
        if (params?.data?.conditions?.[0]?.promotionApplicability) {
          return applicabilityOptions.find(
            (opt) =>
              opt.value == params?.data?.conditions?.[0]?.promotionApplicability
          )?.label;
        }
        return "";
      },
    },
    {
      field: "promoTrigger",
      headerName: "Trigger",
      width: 160,
      valueGetter: (params) => {
        const triggerOptions = [
          { value: "directPromotion", label: "Direct Promotion" },
          { value: "couponBased", label: "Coupon Based" },
        ];
        if (params?.data?.conditions?.[0]?.promotionTrigger) {
          return triggerOptions.find(
            (opt) =>
              opt.value == params?.data?.conditions?.[0]?.promotionTrigger
          )?.label;
        }
        return "";
      },
    },
    {
      field: "buyCondition",
      headerName: "Buy Condition",
      minWidth: 250,
      valueGetter: (params) => {
        const options = [
          { value: "minQntPurchase", label: "Minimum Quantity For Purchase" },
          { value: "anyQntPurchase", label: "Any Quantity For Purchase" },
          {
            value: "buySpecifiedAssortment",
            label: "Buy From Specified Assortment",
          },
        ];
        if (params?.data?.conditions?.[0]?.buySpecCondition) {
          return options.find(
            (opt) =>
              opt.value == params?.data?.conditions?.[0]?.buySpecCondition
          )?.label;
        }
        return "";
      },
    },
    {
      field: "benefitType",
      headerName: "Benefit Type",
      minWidth: 250,
      valueGetter: (params) => {
        const options = [
          { value: "flatDiscount", label: "Flat Discount" },
          { value: "singleSlab", label: "Single Slab" },
          { value: "multiSlabs", label: "Multi Slabs" },
        ];
        if (params?.data?.conditions?.[0]?.getSpecBenefitType) {
          return options.find(
            (opt) =>
              opt.value == params?.data?.conditions?.[0]?.getSpecBenefitType
          )?.label;
        }
        return "";
      },
    },
    {
      field: "discountType",
      headerName: "Discount Type",
      minWidth: 250,
      valueGetter: (params) => {
        if (params?.data?.conditions?.[0]?.getSpecBenefitType == "multiSlabs")
          return "N/A";
        const options = [
          { value: "percentageDiscount", label: "% Discount" },
          { value: "priceDiscount", label: "Price Discount" },
          { value: "fixedPrice", label: "Fixed Price" },
        ];
        let discountTypeValue;
        if (params?.data?.conditions?.[0]?.getSpecBenefitType == "singleSlab") {
          discountTypeValue =
            params?.data?.conditions?.[0]?.slabs?.[0]?.discountType;
        } else if (params?.data?.conditions?.[0]?.discountType) {
          discountTypeValue = params?.data?.conditions?.[0]?.discountType;
        }

        if (discountTypeValue) {
          return options.find((opt) => opt.value == discountTypeValue)?.label;
        }
        return "";
      },
    },
    {
      field: "discountPercentageOrValue",
      headerName: "Value",
      width: 250,
      valueGetter: (params) =>
        params?.data?.conditions?.[0]?.getSpecBenefitType == "multiSlabs"
          ? "N/A"
          : params?.data?.conditions?.[0]?.getSpecBenefitType == "singleSlab"
          ? params?.data?.conditions?.[0]?.slabs?.[0]?.value
          : params?.data?.conditions?.[0]?.discountPercentageOrValue,
    },
    {
      field: "slabInfo",
      headerName: "Slab Information",
      width: 300,
      filter: false,
      valueGetter: (params) => {
        return params?.data?.conditions?.[0]?.getSpecBenefitType == "multiSlabs"
          ? params?.data?.conditions?.[0]?.slabs
              ?.map(
                (slab) =>
                  `${slab.slabfromCartValue}-${slab.slabtoCartValue} : ${
                    slab.value
                  } ${slab.discountType == "percentageDiscount" ? "%" : "/-"}`
              )
              .join(", ")
          : params?.data?.conditions?.[0]?.getSpecBenefitType == "singleSlab"
          ? `>${params?.data?.conditions?.[0]?.slabs[0]?.slabfromCartValue}`
          : "N/A";
      },
      cellRenderer: (params) => {
        return params?.data?.conditions?.[0]?.getSpecBenefitType ==
          "multiSlabs" ? (
          <SlabInfo slabs={params?.data?.conditions?.[0]?.slabs} />
        ) : params?.data?.conditions?.[0]?.getSpecBenefitType ==
          "singleSlab" ? (
          `>${params?.data?.conditions?.[0]?.slabs[0]?.slabfromCartValue}`
        ) : (
          "N/A"
        );
      },
    },
    {field: "createdBy", headerName: "Created By"},
    {
      field: "status",
      headerName: "Status",
      width: 160,
      cellRenderer: (params) => {
        let canEdit = false;
        switch (user?.role) {
          case "ops_manager":
          case "business_manager":
            canEdit = params?.data?.status == "pendingApproval";
            break;
          case "commercial_manager":
            canEdit = params?.data?.status == "approvedByOps";
            break;
          case "mdm_manager":
            canEdit = params?.data?.status == "approvedByCommercial";
            break;
        }

        if (!canEdit) {
          const { label, color } = getStatusChipProps(params?.data?.status);
          return <Chip label={label} color={color} size="small" />;
        }

        let menuOptions = [];
        switch (params?.data?.status) {
          case "pendingApproval":
            menuOptions = [
              { label: "Approved by Ops", value: "approvedByOps" },
              // { label: "Pending Approval", value: "pendingApproval" },
              { label: "Rejected by Ops", value: "rejectedByOps" },
            ];
            break;
          case "approvedByOps":
            menuOptions = [
              {
                label: "Approved by Commercial",
                value: "approvedByCommercial",
              },
              // { label: "Approved by Ops", value: "approvedByOps" },
              {
                label: "Rejected by Commercial",
                value: "rejectedByCommercial",
              },
            ];
            break;
          case "approvedByCommercial":
            menuOptions = [
              { label: "Live", value: "live" },
              // {
              //   label: "Approved by Commercial",
              //   value: "approvedByCommercial",
              // },
            ];
            break;
          // case "rejectedByMDM":
          //   menuOptions = [
          //     { label: "Approved by Ops", value: "approvedByOps" },
          //     { label: 'Rejected by MDM', value: 'rejectedByMDM'},
          //     { label: 'Rejected by Ops', value: 'rejectedByOps'},
          //   ];
          //   break;
        }
        return (
          <FormControl
            fullWidth
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Select
              onChange={(e) =>
                handleStatusChange(params.data.id, e.target.value)
              }
              value={params?.data?.status}
              renderValue={(selected) => {
                const selectedOption = menuOptions.find((option) => option.value === selected);
                return selectedOption ? selectedOption.label : `${getStatusChipProps(params?.data?.status).label}`;
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 6,
                height: 32,
              }}
              id="status-select"
              IconComponent={() => (
                <KeyboardArrowDown
                  sx={{ fontSize: 24, width: 24, height: 24 }}
                />
              )}
            >
              {menuOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
              {/* <MenuItem value="live">Live</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="pendingApproval">Pending Approval</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem> */}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 240,
      sortable: false,
      filter: false,
      cellRenderer: (params) => (
        <Box sx={{ display: "flex" }}>
          {/* <IconButton onClick={(event) => onDuplicate(params.data, event)} >
            <Icon component={copyIcon} fontSize="small" />
          </IconButton>*/}
          {/* <IconButton aria-label="save" onClick={() => alert("Clicked")}>
            <Icon component={commentIcon} fontSize="small" />
          </IconButton> */}
          <CommentsDialog promotionId={params.data?.id} />
          {[
            "approvedByOps",
            "approvedByCommercial",
            "live",
            "pendingApproval",
          ].includes(params.data?.status) && (
            <IconButton
              aria-label="download"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDownload(columns, params?.data);
              }}
            >
              <Icon component={downloadIcon} fontSize="small" />
            </IconButton>
          )}
          {/* <IconButton aria-label="delete" >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  if (error) return <p>Error: {error?.message}</p>;

  return (<>
    <AddCommentForm {...commentFormProps}/>
    <AgGrid
      columns={columns}
      queryData={data?.promotions?.results || []}
      externalGridRef={gridRef}
      // readMethod={{
      //   fetchFn: getPromotionList,
      //   inputParser: ({ startRow, endRow, filter, sortModel }) => {
      //     return {
      //       take: endRow - startRow,
      //       skip: startRow,
      //       search: "",
      //       orderby: sortModel.reduce((acc, { colId, sort }) => {
      //         acc[colId] = sort;
      //         return acc;
      //       }, {}),
      //     };
      //   },
      //   resultsParser: ({ data }) => {
      //     return {
      //       total: data?.promotions?.total || 0,
      //       results: data?.promotions?.results || [],
      //     };
      //   },
      // }}
      gridProps={{
        loading: loading,
        rowHeight: 60,
        alwaysShowHorizontalScroll:true,
        defaultColDef: {
          sortable: true,
          editable: false,
          flex: 1,
          minWidth: 200,
          floatingFilter: true,
          filter: true,
          multiSort: true,
        },
        onRowClicked: (params) => {
          if(params.event.defaultPrevented) {
            return null
          }
          onRowClick(params?.data)
        }
      }}
    />
  </>
  );
}

export default PromotionLandingPage;
