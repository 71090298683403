import { gql } from "@apollo/client";


export const SAMPLE_FEEDBACK_LIST = gql`
query GetReviewListApp($listReviewFilter: ListFeedbackFilter!) {
    getReviewListApp(listReviewFilter: $listReviewFilter) {
      total
      results {
        id
        customerMobile
        customerName
        feedbackDesc
        rating
        barcode
        remark
        brandName
        store
        createdAt
        updatedAt
        createdBy {
          id
          name
          username
        }
        updatedBy {
          id
          name
          username
        }
        brand {
          id
          name
        }
        variant {
          id
          barcode
          itemName
          shortName
        }
      }
    }
  }
`
export const SAMPLE_FEEDBACK_TOTAL = gql`
query GetReviewListApp($listReviewFilter: ListFeedbackFilter!) {
    getReviewListApp(listReviewFilter: $listReviewFilter) {
      total
    }
  }
`