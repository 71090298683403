import { Button, Icon, IconButton } from "@mui/material";
import AgGrid from "components/MDGrid";
import { productServiceClient } from "graphql/client";
import { useEffect, useRef, useState } from "react";
import { GET_CYCLE_COUNT_DATA } from "services/product-service";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import axios from "axios";

const colDef = [
  {
    field: "name",
    headerName: "Cycle Count Name",
    filter: "agTextColumnFilter",
  },
  {
    field: "createdBy",
    filter: "agTextColumnFilter",
    headerName: "Created By",
  },
  {
    field: "createdAt",
    headerName: "Date",
    filter: "agDateColumnFilter",
    valueGetter: (params) => {
      return new Date(params.data?.createdAt).toLocaleString("en-IN");
    },
    getFilterObj: (value) => {
      return {
        createdAt: new Date(value.dateFrom).getTime(),
      };
    },
  },
  {
    field: "id",
    headerName: "Action",
    filter: false,
    cellRenderer: (params) => {
      const id = params.data?.id;
      const fileDownloadUrl =
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/product-service/cycle-count`
          : `/product-service/cycle-count`;

      return (
        <IconButton
          aria-label="download"
          onClick={async () => {
            try {
              const response = await axios({
                url: fileDownloadUrl,
                method: "POST",
                responseType: "blob", // important
                data: {
                  id,
                },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `${params.data.name}.xlsx`
              ); // or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            } catch (e) {
              console.log("error", e);
            }
          }}
        >
          <Icon component={downloadIcon} fontSize="small" />
        </IconButton>
      );
    },
  },
];

export default function CycleCountTable({ tabValue, startDate, endDate }) {
  const startDateMS = startDate.valueOf();
  const endDateMS = endDate.valueOf();

  const endDateMSRef = useRef(endDateMS);
  const startDateMSRef = useRef(startDateMS);

  endDateMSRef.current = endDateMS;
  startDateMSRef.current = startDateMS;

  const gridRef = useRef(null);

  const fetchCycleCount = async ({
    variables: {
      filter: { skip, take, filter },
    },
  }) => {
    const data = await productServiceClient.query({
      query: GET_CYCLE_COUNT_DATA,
      variables: {
        skip,
        take,
        filter: {
          ...filter,
          isSynced: true,
        },
        startDate: filter.createdAt
          ? new Date(filter.createdAt)
          : new Date(startDateMSRef.current),
        endDate: filter.createdAt
          ? new Date(filter.createdAt)
          : new Date(endDateMSRef.current),
      },
      fetchPolicy: "network-only",
    });

    return data;
  };

  useEffect(() => {
    if (!gridRef.current?.api) return;

    gridRef.current.api.onFilterChanged();
  }, [startDateMS, endDateMS]);

  return (
    <AgGrid
      customGridRef={gridRef}
      gridProps={{
        rowHeight: 60,
        pivotMode: false,
        sideBar: {
          toolPanels: ["columns", "filters"],
        },
        enableRangeSelection: true,
      }}
      readMethod={{
        fetchFn: fetchCycleCount,
        inputParser: ({ startRow, endRow, filter }) => ({
          filter: {
            skip: startRow,
            take: endRow - startRow,
            filter,
          },
        }),
        resultsParser: (data) => {
          return {
            results: data?.data?.cycleCountList?.results,
            total: data?.data?.cycleCountList?.total,
          };
        },
      }}
      columns={colDef}
      defaultColDefProp={{
        floatingFilter: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        editable: true,
        filter: true,
        flex: 1,
        minWidth: 200,
      }}
    />
  );
}
