import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { AvailableTabs } from "./tableData";
import { useQuery } from "@apollo/client";
import { MAP_BRAND_STORE } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import useProductContext from "../context/useProductContext";
import { useEffect, useState } from "react";
import { CachedOutlined } from "@mui/icons-material";
import axios from "axios";
import { useNotification } from "context";

const getTabStyles = (tabName, activeTab) => {
  const commonStyle = { padding: "0.5rem 1rem", padding: "0.5rem 1rem" };

  if (tabName === activeTab) {
    return { ...commonStyle, color: "#E91F63 !important" };
  }

  return { ...commonStyle, color: "black !important" };
};

export default function TableTabs({ selectedTab, onChange }) {
  const user = localStorage.getItem("userDetails");

  const userJson = user ? JSON.parse(user) : {};

  const role = userJson.role;

  const isAdmin = role === "admin";

  const { productContext, setProductContext } = useProductContext();
  const { setNotification } = useNotification();

  const {data:brandStoreData, loading, error} = useQuery(MAP_BRAND_STORE,{
    client: dashboardServiceClient,
    variables: {
      listBrandStoreFilter: {
        take: 10000,
        skip: 0,
        search: "",
        filter: {
          brand: { id: isAdmin ? undefined : userJson?.userId},
          isDeleted: false,
          status: ["active", "upcoming"]
        },
      },
    }
  })

  const handleResync = async (selectedRows) => {
    const resyncUrl =
          process.env.REACT_APP_NODE_ENV === "development"
            ? `${process.env.REACT_APP_API_BASE_URL}/saas-service`
            : `/saas-service`;
    const variantIds = selectedRows.map(row=>row.id)
    const response = await axios.post(
      `${resyncUrl}/ginesys/repush-rejectedArticle`,
      { variantIds },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-customer": user,
        },
      }
    );
    console.log(response);
    if(response.status == 200){
      setNotification({
        color: "success",
        isVisible: true,
        message: "Respush successfull"
      });
      setProductContext({
        ...productContext,
        selectedRows: null,
      });
      onChange(null, AvailableTabs.singleProduct);

    } else {
      setNotification({
        color: "error",
        isVisible: true,
        message: response?.data?.message || "Resync failed"
      });
    }
  }
  
  useEffect(()=>{
    if(brandStoreData?.brandStores?.results){
      setProductContext({...productContext, brandStores: brandStoreData?.brandStores?.results})
    }
  },[brandStoreData])
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Tabs
        sx={{
          width: "fit-content",
        }}
        value={selectedTab}
        onChange={onChange}
      >
        <Tab
          label="Single Product"
          value={AvailableTabs.singleProduct}
          sx={{
            marginRight: "1rem",
            fontSize: "13px",
            border: "1px solid #f0e6e6c9",
            marginRight: "5px",
            // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
          }}
        />
        {/* <Tab
        label="Node wise bulk Product List"
        value={AvailableTabs.nodeWiseBulkProduct}
        sx={{
          marginRight: "1rem",
          fontSize: "13px",
          border: "1px solid #f0e6e6c9" ,marginRight:"5px" 
          // ...getTabStyles(AvailableTabs.singleProduct, selectedTab),
        }}
        // sx={{ ...getTabStyles(AvailableTabs.nodeWiseBulkProduct, selectedTab) }}
      /> */}

        <Tab
          sx={{
            fontSize: "13px",
            border: "1px solid #f0e6e6c9",
            marginRight: "5px",
          }}
          label="Bulk Product List"
          value={AvailableTabs.bulkProduct}
        />
        {(isAdmin || productContext.brandStores?.length > 0) && (
          <Tab
            sx={{
              fontSize: "13px",
              border: "1px solid #f0e6e6c9",
              marginRight: "5px",
            }}
            label="Bulk Price Upload"
            value={AvailableTabs.bulkPriceUpload}
          />
        )}
        {(isAdmin || productContext.brandStores?.length > 0) && (
          <Tab
            sx={{
              fontSize: "13px",
              border: "1px solid #f0e6e6c9",
              marginRight: "5px",
            }}
            label="Products Price List"
            value={AvailableTabs.productsPriceList}
          />
        )}
        {/* <IconButton onClick={refresh}>
        <Refresh fontSize="medium" />
      </IconButton> */}
      </Tabs>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {isAdmin && selectedTab == AvailableTabs.singleProduct &&
          productContext.gridRef.current != null && (
            <>
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  color: "#5E5E5E",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  mr: 2,
                  mb: 0,
                }}
              >
                {productContext.selectedRows?.length || 0} Selected
              </Typography>
              <Button
                startIcon={<CachedOutlined />}
                onClick={()=>handleResync(productContext.selectedRows)}
                disabled={!productContext.selectedRows?.length}
                size="small"
                style={{
                  borderRadius: "10rem",
                  color: "black",
                  marginLeft: "8px",
                  borderColor: "black",
                  opacity: productContext.selectedRows?.length > 0 ? 1 : 0.25
                }}
                variant="outlined"
              >
                Re-Sync All
              </Button>
            </>
          )}
      </Box>
    </Box>
  );
}
