import React, { useContext, useEffect, useState } from "react";

// Yup and Formik Imports
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// MUI Components
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Grid, TextField, FormControlLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Icons
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";

// GraphQL Queries and Mutations
import {
  BRANDS_LIST,
  LIST_BRAND_STORE_FOR_NODE_AGREEMENT,
} from "services/brand-service";
import { CREATE_PROMOTION_MUTATION } from "services/promotion-service";

// Apollo Client
import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

// Custom Components
import { useNotification, AuthContext } from "context";
import MDButton from "components/MDButton";
import useDebounce from "layouts/product-category/useDebounce";

// import styles
import "./promotion.css";
import SelectComponent from "./selectComponent";
import { PromotionFormContext } from ".";
import { CREATE_PROMOTION_GENERAL_INFORMATION } from "services/promotion-service";
import { GET_PROMOTION_LIST } from "services/promotion-service";
import { UPDATE_PROMOTION_GENERAL_INFORMATION } from "services/promotion-service";

const promoNames = {
  buyOneGetOne: "Buy One Get One",
  flatPercentageOff: "Flat % off",
  flatRsOff: "Flat Rs. Off",
  getProductOnFP: "Get Product on Fixed Price",
  buyXGetYAtPercentage: "Buy x, get y@X Rs. Off",
  buyXGetYAtRs: "Buy x, get y@X % Off",
  buyXGetYAtRsFP: "Buy x, get y@Rs. (Fixed Price)",
  buyXGetYAtRsProductExclusionNotAvl: "Buy x at Rs y (Any 3 at 999, 5 at 1499)",
  buyXGetYAtPercentageBuyYQnt_GetYPercentage:
    "Buy 1 and get X% Off, Buy Y Qty and get Y% Off",
  counponsPercentageOff:
    "Coupons - Brand Coupons - flat % off (limited time offer)",
  couponsRsOff: "Coupons - Brand Coupons - flat Rs off",
  shopXGetYSample: "Shop x, get y sample (same brand)",
  shopForRs249: "Shop for Rs 2,499 get sample box worth Rs 499",
};

const usePromotionMutations = (
  promotionId,
  setPromotionId,
  handleChange,
  setNotification,
  gridRef
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
  };

  // Only using the create mutation now as per business requirement
  const [createPromotionGeneralInfoMutation] = useMutation(
    CREATE_PROMOTION_GENERAL_INFORMATION,
    mutationOptions
  );

  const [updatePromotionGeneralInfoMutation] = useMutation(
    UPDATE_PROMOTION_GENERAL_INFORMATION,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const payload = {
      id: values?.id || undefined,
      promoName: values?.promoName || "",
      description: values?.promoDesc || "",
      start: values?.start,
      end: values?.end,
      startTime: values?.startTime || "",
      endTime: values?.endTime || "",
      brand: values?.brand?.value || "",
      promotionStore: values?.brandStoreId?.map((item) => item.store.id) || [],
      isCustomTime: values?.customTime || false,
      sponsoredBy: values?.sponsoredBy,
    };

    if (payload.id != null) {
      updatePromotionGeneralInfoMutation({
        variables: { updatePromotionGeneralInfoInput: payload },
      })
        .then((res) => {
          if (res.data) {
            handleChange(null, 1);
            setNotification({
              color: "success",
              isVisible: true,
              message: "General info updated successfully",
            });
          } else {
            setNotification({
              color: "error",
              isVisible: true,
              message: res.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          setNotification({
            color: "error",
            isVisible: true,
            message: error.message || "Something went wrong",
          });
        });
    } else {
      createPromotionGeneralInfoMutation({
        variables: { createPromotionGeneralInfoInput: payload },
      })
        .then((res) => {
          if (res.data) {
            setPromotionId(
              res.data.createGeneralInformationPromotion?.promotion?.id
            );
            handleChange(null, 1);
            setNotification({
              color: "success",
              isVisible: true,
              message: "Promotion created successfully",
            });
          } else {
            setNotification({
              color: "error",
              isVisible: true,
              message: res.errors?.[0]?.message || "Something went wrong",
            });
          }
        })
        .catch((error) => {
          setNotification({
            color: "error",
            isVisible: true,
            message: error.message || "Something went wrong",
          });
        });
    }
  };

  return handleSubmit;
};

const validationSchema = Yup.object({
  // id: Yup.string(),
  brand: Yup.object().required("Select a brand"),
  brandStoreId: Yup.array().min(1, "Select at least one store"),
  promoName: Yup.string()
    .required("Promo Name is required")
    .max(50, "Maximum 50 characters allowed"),
  start: Yup.date()
    .required("Start date is required"),
    // .test(
    //   "start",
    //   "Start date must be at least one day after today",
    //   function (value) {
    //     return dayjs(value).isAfter(dayjs(), "day");
    //   }
    // ),
  end: Yup.date()
    .required("End date is required")
    .min(
      Yup.ref("end"),
      "End date must be at least one day after the start date"
    ),
  startTime: Yup.string().when("customTime", {
    is: () => true,
    then: () => Yup.string().required("Start time is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  endTime: Yup.string().when("customTime", {
    is: () => true,
    then: () =>
      Yup.string()
        .required("End time is required")
        .test(
          "is-after-start-time",
          "End time must be after Start time",
          function (endTime) {
            const { startTime } = this.parent;
            if (!startTime || !endTime) return true; // Skip validation if either is not provided
            const start = dayjs(startTime, "HH:mm");
            const end = dayjs(endTime, "HH:mm");
            return end.isAfter(start); // Validate if endTime is after startTime
          }
        ),
    otherwise: () => Yup.string().notRequired(),
  }),
  customTime: Yup.boolean(),
  promoDesc: Yup.string()
    .required("Promo Description is required")
    .max(500, "Maximum 500 characters allowed"),
  isDeleted: Yup.boolean(),
});

export const promoOptions = [
  { label: "Buy One Get One", value: "buyOneGetOne" },
  { label: "Flat % off", value: "flatPercentageOff" },
  { label: "Flat Rs. Off", value: "flatRsOff" },
  { label: "Get Product on Fixed Price", value: "getProductOnFP" },
  { label: "Buy x, get y@X Rs. Off", value: "buyXGetYAtPercentage" },
  { label: "Buy x, get y@X % Off", value: "buyXGetYAtRs" },
  { label: "Buy x, get y@Rs. (Fixed Price)", value: "buyXGetYAtRsFP" },
  {
    label: "Buy x at Rs y (Any 3 at 999, 5 at 1499)",
    value: "buyXGetYAtRsProductExclusionNotAvl",
  },
  {
    label: "Buy 1 and get X% Off, Buy Y Qty and get Y% Off",
    value: "buyXGetYAtPercentageBuyYQnt_GetYPercentage",
  },
  {
    label: "Coupons - Brand Coupons - flat % off (limited time offer)",
    value: "counponsPercentageOff",
  },
  { label: "Coupons - Brand Coupons - flat Rs off", value: "couponsRsOff" },
  { label: "Shop x, get y sample (same brand)", value: "shopXGetYSample" },
  {
    label: "Shop for Rs 2,499 get sample box worth Rs 499",
    value: "shopForRs249",
  },
];

const PromotionForm = ({ handleClose, handleChange }) => {
  const { user } = useContext(AuthContext);
  const { setNotification } = useNotification();
  const { promotionId, setPromotionId, gridRef } =
    useContext(PromotionFormContext);

  const isAdmin = user && user.role === "admin";
  const defaultBrandId = isAdmin ? "" : user?.userId;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBrand, setSelectedBrand] = useState(defaultBrandId);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [
    getPromotionList,
    { loading: promotionLoading, error: promotionError },
  ] = useLazyQuery(GET_PROMOTION_LIST, {
    client: dashboardServiceClient,
    onError: (error) => {
      console.error("Error: fetching promotions", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching promotions",
      });
    },
  });
  const [promotionData, setPromotionData] = useState(null);

  // const [getPromotionDetails, { loading, data, error}] = useLazyQuery()

  useEffect(() => {
    // TODO: need to call promotion details form to fetch tab form details
    if (promotionId != null) {
      getPromotionList({
        variables: {
          skip: 0,
          take: 1,
          filter: {
            id: promotionId,
          },
        },
      }).then((res) => {
        if (res.data.promotions.results.length > 0) {
          setPromotionData(res.data.promotions.results[0]);
        }
      });
    }
  }, [promotionId]);

  useEffect(()=>{
    if(promotionData){
      setSelectedBrand(promotionData.generalInfoPromotion?.[0].promotionBrand.brand.id);
    }
  },[promotionData])

  const [isCustomTime, setIsCustomTime] = useState(false); // State for checkbox

  const handleSubmit = usePromotionMutations(
    promotionId,
    setPromotionId,
    handleChange,
    setNotification,
    gridRef
  );

  // Below Are the fixed Options for Promo Name

  const [
    fetchStoreList,
    { loading: storeLoading, error: storeError, data: storeList },
  ] = useLazyQuery(LIST_BRAND_STORE_FOR_NODE_AGREEMENT, {
    client: dashboardServiceClient,
    onError: (error) => {
      console.error("Error: fetching stores", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching stores",
      });
    },
  });

  useEffect(() => {
    if (selectedBrand) {
      fetchStoreList({
        variables: {
          listBrandStoreFilter: {
            take: 10,
            skip: 0,
            filter: {
              status: ["active", "upcoming"],
              brand: { id: selectedBrand },
            },
          },
        },
      });
    }
  }, [selectedBrand, fetchStoreList]);

  const brandStoreList = storeList?.brandStores?.results?.map((item) => item);

  // TODO: need to move it to autocomplete using debounce and useLazyQuery instead
  const { loading, data, error } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    onError: (error) => {
      console.error("Error: fetching brands", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching brands",
      });
    },
    variables: {
      take: 10,
      skip: 0,
      search: debouncedSearchTerm,
      filter:
        user && user?.role === "admin"
          ? {
              isDeleted: false,
              brandStatus:"live"
            }
          : {
              id: user?.userId,
              isDeleted: false
            },
    },
  });

  return (
    <Formik
      key={promotionData ? promotionData.id : "new"} // Key the component to the promotion ID or 'new' for new promotions
      initialValues={{
        id: promotionData?.generalInfoPromotion?.[0]?.id,
        promotionId: promotionData?.id,
        promoName: promotionData?.promoName || "",
        promoDesc: promotionData?.generalInfoPromotion?.[0]?.description || "",
        start: promotionData?.generalInfoPromotion?.[0]?.start
          ? dayjs(promotionData?.generalInfoPromotion?.[0]?.start)
          : dayjs().add(0, "day"),
        customTime:
          promotionData?.generalInfoPromotion?.[0]?.isCustomeTime || false,
        end: promotionData?.generalInfoPromotion?.[0]?.end
          ? dayjs(promotionData?.generalInfoPromotion?.[0]?.end)
          : dayjs().add(0, "day"),
        startTime:
          promotionData && promotionData?.generalInfoPromotion?.[0]?.startTime
            ? promotionData?.generalInfoPromotion?.[0]?.startTime
            : "00:01",
        endTime:
          promotionData && promotionData?.generalInfoPromotion?.[0]?.endTime
            ? promotionData?.generalInfoPromotion?.[0]?.endTime
            : "23:59",
        brand: isAdmin
          ? promotionData?.generalInfoPromotion?.[0]?.promotionBrand?.brand
            ? {
                label:
                  promotionData.generalInfoPromotion?.[0].promotionBrand.brand
                    .name,
                value:
                  promotionData.generalInfoPromotion?.[0].promotionBrand.brand
                    .id,
              }
            : "" || ""
          : {value:defaultBrandId},
        brandStoreId:
          promotionData?.generalInfoPromotion?.[0]?.promotionStore || [],
        sponsoredBy: promotionData?.generalInfoPromotion?.[0]?.sponsoredBy || "brand",
        isCustomTime:
          promotionData?.generalInfoPromotion?.[0]?.isCustomeTime || false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (promotionData && promotionData.status !== "draft") {
          handleChange(null, 1);
        } else {
          handleSubmit(values);
        }
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        validateField,
        handleBlur,
      }) => {
        return (
          <Form>
            <Grid container spacing={2} p={2}>
              {console.log(values, errors, "values ")}
              {isAdmin && (
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={promotionData && promotionData.status != "draft"}
                    labelId="demo-simple-select-label"
                    label="Select Brand"
                    id="demo-simple-select"
                    name="brand"
                    loading={loading}
                    iconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    value={values?.brand || null}
                    options={
                      data?.brandlist?.results?.length
                        ? data?.brandlist?.results.map((item) => ({
                            label: item?.name,
                            value: item?.id,
                          }))
                        : []
                    }
                    onChange={(e, newValue) => {
                      setFieldValue("brand", newValue ? newValue : null); // Ensure it's a string
                      setSelectedBrand(newValue?.value ? newValue.value : "");
                      setFieldValue("brandStoreId", [])
                    }}
                    onInputChange={(event, newValue) => {
                      if (
                        (event && event?.type === "reset") ||
                        newValue === ""
                      ) {
                        setSearchTerm(""); // Clear search when input is cleared
                      } else if (event && event.type === "change") {
                        setSearchTerm(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Brand"
                        error={
                          touched.brand && Boolean(errors.brand)
                        }
                        helperText={touched.brand && errors.brand}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Autocomplete
                  disabled={
                    !values?.brand ||
                    (promotionData && promotionData.status != "draft")
                  }
                  multiple
                  id="brandStoreId"
                  options={brandStoreList || []}
                  getOptionLabel={(option) =>
                    `${option.store.name}`
                  }
                  value={values.brandStoreId || []}
                  onChange={(event, newValue) =>
                    setFieldValue("brandStoreId", newValue)
                  }
                  onBlur={handleBlur}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store Name"
                      error={
                        touched.brandStoreId && Boolean(errors.brandStoreId)
                      }
                      helperText={touched.brandStoreId && errors.brandStoreId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectComponent
                  disabled={promotionData && promotionData.status != "draft"}
                  label="Promotion Name"
                  name="promoName"
                  value={values.promoName}
                  options={promoOptions} // here is iw ant to give options fas per given scereenshot
                  setFieldValue={setFieldValue}
                  error={errors.promoName}
                  helperText={errors.promoName}
                  touched={touched.promoName}
                />
              </Grid>
              {isAdmin && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontSize: 14,
                      }}
                    >
                      Sponsored By
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
                    <FormControl
                      component="fieldset"
                      disabled={
                        promotionData && promotionData.status != "draft"
                      }
                    >
                      <RadioGroup
                        row
                        name="option"
                        defaultValue={values.sponsoredBy}
                        onChange={(event) =>
                          setFieldValue("sponsoredBy", event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="brand"
                          control={<Radio />}
                          label="Brand"
                        />
                        <FormControlLabel
                          value="broadway"
                          control={<Radio />}
                          label="Broadway"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      disabled={
                        promotionData && promotionData.status != "draft"
                      }
                      sx={{ width: "100%" }}
                      slotProps={
                        errors.start && !!touched.start
                          ? {
                              textField: {
                                error: !!touched.start && errors.start,
                                helperText: errors.start,
                              },
                            }
                          : null
                      }
                      label="Start Date"
                      format="DD/MM/YYYY"
                      name="start"
                      value={
                        values.start
                          ? dayjs(values.start)
                          : dayjs().add(1, "day")
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "start",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      minDate={dayjs().add(0, "day")}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          fullWidth
                          {...params}
                          error={touched?.start && Boolean(errors?.start)}
                          helperText={touched?.start && errors?.start}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      disabled={
                        promotionData && promotionData.status != "draft"
                      }
                      sx={{ width: "100%" }}
                      slotProps={
                        errors.end && !!touched.end
                          ? {
                              textField: {
                                error: !!touched.end && errors.end,
                                helperText: errors.end,
                              },
                            }
                          : null
                      }
                      format="DD/MM/YYYY"
                      label="End Date"
                      name="end"
                      minDate={
                        values.start
                          ? dayjs(values.start).add(0, "day")
                          : dayjs().add(0, "day")
                      }
                      value={values.end ? dayjs(values.end) : null}
                      onChange={(newValue) =>
                        setFieldValue(
                          "end",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      // disabled={!values.brandStoreId}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={touched?.end && Boolean(errors?.end)}
                          helperText={touched?.end && errors?.end}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={promotionData && promotionData.status != "draft"}
                  control={
                    <Checkbox
                      name="customTime"
                      checked={values.customTime}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFieldValue("customTime", checked);
                        setIsCustomTime(checked);
                        if (!checked) {
                          setFieldValue("startTime", "00:01");
                          setFieldValue("endTime", "23:59");
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="Select Custom Time"
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      sx={{ width: "100%" }}
                      slotProps={
                        errors.startTime && touched.startTime
                          ? {
                              textField: {
                                error: !!touched.startTime && errors.startTime,
                                helperText: errors.startTime,
                              },
                            }
                          : null
                      }
                      label="Start Time"
                      name="startTime"
                      value={
                        values.customTime
                          ? values.startTime
                            ? dayjs(values.startTime, "HH:mm")
                            : null
                          : dayjs("00:01", "HH:mm")
                      }
                      disabled={
                        !values.customTime ||
                        (promotionData && promotionData.status != "draft")
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          "startTime",
                          dayjs(newValue).format("HH:mm")
                        );
                        setFieldTouched("startTime", true, false); // Changed
                        validateField("startTime");
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      // disabled={!values.brandStoreId || !values.start || !values.end}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      sx={{ width: "100%" }}
                      slotProps={
                        errors.endTime && touched.endTime
                          ? {
                              textField: {
                                error: !!touched.endTime && errors.endTime,
                                helperText: errors.endTime,
                              },
                            }
                          : null
                      }
                      label="End Time"
                      name="endTime"
                      value={
                        values.customTime
                          ? values.endTime
                            ? dayjs(values.endTime, "HH:mm")
                            : null
                          : dayjs("23:59", "HH:mm") // Default end time
                      }
                      disabled={
                        !values.customTime ||
                        (promotionData && promotionData.status != "draft")
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          "endTime",
                          dayjs(newValue).format("HH:mm")
                        );
                        setFieldTouched("endTime", true, false);
                        validateField("endTime");
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={touched.eventDate && Boolean(errors.eventDate)}
                          helperText={touched.eventDate && errors.eventDate}
                        />
                      )}
                      // disabled={!values.brandStoreId || !values.start || !values.end}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="promoDesc"
                  label="Promo Description"
                  fullWidth
                  error={touched.promoDesc && !!errors.promoDesc}
                  helperText={touched.promoDesc && errors.promoDesc}
                  disabled={promotionData && promotionData.status != "draft"}
                />
              </Grid>
              {/* {JSON.stringify(errors)} */}
              <Grid
                container
                xs={12}
                sx={{ mt: 2 }}
                flexDirection={"row-reverse"}
                gap={3}
              >
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Next
                </MDButton>
                <MDButton
                  color="dark"
                  variant="outlined"
                  circular={true}
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PromotionForm;
