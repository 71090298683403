import React, { createContext, useContext, useRef, useState,useEffect } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
// import StarIcon from '@mui/icons-material/Star';
// import { ReactComponent as StarIcon } from "assets/images/StarIcon.svg";
import { Add as AddIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import StoreAgreementLandingPage from "./storeAgreementLandingPage";
import StoreAgreementForm from "./storeAgreementForm";

export const GridContext = createContext();
export const useGridContext = () => useContext(GridContext);

function StoreAgreement({ brandData, tabValue, setTabValue }) {
  const gridRef = useRef(); // Create the ref for the grid
  const [open, setOpen] = useState(false);
  const [selectedStoreAgreement, setSelectedStoreAgreement] = useState(null);

  const handleRowClick = (params) => {
    setSelectedStoreAgreement(params.data);
    handleOpen();
  };
  const ControlSection = ({ user, setOpen, setSelectedStoreAgreement }) =>
    user && ["admin", "broadway-rm"].includes(user?.role) ? (
      <>
        <Grid item xs={12} sm={6} md={6} />
        <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <MDButton
              type="button"
              variant="contained"
              color="black"
              style={{
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: 2,
              }}
              circular={true}
              onClick={() => {
                setOpen(true);
                setSelectedStoreAgreement(null);
              }}
              startIcon={<AddIcon />}
            >
              Assign Store
            </MDButton>
          </Box>
        </Grid>
      </>
    ) : (
      <></>
    );
  const CustomerDrawer = ({ open, handleClose, selectedStoreAgreement }) => {
    useEffect(() => {
      if (open) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
      return () => {
        document.documentElement.style.overflow = "auto";
      };
    }, [open]);
   return (
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "50%" } }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#FFFFFF",
            borderBottom: 1,
            borderColor: "#FAFAFA",
            pb: 3,
            pt: 2,
            pl: "5px",
          }}
        >
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", mx: 2 }}
          >
            <Icon fontSize="large">star</Icon>
            <Typography
              variant="h5"
              fontFamily={"Montserrat"}
              fontSize={24}
              sx={{ ml: 2 }}
            >
              Assign Store
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", minHeight: "70vh" }}>
          <StoreAgreementForm
            agreementData={selectedStoreAgreement}
            handleClose={handleClose}
            brandData={brandData}
          />
        </Box>
      </Drawer>
    );
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  return (
    <GridContext.Provider value={{ externalGridRef: gridRef }}>
      <Grid>
        <Grid container display="flex">
          <Grid item xs={6}>
            <Typography> Store Agreement</Typography>
          </Grid>
          <Grid item xs={6} display="flex">
            <ControlSection setOpen={handleOpen} user={user} setSelectedStoreAgreement={setSelectedStoreAgreement} />
          </Grid>
        </Grid>
        <Grid>
          <StoreAgreementLandingPage
            onRowClick={handleRowClick}
            brandData={brandData}
          />
          {user && ["admin", "broadway-rm"].includes(user?.role) && (
            <CustomerDrawer
              open={open}
              handleClose={handleClose}
              selectedStoreAgreement={selectedStoreAgreement}
            />
          )}
        </Grid>
      </Grid>
    </GridContext.Provider>
  );
}

export default StoreAgreement;
