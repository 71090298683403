import React, { useContext, useEffect } from "react";

// @mui material components
import { Tabs, Tab, Box } from "@mui/material";

// Material Dashboard 2 React Components
import MDTabPanel from "components/MDTabPanel";

// Custom Components
import PromotionForm from "./promotionForm";
import Conditions from "./conditions";
import AssortmentForm from "./assortmentForm";
import { PromotionFormContext } from "./index";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PromotionTabs({
  handleClose,
}) {
  const { tabValue, setTabValue, setPromotionId } = useContext(PromotionFormContext)

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(()=>{
    console.log("TAB CHANGE", tabValue);
  },[tabValue])

  const closeDrawer = () => {
    setPromotionId(null);
    handleClose();
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="promotions-tabs"
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Assortment" {...a11yProps(1)} />
          <Tab label="Conditions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <MDTabPanel value={tabValue} index={0}>
        <PromotionForm
          handleClose={closeDrawer}
          handleChange={handleChange}
        />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <AssortmentForm
          handleClose={closeDrawer}
          handleChange={handleChange}
        />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={2}>
        <Conditions
          handleClose={closeDrawer}
          handleChange={handleChange}
        />
      </MDTabPanel>
    </Box>
  );
}
