import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Chip,
  Divider,
  Drawer,
  Grid,
  Icon,
  Typography,
  Pagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { ReactComponent as NewsIcon } from "assets/images/newsIcon.svg";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import ProductCategoryFormComponent from "./ProductCategoryForm";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "services/product-category-service";
import { dashboardServiceClient } from "graphql/client";
import ImageCarousel from "./imageCarousel";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";
import MDButton from "components/MDButton";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import axios from "axios";
import { ExportIcon } from "layouts/brands";

function buildTree(data) {
  const categories = data?.categoryProduct?.results || [];
  const map = {};

  categories.forEach((category) => {
    map[category.id] = { ...category, children: [] };
  });

  const tree = [];

  categories.forEach((category) => {
    if (category.parent) {
      if (map[category.parent.id]) {
        map[category.parent.id].children.push(map[category.id]);
      }
    } else {
      tree.push(map[category.id]);
    }
  });

  return tree;
}

function searchTree(items, searchKeyword) {
  if (!searchKeyword) return items;

  const filteredItems = items.reduce((acc, item) => {
    if (item.title.toLowerCase().includes(searchKeyword.toLowerCase())) {
      acc.push(item);
    } else if (item.children) {
      const filteredChildren = searchTree(item.children, searchKeyword);
      if (filteredChildren.length) {
        acc.push({ ...item, children: filteredChildren });
      }
    }
    return acc;
  }, []);

  return filteredItems;
}
function handleExportToExcel() {
  // const traverseTree = (node, level, result) => {
  //   // Add the current node to the result array with the level
  //   const nodeWithLevel = { level, ...node };
  //   delete nodeWithLevel.parent
  //   delete nodeWithLevel.children
  //   result.push({
  //     ...nodeWithLevel,
  //     image: JSON.stringify(nodeWithLevel.image?.map(img=>img?.publicurl))
  //     // categoryProductAttribute: JSON.stringify(
  //     //   nodeWithLevel.categoryProductAttribute.map((cpa) => cpa.id)
  //     // ),
  //   });

  //   // Recursively traverse each child node
  //   if (node.children && node.children.length > 0) {
  //     node.children.forEach(child => {
  //       traverseTree(child, level + 1, result);
  //     });
  //   }
  // };
  // const treeData = buildTree(data)
  // const result = [];
  // treeData.forEach(rootNode => {
  //   traverseTree(rootNode, 0, result);
  // });

  // const worksheet = XLSX.utils.json_to_sheet(result);
  // const workbook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // XLSX.writeFile(workbook, "product-category.xlsx");
  axios({
    url:
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/export-product-category`
        : `dashboard-service/export-product-category`,
    method: "post",
    // url: "http://localhost:3002/export-product-category",
    responseType: "blob",
    data: { take: 99999, skip: 0, search: "", filter: {isDeleted: false} },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "product-category.xlsx"); // 设置下载文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("error:", error);
    });
}
function ProductCategories() {
  const [open, setOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [productCategoryList, setProductCategoryList] = useState(null);
  const [fullCategoryData, setFullCategoryData] = useState(null);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const [isNewForm, setIsNewForm] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
      },
      fetchPolicy: "network-only",
    },
  });
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(fullCategoryData)) {
      setFullCategoryData(data);
      setProductCategoryList(buildTree(data));
    }
  }, [data]);

  useEffect(() => {
    if (fullCategoryData) {
      const filteredData = searchKeyword
        ? searchTree(buildTree(fullCategoryData), searchKeyword)
        : buildTree(fullCategoryData);
      setProductCategoryList(filteredData);

      if (searchKeyword) {
        const expandedIds = new Set();
        const collectExpandedIds = (items) => {
          items.forEach((item) => {
            expandedIds.add(item.id);
            if (item.children) {
              collectExpandedIds(item.children);
            }
          });
        };
        collectExpandedIds(filteredData);
        setExpandedItems([...expandedIds]);
      } else {
        setExpandedItems([]);
      }
    }
  }, [searchKeyword, fullCategoryData]);

  const handleNodeToggle = (event, nodeIds) => {
    setExpandedItems(nodeIds);
  };

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const handleNodeClick = (item) => {
    setCategoryData(item);
    setIsNewForm(false);
    setOpen(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsNewForm(false);
  };
  const treeData = productCategoryList || [];
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <Grid container spacing={2}>
        <ControlSection
          handleOpen={handleOpen}
          setCategoryData={setCategoryData}
          setIsNewForm={setIsNewForm}
        />
        <TreeView
          aria-label="category tree"
          slots={{
            expandIcon: AddIcon,
            collapseIcon: RemoveIcon,
          }}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleNodeToggle}
          sx={{
            backgroundColor: "white !important",
            width: "100%",
            marginTop: "15px",
            borderRadius: "10px",
          }}
          // defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {treeData.map((item) => (
            <RenderTree
              item={item}
              key={item.id}
              onNodeClick={handleNodeClick}
            />
          ))}
        </TreeView>
        <CategoryDrawer
          open={open}
          handleClose={handleClose}
          categoryData={categoryData}
          categoryList={data.categoryProduct.results}
          refetch={refetch}
          isNewForm={isNewForm}
          setIsNewForm={setIsNewForm}
          setCategoryData={setCategoryData}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          margin: "20px 0",
        }}
      ></Box>
    </DashboardLayout>
  );
}

const RenderTree = ({ item, onNodeClick }) => {
  if (!item || !item.id) {
    return null; // Skip rendering items with undefined id
  }
  return (
    <TreeItem
      itemId={item.id}
      sx={{ margin: "10px", borderBottom: "1px solid #EAEAEA" }}
      label={
        <Typography
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onNodeClick(item);
          }}
          sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
        >
          <Chip
            size="small"
            sx={{ marginRight: "8px", width: "10px", height: "10px" }}
            color={item.isDeleted ? "error" : "success"}
          />
          {item?.title}
        </Typography>
      }
      expandIcon={<AddIcon />}
      collapseIcon={<RemoveIcon />}
    >
      {Array.isArray(item.children)
        ? item.children.map((child) => (
            <RenderTree
              key={child?.id}
              item={child}
              onNodeClick={onNodeClick}
            />
          ))
        : null}
    </TreeItem>
  );
};
const ControlSection = ({ handleOpen, setCategoryData, setIsNewForm }) => (
  <>
    <Grid item xs={12} sm={6} md={6} ><Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Product Category</Typography> </Grid>
    <Grid item xs={12} sm={6} md={6} textAlign="right">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        gap={2}
      >
        {/* <Icon sx={{ mr: 2 }} component={SearchIcon} fontSize="large" /> */}
        <MDButton
          type="button"
          variant="outlined"
          color="black"
          style={{
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: 500,
            ml: 2,
            backgroundColor:"#fff"
          }}
          circular={true}
          startIcon={<ExportIcon />}
          onClick={() => {
            handleExportToExcel();
          }}
        >
          Export
        </MDButton>
        <MDButton
          type="button"
          variant="contained"
          color="black"
          style={{
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: 500,
          }}
          circular={true}
          startIcon={<AddIcon />}
          onClick={() => {
            setCategoryData(null);
            handleOpen();
            setIsNewForm(true);
          }}
        >
          New Product Category
        </MDButton>
      </Box>
    </Grid>
  </>
);

const CategoryDrawer = ({
  open,
  handleClose,
  categoryData,
  categoryList,
  refetch,
  isNewForm,
  setIsNewForm,
  setCategoryData,
}) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={handleClose}
    PaperProps={{ sx: { width: "60%" } }}
  >
    <Grid
      container
      sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
    >
      <Grid item xs={12}>
        &nbsp;
      </Grid>
      <Grid item xs={12}>
        <Icon sx={{ mr: 2, ml: 2 }} component={NewsIcon} fontSize="large" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ ml: 2 }}>
          Product Categories
        </Typography>
      </Grid>
    </Grid>
    <Box sx={{ width: "100%", minHeight: "70vh", padding: "20px" }}>
      <ProductCategoryFormComponent
        handleClose={handleClose}
        categoryList={categoryList}
        categoryData={categoryData}
        refetch={refetch}
        isNewForm={isNewForm}
      />
    </Box>
  </Drawer>
);

export default ProductCategories;
