import React from 'react';
//  MUI Components
import { Grid, Typography, TextField } from '@mui/material';
// Custom Components
import SelectComponent from './selectComponent'; 

const ItemLevelDiscountForm = ({ values, setFieldValue, errors, touched, disabled=false }) => {
  return (
    <>
      <Grid item xs={12}>
        <SelectComponent
          disabled={disabled}
          label="Promotion Trigger"
          name="promotionTrigger"
          value={values.promotionTrigger}
          options={[
            { value: "directPromotion", label: "Direct Promotion" },
            { value: "couponBased", label: "Coupon Based" },
          ]}
          setFieldValue={setFieldValue}
          error={errors.promotionTrigger}
          helperText={errors.promotionTrigger}
          touched={touched.promotionTrigger}
        />
      </Grid>

      {values.promotionTrigger == "couponBased" && (
        <Grid item xs={6}>
          <TextField
          fullWidth
            name="voucherQuantity"
            label="Voucher Quantity"
            type="number"
            minValue={1}
            value={values.voucherQuantity}
            onChange={(e) => {
              const value = e.target.value;
              // Allow only positive numbers and prevent "e" or negative values
              if (/^\d*\.?\d*$/.test(value)) {
                setFieldValue("voucherQuantity", value);
              }
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => {
              // Prevent input of ".", "-", "e", or any non-numeric keys
              if ([".", "-", "e", "E"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            disabled={disabled}
            error={Boolean(errors.voucherQuantity)}
            helperText={errors.voucherQuantity}
            touched={touched.voucherQuantity}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <SelectComponent
          disabled={disabled}
          label="Promotion Type"
          name="promotionType"
          value={values.promotionType}
          options={[
            { value: "qntDirectDisc", label: "Quantity Based Direct Discount" },
          ]}
          setFieldValue={setFieldValue}
          error={errors.promotionType}
          helperText={errors.promotionType}
          touched={touched.promotionType}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "600" }}
          gutterBottom
        >
          Buy Specification
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <SelectComponent
          disabled={disabled}
          label="Condition Type"
          name="conditionType"
          value={values.conditionType}
          options={[
            { value: "minQntPurchase", label: "Minimum Quantity For Purchase" },
            { value: "anyQntPurchase", label: "Any Quantity For Purchase" },
          ]}
          error={errors.conditionType}
          helperText={errors.conditionType}
          touched={touched.conditionType}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setFieldValue("conditionType", selectedValue);
            if (selectedValue === "anyQntPurchase") {
              setFieldValue("quantity", 1);
            } else {
              setFieldValue("quantity", "");
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="quantity"
          label="Quantity"
          type="number"
          minValue={1}
          value={values.quantity}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only positive numbers and prevent "e" or negative values
            if (/^\d*\.?\d*$/.test(value)) {
              setFieldValue("quantity", value);
            }
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) => {
            // Prevent input of ".", "-", "e", or any non-numeric keys
            if ([".", "-", "e", "E"].includes(e.key)) {
              e.preventDefault();
            }
          }}
          disabled={disabled || values.conditionType === "anyQntPurchase"}
          error={Boolean(errors.quantity)}
          helperText={errors.quantity}
          touched={touched.quantity}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "600" }}
          gutterBottom
        >
          Get Specification
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectComponent
          disabled={disabled}
          label="Benefit Type"
          name="benifitType"
          value={values.benifitType}
          options={[{ value: "flatDiscount", label: "Flat Discount" }]}
          setFieldValue={setFieldValue}
          error={errors.benifitType}
          helperText={errors.benifitType}
          touched={touched.benifitType}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectComponent
          disabled={disabled}
          label="Discount Type"
          name="discountType"
          value={values.discountType}
          options={[
            { value: "percentageDiscount", label: "Percentage Discount" },
            { value: "priceDiscount", label: "Price Discount" },
            { value: "fixedPrice", label: "Fixed Price" },
          ]}
          error={errors.discountType}
          helperText={errors.discountType}
          touched={touched.discountType}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setFieldValue("discountType", selectedValue);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={disabled}
          name="value"
          label={
            values?.discountType === "percentageDiscount" ? "Percent" : "Value"
          }
          value={values.value}
          onChange={(e) => setFieldValue("value", e.target.value)}
          error={Boolean(errors.value)}
          helperText={errors.value}
          touched={touched.value}
          sx={{ width: "100%", whiteSpace: "wrap" }}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat" }}>
          {values.discountType === "percentageDiscount"
            ? "% Discount on "
            : values.discountType === "priceDiscount"
            ? "Discount on "
            : ""}
        </Typography>
      </Grid>
      {values.discountType !== "fixedPrice" ? (
        <Grid item xs={7}>
          <SelectComponent
            disabled={disabled}
            label="Price"
            name="price"
            value={values.price}
            options={[
              { value: "mrp", label: "Applicable On MRP" },
              { value: "rsp", label: "Applicable on SP" },
            ]}
            error={errors.price}
            helperText={errors.price}
            touched={touched.price}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setFieldValue("price", selectedValue);
            }}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default ItemLevelDiscountForm;
