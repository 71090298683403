// This Is Select Component Which We Are Using In Multiple Promotion Form.

import React from 'react';
// MUI Components

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
// Formik Imports
import { Field } from 'formik';
// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SelectComponent = ({
  label,
  name,
  value,
  options,
  setFieldValue,
  error,
  helperText,
  touched,
  onChange,
  disabled=false
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Field
        as={Select}
        labelId={`${name}-label`}
        name={name}
        value={value}
        label={label}
        IconComponent={() => <ArrowDropDownIcon style={{ marginRight: '18px' }} />}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            setFieldValue(name, e.target.value);
          }
        }}
        error={touched && Boolean(error)}
        sx={{ height: 40 }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
      {touched && error && (
        <Typography fontSize={12} color="error">
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default SelectComponent;
