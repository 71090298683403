import React, { useEffect, useState, createContext, useContext } from "react";

// Apollo Client
import { useLazyQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { reportServiceClient } from "graphql/client";

// @mui material components
import { Button, Grid, TextField, Typography } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

// Graphql queries & mutations
import {
  GET_EVENT_REPORT,
  GET_SALES_REPORT,
  GET_RETURN_AND_REFUND_REPORT,
  GET_STOCK_REPORT,
  GET_GMV_REPORT,
  GET_FOOTFALL_REPORT,
} from "services/report-service";

// Images and Icon
import { Add as AddIcon } from "@mui/icons-material";

// Custom Components
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import ReportTabs from "./reportTabs";
import StatsCards from "./statsCards";
import MDButton from "components/MDButton";

// Custom Context
import { useNotification, AuthContext } from "context";

// Custome Libraries
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as XLSX from "xlsx";
import axios from "axios";
import StoreDropdown from "./storeDropdrown";
import { ExportIcon } from "layouts/brands";

// Setting up default timezone for dayjs operations
dayjs.extend(utc);
dayjs.extend(timezone);

// Custom Context Creation
export const ReportsFilter = createContext();

function Reports() {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [tabValue, setTabValue] = useState(0);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [key, setKey] = useState(new Date());
  const { setNotification } = useNotification();
  const [reportType, setReportType] = useState("");
  const [downloadingCycleCount, setDownloadingCycleCount] = useState(false);
  const [selectStore, setSelectStore] = useState(null);
  const [selectedEntrance, setSelectedEntrance] = useState([]);
  const { user } = useContext(AuthContext);
  const [isDateManuallyChanged, setIsDateManuallyChanged] = useState(false);


  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem("token");
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss");

    // Base URL construction based on environment
    let url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/footfall-report/export`
        : `/dashboard-service/footfall-report/export`;

    // Append query parameters conditionally
    const queryParams = new URLSearchParams({
      fromDate: formattedStartDate,
      tillDate: formattedEndDate,
    });

    if (selectStore) {
      queryParams.append("storeId", selectStore);
    }

    if (selectedEntrance?.length > 0) {
      queryParams.append("entranceNames", JSON.stringify(selectedEntrance));
    }

    url += `?${queryParams.toString()}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {

        const currentDate = dayjs().format("DD-MM-YYYY");
        const filename = `Footfall Report ${currentDate}.xlsx`;

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `footfallReport.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download Report");
      }
    } catch (error) {
      console.error("Error:", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    if (user && !["admin", "broadway-rm"].includes(user.role)) {
      setSelectedBrand(user?.userId);
    }
  }, [user]);

  const [
    getGmvReport,
    {
      loading: gmvLoading,
      data: gmvData,
      error: gmvError,
      refetch: gmvRefetch,
    },
  ] = useLazyQuery(GET_GMV_REPORT, {
    client: dashboardServiceClient,
  });

  const [
    getSalesReport,
    {
      loading: salesLoading,
      data: salesData,
      error: salesError,
      refetch: salesRefetch,
    },
  ] = useLazyQuery(GET_SALES_REPORT, {
    client: reportServiceClient,
  });

  const [
    getReturnRefundReport,
    {
      loading: returnLoading,
      data: returnData,
      error: returnError,
      refetch: returnRefetch,
    },
  ] = useLazyQuery(GET_RETURN_AND_REFUND_REPORT, {
    client: dashboardServiceClient,
  });

  const [
    getStockReport,
    {
      loading: stockLoading,
      data: stockData,
      error: stockError,
      refetch: stockRefetch,
    },
  ] = useLazyQuery(GET_STOCK_REPORT, {
    client: reportServiceClient,
  });

  const [
    getEventReport,
    {
      loading: eventLoading,
      data: eventData,
      error: eventError,
      refetch: eventRefetch,
    },
  ] = useLazyQuery(GET_EVENT_REPORT, {
    client: dashboardServiceClient,
  });

  const [
    getFootFallReport,
    {
      loading: footfallLoading,
      data: footfallData,
      error: footfallError,
      refetch: footfallRefetch,
    },
  ] = useLazyQuery(GET_FOOTFALL_REPORT, {
    client: dashboardServiceClient,
    fetchPolicy: "no-cache",
  });

  const handleStartDateChange = (newValue) => {

    setIsDateManuallyChanged(true);

    if (newValue === null || newValue?.isAfter(dayjs())) {
      setStartDateError("Please Enter Valid Start date.");
      setStartDate(null); // Set to null if the date is invalid or cleared
    } else {
      setStartDate(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"));
      setStartDateError(null);

      // Automatically adjust the end date to be within one month of the start date
      const newEndDate = dayjs(newValue).add(6, "month").isAfter(dayjs())
        ? dayjs() // If one month from startDate is in the future, set endDate to today
        : dayjs(newValue).add(6, "month");
      setEndDate(newEndDate?.format("YYYY-MM-DDTHH:mm:ss"));
      setEndDateError(null);
    }
  };

  const handleExportToExcel = () => {
    const flatten = (obj, parent = "", res = {}) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const propName = parent ? `${parent}.${key}` : key;
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach((item, index) => {
                flatten(item, `${propName}[${index}]`, res);
              });
            } else {
              flatten(obj[key], propName, res);
            }
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    const flattenedData = footfallDataRows.map((item) => flatten(item));
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const handleEndDateChange = (newValue) => {
    if (newValue === null) {
      setEndDate(null); // Set to null if the date is cleared
      setEndDateError(null);
    }
    setIsDateManuallyChanged(true);

    const maxAllowedEndDate = dayjs(startDate, "YYYY-MM-DDTHH:mm:ss").add(
      6,
      "month"
    ); // Maximum allowed end date is 1 month from start date
    if (
      dayjs(newValue).isAfter(maxAllowedEndDate) ||
      dayjs(newValue).isAfter(dayjs()) ||
      dayjs(newValue).isBefore(startDate)
    ) {
      setEndDateError(
        "End date must be within one month of the start date and cannot be a future date."
      );
    } else {
      setEndDate(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"));
      setEndDateError(null);
    }
  };

  useEffect(() => {
    setSelectStore(null);
    setSelectedEntrance("");
  }, [tabValue]);

  useEffect(() => {
    // Apply default range for tab cycle count when the user hasn't manually changed dates
    if (tabValue === 6 && !isDateManuallyChanged) {
      setStartDate(dayjs().subtract(1, "week").startOf("day").format("YYYY-MM-DDTHH:mm:ss"));
    }
    else if(tabValue !== 6 && !isDateManuallyChanged){
      setStartDate(dayjs().startOf("day"))
    }
  }, [tabValue, isDateManuallyChanged]);

  useEffect(() => {
    setKey(new Date()); // TODO: need a way to refresh data without key change
    switch (tabValue) {
      case 0: // sales-purchase report
      case 1: // sales-return report
        getSalesReport({
          variables: {
            startDate:
              dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss") ||
              dayjs().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            endDate:
              dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss") ||
              dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            orderType:
              tabValue === 0 ? "PURCHASE" : tabValue === 1 ? "RETURN" : "",
            filter: {
              brandId: selectedBrand || null,
              storeId: selectedStore || null,
            },
          },
          onCompleted: (response) =>
            console.log(`Completed:`, response?.getSalesReports?.results),
          onError: (error) => console.log(`Error: `, error),
        });
        return setReportType("sales-report");
      case 2: // footfall report
        const formattedStartDate = dayjs(startDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss");
        return getFootFallReport({
          variables: {
            listFootfallFilter: {
              fromDate: formattedStartDate,
              tillDate: formattedEndDate,
              storeId: selectStore || null,
              entranceNames:
                (selectedEntrance &&
                  selectedEntrance.length &&
                  selectedEntrance) ||
                [],
            },
          },
        });
      case 3:
        getStockReport({
          variables: {
            startDate:
              dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss") ||
              dayjs().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            endDate:
              dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss") ||
              dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            filter: {
              brandId: selectedBrand || null,
              storeId: selectedStore || null,
            },
          },
          onCompleted: (response) =>
            console.log(`Completed:`, response?.getStockReports?.results),
          onError: (error) => console.log(`Error: `, error),
        });
        return setReportType("stock-report");
      case 4: // event report
      case 5:
      case 6:
        return console.warn("Invalid report type yet to activate");
      default:
        console.error(
          "OutofRange: tab value is not active or present: ",
          tabValue
        );
    }
  }, [
    tabValue,
    selectedBrand,
    selectStore,
    startDate,
    endDate,
    selectedEntrance,
  ]);

  const footfallArray = footfallData;

  const gmvDataRows = gmvData?.getGmvReport?.data ?? []; // * not required for now
  const salesDataRows = salesData?.getSalesReports?.results ?? []; // * will be used in sales purchase report
  const returnRefundDataRows = salesData?.getSalesReports?.results ?? []; // * will be used in sales return report
  const stockDataRows = stockData?.getStockReports?.results ?? []; // * will be used in stock report
  const eventDataRows = eventData?.getEventReports?.data; // * not required for now
  const footfallDataRows = footfallArray; // * will be used in footfall report
  const footfallDataStats = [
    // * this is to show stats card for footfall
    {
      label: "Total Visitors",
      value: footfallData?.Footfalls?.totalFootFall,
      fromTime: dayjs(startDate).format("DD-MM-YYYY"),
      toTime: dayjs(endDate).format("DD-MM-YYYY"),
    },
  ];

  return (
    <DashboardLayout>
      {/* Custom Context Provider to Share Data */}
      <ReportsFilter.Provider
        value={{
          selectedBrand,
          selectedStore,
          startDate,
          endDate,
          tabValue,
          getStockReport,
          getSalesReport,
          key,
        }}
      >
        <Grid container mt={2}>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontFamily: "montserrat",
                color: "#000",
                fontWeight: "700",
              }}
              ml={1}
            >
              Reports
            </Typography>

            {[0, 1, 2, 3, 4, 6].includes(tabValue) && (
              <Grid item xs={10} display="flex" gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={2}>
                    <MobileDateTimePicker
                      label="Start Date & Time"
                      ampm={true} // Enable AM/PM view
                      views={["year", "month", "day", "hours", "minutes"]} // Views with hours and minutes for time selection
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock, // Clock for seconds if needed
                      }}
                      format="DD/MM/YYYY hh:mm A"
                      value={dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")}
                      onChange={handleStartDateChange}
                      maxDate={dayjs()}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(startDateError)}
                          helperText={startDateError || ""}
                        />
                      )}
                    />
                    {startDateError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 1, fontSize: "10px" }}
                      >
                        {startDateError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <MobileDateTimePicker
                      label="End Date & Time"
                      ampm={true} // Enable AM/PM view
                      views={["year", "month", "day", "hours", "minutes"]} // Views with hours and minutes for time selection
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock, // Clock for seconds if needed
                      }}
                      format="DD/MM/YYYY hh:mm A"
                      value={dayjs(endDate, "YYYY-MM-DDTHH:mm:ss")}
                      minDate={dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")}
                      maxDate={
                        dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")
                          .add(6, "month")
                          .isAfter(dayjs())
                          ? dayjs()
                          : dayjs(startDate, "YYYY-MM-DDTHH:mm:ss").add(
                              6,
                              "month"
                            )
                      }
                      disabled={!startDate}
                      onChange={handleEndDateChange}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(endDateError)}
                          helperText={endDateError || ""}
                        />
                      )}
                    />
                    {endDateError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 1, fontSize: "10px" }}
                      >
                        {endDateError}
                      </Typography>
                    )}
                  </Grid>
                </LocalizationProvider>
                {tabValue === 2 && (
                  <Grid item xs={4} display="flex" gap={1}>
                    <StoreDropdown
                      selectStore={selectStore}
                      setSelectStore={setSelectStore}
                      selectedEntrance={selectedEntrance}
                      setSelectedEntrance={setSelectedEntrance}
                    />
                  </Grid>
                )}
                {tabValue === 2 && (
                  <Grid item xs={2} sx={{ textAlign: "end" }}>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="black"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: 500,
                        pb: 2,
                        margin:"0px",
                        backgroundColor:"#fff"
                      }}
                      circular={true}
                      startIcon={<ExportIcon />}
                      onClick={handleDownloadTemplate}
                    >
                      Export
                    </MDButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {[0, 1, 2].includes(tabValue) && (
            <Grid item xs={12} display="flex">
              <StatsCards tabValue={tabValue} displayData={footfallDataStats} />
            </Grid>
          )}

          {tabValue === 6 && (
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                startIcon={<ExportIcon />}
                onClick={async () => {
                  const fileDownloadUrl =
                    process.env.REACT_APP_NODE_ENV === "development"
                      ? `${process.env.REACT_APP_API_BASE_URL}/product-service/cycle-count`
                      : `/product-service/cycle-count`;

                  setDownloadingCycleCount(true);
                  const currentDate = dayjs().format("DD-MM-YYYY");
                  const filename = `Cycle Count ${currentDate}.xlsx`;

                  try {
                    const response = await axios({
                      url: fileDownloadUrl,
                      method: "POST",
                      data: {
                        startDate: new Date(startDate).getTime(),
                        endDate: new Date(endDate).getTime(),
                      },
                      responseType: "blob", // important
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                    });

                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  } catch (e) {
                    const responseData = e.response?.data;

                    if (responseData) {
                      //text/html blob to text
                      const reader = new FileReader();
                      reader.readAsText(responseData);
                      reader.onloadend = function () {
                        const error = reader.result;
                        setNotification({
                          color: "error",
                          isVisible: true,
                          message: error || "Something went wrong",
                        });
                      };
                    } else {
                      setNotification({
                        color: "error",
                        isVisible: true,
                        message: e?.message || "Something went wrong",
                      });
                    }

                    console.log({ e });
                  } finally {
                    setDownloadingCycleCount(false);
                  }
                }}
                disabled={downloadingCycleCount}
                style={{
                  borderRadius: "10rem",
                  borderColor: "black",
                  backgroundColor: "white",
                  color:"black",
                  fontWeight:500,
                  marginLeft: "8px",
                  fontFamily:"Montserrat"
                }}
                variant="outlined"
              >
                {downloadingCycleCount ? "Downloading..." : "Export"}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} mt={2}>
            <ReportTabs
              loading={salesLoading}
              tabValue={tabValue}
              setTabValue={setTabValue}
              gmvDataRows={gmvDataRows}
              salesDataRows={salesDataRows}
              returnRefundDataRows={returnRefundDataRows}
              stockDataRows={stockDataRows}
              eventDataRows={eventDataRows}
              footfallDataRows={footfallDataRows}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
        </Grid>
      </ReportsFilter.Provider>
    </DashboardLayout>
  );
}

export default Reports;
