import React from 'react';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

/**
 * MDDropdown component renders a dropdown using Material-UI Autocomplete.
 * 
 * @param {Array} options - Array of options to display in the dropdown.
 * @param {Function} setSelected - Function to set the selected option.
 */
function MDDropdown({ options, setSelected }) {
    return (
        <div>
            <Grid item xs={12}>
                <Autocomplete
                    options={options.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(event, newValue) => {
                        setSelected(newValue?.value);
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: 40,
                            px: 2,
                            backgroundColor: "white.main",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select a store"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
        </div>
    );
}

export default MDDropdown;
