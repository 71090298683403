import {
  Box,
  FormControl,
  Icon,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { KeyboardArrowDown } from "@mui/icons-material";
import CustomHeaderCheckbox from "components/MDGrid/custom-header-checkbox";

export const BulkProductStatus = {
  DRAFT: "draft",
  PENDING: "pending",
  PARSEFAILED: "parse failed",
  PARSING: "parsing",
  APPROVED: "approved",
  REJECTED: "rejected",
  DONE: "done",
};

export const BulkPriceUpdateStatus = {
  DRAFT: "draft",
  PENDING: "pending",
  PARSEFAILED: "parse failed",
  PARSING: "parsing",
  APPROVED: "approved",
  REJECTED: "rejected",
  DONE: "done",
};

export const VariantStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DONE: "DONE",
};

export const BulkProductTableHeaderData = [
  { field: "numericId", headerName: "Id" },
  { field: "brand.name", headerName: "Brand Name" },
  { field: "type", headerName: "Type" },
  {
    field: "node",
    headerName: "Node",
  },
  {
    field: "filename",
    headerName: "File Name",
    type: "download",
    downloadUrl: "tranformedFileUrl",
    downloadName: "filename",
    customStyle: {
      color: "blue",
    },
  },

  {
    field: "status",
    headerName: "Status",
    customValueStyle: {
      [BulkProductStatus.DRAFT]: {
        color: "#888888",
        textTransform: "uppercase",
      }, // Grey for draft
      [BulkProductStatus.PENDING]: {
        color: "#FFD700",
        textTransform: "uppercase",
      }, // Gold for pending
      [BulkProductStatus.PARSEFAILED]: {
        color: "#FF6347",
        textTransform: "uppercase",
      }, // Tomato red for parse failed
      [BulkProductStatus.PARSING]: {
        color: "#6495ED",
        textTransform: "uppercase",
      }, // Cornflower blue for parsing
      [BulkProductStatus.APPROVED]: {
        color: "#32CD32",
        textTransform: "uppercase",
      }, // Lime green for approved
      [BulkProductStatus.REJECTED]: {
        color: "#FF4500",
        textTransform: "uppercase",
      }, // Orange red for rejected
      [BulkProductStatus.DONE]: {
        color: "#008000",
        textTransform: "uppercase",
      }, // Green for done
    },
  },
  { field: "message", headerName: "Message" },
  {
    field: "createdByUser.name",
    headerName: "Created By",
  },
  { field: "updated_at", headerName: "Update On", type: "date" },
  {
    field: "action-download",
    downloadUrl: "tranformedFileUrl",
    downloadName: "filename",
    headerName: "Transformed File",
  },
];

export const getBulkProductTableColumnDefs = (setChangeStatusRow) => [
  { field: "numericId", headerName: "Batch Id", filter: false },
  {
    field: "brandName",
    filter: false,
    headerName: "Brand Name",
    valueGetter: (params) => params.data?.brand?.name,
  },
  {
    field: "type",
    headerName: "Type",
    filter: false,
  },
  {
    field: "node",
    headerName: "Node",
    filter: false,
  },
  {
    field: "filename",
    headerName: "File Name",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.tranformedFileUrl;
      const name = params.value;
      return (
        <a href={url} download={name} style={{ color: "blue" }}>
          {name}
        </a>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: false,
    cellRenderer: (params) => {
      const value = params.value;
      const customValueStyle = {
        DRAFT: { color: "#888888", textTransform: "uppercase" },
        PENDING: { color: "#FFD700", textTransform: "uppercase" },
        PARSEFAILED: { color: "#FF6347", textTransform: "uppercase" },
        PARSING: { color: "#6495ED", textTransform: "uppercase" },
        APPROVED: { color: "#32CD32", textTransform: "uppercase" },
        REJECTED: { color: "#FF4500", textTransform: "uppercase" },
        DONE: { color: "#008000", textTransform: "uppercase" },
      };
      return (
        <span style={customValueStyle[value?.toUpperCase()]}>{value}</span>
      );
    },
  },
  { field: "message", headerName: "Message", filter: false },
  {
    field: "createdByUser",
    headerName: "Created By",
    filter: false,
    valueGetter: (params) => {
      return (
        params.data?.createdByUser?.name || params.data?.createdByUser?.username
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Update On",
    filter: false,
    valueFormatter: (params) =>
      params.value ? new Date(params.value).toLocaleString() : "",
  },
  {
    field: "action-download",
    headerName: "Transformed File",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.tranformedFileUrl;
      const name = params.data?.filename;
      const user =
        localStorage.getItem("userDetails") &&
        localStorage.getItem("userDetails") !== "undefined"
          ? JSON.parse(localStorage.getItem("userDetails"))
          : {};
      return (
        // <a href={url} download={name} style={{ color: 'blue' }}>
        //   Download
        // </a>
        <Box display="flex">
          <IconButton
            aria-label="download"
            download={name}
            LinkComponent={"a"}
            href={url}
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          {/* for the time being this button is not dynamic for the action and is for a specific table only */}
          {user?.role === "admin" && (
            <IconButton
              aria-label="change-status"
              onClick={() => {
                setChangeStatusRow({
                  status: params.data?.status,
                  id: params.data?.id,
                });
              }}
            >
              <Icon component={ChangeCircleIcon} fontSize="medium" />
            </IconButton>
          )}
        </Box>
      );
    },
  },
];

export const getSuperAdminBulkProductTableColumnDefs = (setChangeStatusRow) => {
  return [
    { field: "numericId", headerName: "Batch Id", filter: false },
    {
      field: "filename",
      headerName: "File Name",
      filter: false,
      cellRenderer: (params) => {
        const url = params.data?.url;
        const name = params.value;
        return (
          <a href={url} download={name} style={{ color: "blue" }}>
            {name}
          </a>
        );
      },
    },
    {
      field: "createdByUser",
      headerName: "Uploaded By",
      filter: false,
      valueGetter: (params) => {
        return (
          params.data.createdByUser.name || params.data?.createdByUser?.username
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Uploaded On",
      filter: false,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleString("en-In") : "",
    },
    {
      field: "status",
      headerName: "Current Status",
      filter: false,
      cellRenderer: (params) => {
        const value = params.value;

        const statuses = {
          PENDING: ["pending", "draft"],
          APPROVED: ["approved", "parsing"],
          REJECTED: ["rejected"],
          INTERNAL_ISSUES: ["parsefailed"],
          DONE: ["done"],
        };

        const customValueStyle = {
          PENDING: { color: "#FFD700", textTransform: "uppercase" },
          INTERNAL_ISSUES: { color: "#FF6347", textTransform: "uppercase" },
          APPROVED: { color: "#32CD32", textTransform: "uppercase" },
          REJECTED: { color: "#FF4500", textTransform: "uppercase" },
          DONE: { color: "#008000", textTransform: "uppercase" },
        };

        const formattedStatus = Object.keys(statuses).find((key) =>
          statuses[key].includes(value)
        );

        return (
          <span style={customValueStyle[formattedStatus?.toUpperCase()]}>
            {formattedStatus.replaceAll("_", " ")}
          </span>
        );
      },
    },
    { field: "message", headerName: "Update Message", filter: false },
    {
      field: "updatedByUser",
      headerName: "Updated By",
      filter: false,
      valueGetter: (params) =>
        params.data.updatedByUser?.name || params.data.updatedByUser?.username,
    },
    {
      field: "updated_at",
      headerName: "Update On",
      filter: false,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleString("en-In") : "",
    },
    {
      field: "totalRows",
      headerName: "Total Product Count",
      filter: false,
    },
    {
      headerName: "Created in Broadway",
      field: "createdCountInBroadway",
      filter: false,
    },
    {
      headerName: "Created in ERP",
      field: "createdCountInGenysis",
      filter: false,
    },
    {
      field: "action-download",
      headerName: "File",
      filter: false,
      cellRenderer: (params) => {
        const url = params.data?.url;
        const name = params.data?.filename;
        const user =
          localStorage.getItem("userDetails") &&
          localStorage.getItem("userDetails") !== "undefined"
            ? JSON.parse(localStorage.getItem("userDetails"))
            : {};
        return (
          <div
            style={{
              display: "inline-flex",
            }}
          >
            <Box display="flex">
              <IconButton
                aria-label="download"
                download={name}
                LinkComponent={"a"}
                href={url}
              >
                <Icon component={downloadIcon} fontSize="small" />
              </IconButton>
            </Box>
            {user?.role === "admin" && (
              <IconButton
                aria-label="change-status"
                onClick={() => {
                  setChangeStatusRow({
                    status: params.data?.status,
                    id: params.data?.id,
                  });
                }}
              >
                <Icon component={ChangeCircleIcon} fontSize="medium" />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];
};

export const VariantTableHeaderData = [
  {
    field: "articleName",
    headerName: "Node",
    renderCell: (value) => value.replaceAll("-", "/"),
  },
  { field: "skuId", headerName: "SKU id" },
  { field: "ginesys_response.icode", headerName: "Ginesys Item Code" },
  { field: "itemName", headerName: "Item Name" },
  {
    field: "product.brand.name",
    headerName: "Brand Name",
    defaultValue: "N/A",
  },
  {
    field: "imageUrl",
    headerName: "Image",
    renderCell: (value) => {
      return (
        <img
          src={value}
          alt="variant image"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "contain",
            border: "1px solid #ddd",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px #ddd",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/50";
          }}
        />
      );
    },
  },
  {
    field: "availableQty",
    headerName: "Stock",
  },
  {
    field: "status",
    headerName: "G Sync",
    renderCell: (value) => {
      if (value.includes("G_")) {
        let transformedText = value.replace("G_", "");
        transformedText = transformedText.replaceAll("_", " ");
        return transformedText;
      } else return VariantStatus.PENDING;
    },
  },
  { field: "message", headerName: "Message" },
  {
    field: "isSample",
    headerName: "Sample",
    renderCell: (value) => {
      return (
        <span
          style={{
            color: value ? "#0000FF" : "#FF0000",
          }}
        >
          {value ? "YES" : "NO"}
        </span>
      );
    },
  },

  {
    field: "status",
    headerName: "Status",
    renderCell: (value) => {
      const customValueStyle = {
        [VariantStatus.PENDING]: {
          color: "#FFD700",
          textTransform: "uppercase",
        }, // Gold for pending

        [VariantStatus.APPROVED]: {
          color: "#32CD32",
          textTransform: "uppercase",
        }, // Lime green for approved
        [VariantStatus.REJECTED]: {
          color: "#FF4500",
          textTransform: "uppercase",
        }, // Orange red for rejected
        [VariantStatus.DONE]: {
          color: "#008000",
          textTransform: "uppercase",
        }, // Green for done
      };

      if (value.includes("G_")) {
        return (
          <span style={customValueStyle[VariantStatus.APPROVED]}>
            {VariantStatus.APPROVED}
          </span>
        );
      } else {
        return <span style={customValueStyle[value]}>{value}</span>;
      }
    },
  },

  {
    field: "broadwayCreatedDate",
    headerName: "Broadway Created Date",
    type: "date",
  },
  { field: "ERPCreatedDate", headerName: "ERP Created Date", type: "date" },
];

export const getVariantTableColumnDefs = (rowSelectCondition, user) => [
  {
    headerName: "",
    field: "checkbox",
    selectCondition: (params) => {
      return rowSelectCondition(params?.data); // Use the provided rowSelectCondition function
    },
    checkboxSelection: true,
    // cellRenderer: CustomCheckboxRenderer,
    headerComponent: CustomHeaderCheckbox, // Adds a checkbox to the header to select/deselect all rows
    maxWidth: 50,
    filter: false,
    hide: user?.role != "admin",
  },
  {
    headerName: "Broadway SKU Id",
    field: "skuId",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Product Name",
    field: "vendorArticleName",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Product Id",
    field: "vendorArticleNumber",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Barcode/EAN",
    field: "barCode",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "ERP Product Id",
    field: "ginesys_response.icode",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      ginesys_response: { code: value.filter },
    }),
    valueGetter: (params) => params.data?.ginesys_response?.icode,
  },
  {
    headerName: "Category Tree",
    field: "articleName",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
    getSortObj: (sortOrder) => ({ firstName: sortOrder }),
    cellRenderer: (params) => {
      const value = params.getValue(params.colDef.field);

      return value ? value.replaceAll("-", "/") : "";
    },
    minWidth: 300,
    pivot: true,
  },
  {
    headerName: "Node",
    field: "category.title",
    filter: false,
  },
  {
    headerName: "MRP",
    field: "productPrice.mrp",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      productPrice: { mrp: value.filter },
    }),
    valueGetter: (params) => params.data?.productPrice?.mrp,
  },
  {
    headerName: "Selling Price",
    field: "productPrice.msp",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      productPrice: { msp: value.filter },
    }),
    valueGetter: (params) => params.data?.productPrice?.msp,
  },
  {
    headerName: "Brand Name",
    filter: "agTextColumnFilter",
    field: "brandName",
    valueGetter: (params) => params.data?.product?.brand?.name,
  },
  {
    headerName: "Vendor Name",
    field: "brand.vendorName",
    filter: false,
    valueGetter: (params) => params.data?.product?.brand?.usp,
  },
  {
    headerName: "ERP Status",
    field: "status",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    cellRenderer: (params) => {
      const value = params.value;
      if (value?.includes("G_")) {
        return value?.replace("G_", "")?.replaceAll("_", " ");
      } else {
        return VariantStatus.PENDING;
      }
    },
  },
  {
    headerName: "ERP Message",
    field: "message",
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Reference Filename",
    field: "bulkProductUpload.filename",

    filter: "agTextColumnFilter",
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      bulkProductUpload: { filename: value.filter },
    }),
    valueGetter: (params) =>
      params.data?.bulkProductUpload?.filename || "No File Associated",
  },
  {
    headerName: "Batch Id",
    field: "bulkProductUpload.numericId",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      bulkProductUpload: { numericId: value.filter },
    }),
    valueGetter: (params) => params.data?.bulkProductUpload?.numericId,
  },
];

export const getBulkPriceUpdateTableColumnDefs = ({
  user,
  handleDeleteClick,
  handleStatusChange,
}) => [
  {
    headerName: "Id",
    field: "id",
  },
  {
    headerName: "Brand",
    field: "brand",
    valueGetter: (params) => params.data?.brand?.name || "N/A",
  },
  {
    headerName: "Excel Name",
    field: "filename",
  },
  {
    field: "status",
    headerName: "Status",
    filter: false,
    cellRenderer: (params) => {
      if (
        (user && user?.role !== "admin") ||
        ["APPROVED", "REJECTED", "PARSEFAILED"].includes(params.value)
      ) {
        const value = params.value;
        const customValueStyle = {
          DRAFT: { color: "#888888", textTransform: "uppercase" },
          PENDING: { color: "#FFD700", textTransform: "uppercase" },
          PARSEFAILED: { color: "#FF6347", textTransform: "uppercase" },
          PARSING: { color: "#6495ED", textTransform: "uppercase" },
          APPROVED: { color: "#32CD32", textTransform: "uppercase" },
          REJECTED: { color: "#FF4500", textTransform: "uppercase" },
          DONE: { color: "#008000", textTransform: "uppercase" },
        };
        return (
          <span style={customValueStyle[value?.toUpperCase()]}>{value}</span>
        );
      }
      return (
        <FormControl fullWidth>
          <Select
            onChange={(e) => handleStatusChange(params.data.id, e.target.value)}
            defaultValue={params.value}
            style={{
              width: "100%",
              padding: 10,
              borderRadius: 6,
              marginTop: 2,
            }}
            id="status-select"
            IconComponent={() => (
              <KeyboardArrowDown sx={{ fontSize: 24, width: 24, height: 24 }} />
            )}
          >
            <MenuItem value="APPROVED">APPROVED</MenuItem>
            <MenuItem value="PENDING">PENDING</MenuItem>
            <MenuItem value="REJECTED">REJECTED</MenuItem>
            <MenuItem value="PARSEFAILED">PARSEFAILED</MenuItem>
          </Select>
        </FormControl>
      );
    },
  },
  {
    field: "gSyncStatus",
    headerName: "ERP-Sync",
    filter: false,
    cellRenderer: (params) => {
      if (
        ["REJECTED", "PARSEFAILED", "PENDING"].includes(params?.data?.status) ||
        params?.value === "SYNCED" ||
        (user && user?.role !== "admin")
      ) {
        const value = params.value;
        const customValueStyle = {
          SYNCED: { color: "#32CD32", textTransform: "uppercase" },
          NOT_SYNCED: { color: "#FF4500", textTransform: "uppercase" },
        };
        return (
          <span style={customValueStyle[value?.toUpperCase()]}>{value}</span>
        );
      }
      return (
        <FormControl fullWidth>
          <Select
            onChange={(e) =>
              handleStatusChange(params.data.id, undefined, e.target.value)
            }
            defaultValue={params.value}
            style={{
              width: "100%",
              padding: 10,
              borderRadius: 6,
              marginTop: 2,
            }}
            id="status-select"
            IconComponent={() => (
              <KeyboardArrowDown sx={{ fontSize: 24, width: 24, height: 24 }} />
            )}
          >
            <MenuItem value="SYNCED">SYNCED</MenuItem>
            <MenuItem value="NOT_SYNCED">NOT SYNCED</MenuItem>
          </Select>
        </FormControl>
      );
    },
  },
  {
    headerName: "Created By",
    field: "createdBy",
  },
  {
    headerName: "Updated By",
    field: "updatedBy",
  },
  {
    headerName: "Uploaded On",
    field: "createDate",
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    disableColumnMenu: true,
    filter: false,
    headerAlign: "left",
    width: 240,
    flex: 2,
    cellRenderer: (params) => (
      <Box sx={{ "& button": { mr: 1 } }}>
        <IconButton
          aria-label="download"
          onClick={
            () => {
              const link = document.createElement("a");
              link.href = params.data.publicUrl;
              link.download = params.data.filename + ".xlsx"; // You can set the default file name for the download here
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
            // handleDownloadClick(params.row.publicUrl, params.row.name)
          }
        >
          <Icon component={downloadIcon} fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteClick(params.data.id)}
        >
          <Icon component={deleteIcon} fontSize="small" />
        </IconButton>
      </Box>
    ),
  },
];

export const getBulkPriceUpdateAuditTableColumnDefs = (queryData, storeMap) => {
  if (queryData && queryData.length > 0) {
    // const allDynamicKeys = queryData.reduce((keys, item) => {
    //   const dynamicKeys = Object.keys(item.dynamicData || {});
    //   dynamicKeys.forEach(key => {
    //     if (!keys.includes(key)) {
    //       keys.push(key);
    //     }
    //   });
    //   return keys;
    // }, []);
    const columnKeys = {
      Vendor: null,
      "Vendor Article Number": null,
      BW_SkuId: null,
      "ERP Item Code": null,
      updatedBy: null,
      updateDate: null,
      __typename: null,
      brand: null,
      id: null,
    };
    const columns = [
      {
        headerName: "Brand",
        field: "Vendor",
      },
      {
        headerName: "Vendor Article Number",
        field: "Vendor Article Number",
      },
      {
        headerName: "BW_SkuId",
        field: "BW_SkuId",
      },
      {
        headerName: "ERP Item Code",
        field: "ERP Item Code",
      },
    ];
    const addedHeaderNames = new Set(); // To keep track of added header names
    queryData.forEach((item) => {
      for (let key in item) {
        // if(["__typename","brand","Vendor","Item Code","Product id","updateDate", "updatedBy"].includes(key)) continue;
        if (columnKeys.hasOwnProperty(key)) continue;
        if (key.endsWith("__RSP")) {
          // Extract the code before "_RSP"
          const storeId = key.split("__RSP")[0];

          // Set headerName to storeMap[storeId] if it exists, otherwise use the key
          const headerName = storeMap[storeId] + "__RSP" || key;
          // Check if the headerName has already been added
          if (!addedHeaderNames.has(headerName)) {
            addedHeaderNames.add(headerName); // Mark this header as added
            columns.push({
              headerName,
              field: key, // Nested field in the row data
            });
          }
        } else {
          // For other keys, push them as usual
          columnKeys[key] = null;
          if (!addedHeaderNames.has(key)) {
            // Check for duplicates
            addedHeaderNames.add(key); // Mark this header as added
            columns.push({
              headerName: key,
              field: key, // Nested field in the row data
            });
          }
        }
      }
    });
    columns.push(
      ...[
        {
          headerName: "Updated On",
          field: "updateDate",
          valueFormatter: (params) => new Date(params.value).toLocaleString(), // Format the date field as a string with date and time
        },
        {
          headerName: "Updated By",
          field: "updatedBy",
        },
      ]
    );
    // const dynamicColumns = allDynamicKeys.map(key => ({
    //   headerName: key,
    //   field: key,  // Nested field in the row data
    // }));
    return columns;
  }
};

export const AvailableTabs = {
  singleProduct: "single",
  nodeWiseBulkProduct: "nodewise-bulk",
  bulkProduct: "bulk",
  bulkPriceUpload: "bulk-price-upload",
  productsPriceList: "products-price-list",
};

export const AvailableForms = AvailableTabs;
