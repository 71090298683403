import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  Box,
  IconButton,
  Switch,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import galleryIcon from "../../../assets/images/gallery-icon.svg";
import addIcon from "../../../assets/images/add-icon.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import { useLazyQuery, useQuery } from "@apollo/client";
import { BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { AvailableForms } from "../productTable/tableData";
import axios from "axios";
import { useNotification } from "context";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import { GET_CATEGORIES } from "services/product-category-service";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import useProductContext from "../context/useProductContext";
import { productServiceClient } from "graphql/client";
import { ALL_PRODUCT_VARIANT_LIST } from "services/product-service";
import useDebounce from "layouts/product-category/useDebounce";
import MDButton from "components/MDButton";

const validationSchema = Yup.object().shape({
  fileUpload: Yup.mixed()
    .required("File is required")
    .test("fileFormat", "Only Excel and CSV files are allowed", (data) => {
      const value = data.file;
      return (
        value &&
        (value.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          value.type === "application/vnd.ms-excel" ||
          value.type === "text/csv")
      );
    }),
  selectOption: Yup.string().required("Select option is required"),
  brandOption: Yup.string(),
  onlineProduct: Yup.boolean(),
});

const initialValues = {
  fileUpload: null,
  selectOption: "",
  brandOption: "",
  onlineProduct: false,
  update: false,
};

export function CustomErrorTextField({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        color: "#F44335",
        marginTop: "3px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
        fontSize: "0.75rem",
        fontWeight: "300",
        lineHeight: "1.25",
        letterSpacing: "0.03333em",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default function AddBulkProduct({ setForm }) {
  const formRef = useRef(null);

  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const isAdmin = user?.role === "admin";

  const { setNotification } = useNotification();
  const { productContext, setProductContext } = useProductContext();

  const [selectOptions, setSelectOptions] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryAttributes, setCategoryAttributes] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState(null);

  const {
    loading: catLoading,
    error: catError,
    data: catData,
    refetch: catRefetch,
  } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
      },
      fetchPolicy: "network-only",
    },
  });
  const getCategoryBreadcrumb = (categoryId) => {
    const allCategories = catData?.categoryProduct?.results;
    const category = allCategories?.find((cat) => cat?.id === categoryId);
    if (!category) return "";

    const breadcrumbs = [];
    let currentCategory = category;

    while (currentCategory) {
      breadcrumbs?.unshift(currentCategory.title);
      currentCategory = allCategories?.find(
        (cat) => cat?.id === currentCategory?.parent?.id
      );
    }

    return breadcrumbs.join(" / ");
  };
  useEffect(() => {
    if (selectedBrand) {
      fetchBrandCateogryMapping();
    }
  }, [selectedBrand]);

  useEffect(() => {
    fetchCategoryAttributeMaster();
  }, [selectedCategory]);

  // * to help with Brand Selections
  const [searachBrandValues, setSearchBrandValues] = useState("");
  const debouncedBrandSearchTerm = useDebounce(searachBrandValues, 300);
  const [
    getBrandList,
    { loading: brandLoading, data: brandData, error: brandError },
  ] = useLazyQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: 20,
      skip: 0,
      search: debouncedBrandSearchTerm,
      filter: isAdmin
        ? { isDeleted: false }
        : {
            username: user?.username,
            isDeleted: false,
          },
    },
  });
  useEffect(() => {
    getBrandList();
  }, []);
  useEffect(() => {
    if (debouncedBrandSearchTerm && debouncedBrandSearchTerm.length > 3) {
      getBrandList();
    }
  }, []);

  const {
    loading: variantLoading,
    data: variantData,
    error: variantError,
  } = useQuery(ALL_PRODUCT_VARIANT_LIST, {
    client: productServiceClient,
    variables: { limit: 10000, skip: 0, brandId: selectedBrand },
  });
  const [
    fetchBrandCateogryMapping,
    { loading: categoryLoading, data: categoryData, error: categoryError },
  ] = useLazyQuery(GET_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    variables: {
      take: 100,
      skip: 0,
      filter: {
        brand: { id: selectedBrand },
        isDeleted: false,
        category: { isNodeItem: true },
      },
    },
    onCompleted: (data) => {
      const category =
        data.brandCategoryStores.results?.map((category) => category) || [];

      setSelectOptions(category);
    },
  });

  const [fetchCategoryAttributeMaster, {}] = useLazyQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    variables: {
      listCategoryProductFilter: {
        take: 100,
        skip: 0,
        filter: { id: selectedCategory, isDeleted: false },
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
      },
    },
    onCompleted: (data) => {
      const attributeMaster = [];

      setCategoryDetails("loading");

      data.categoryProduct.results?.forEach((category) => {
        category.categoryProductAttribute?.forEach((categoryAttribute) => {
          attributeMaster.push({
            attributeId: categoryAttribute.id,
            name: categoryAttribute.masterAttribute.name,
            type: categoryAttribute.attributeType,
            isMandatory: categoryAttribute.isMandatory,
            attributeValues: categoryAttribute.values,
            masterId: categoryAttribute.masterAttribute.id,
            masterValues: categoryAttribute.masterAttribute.values,
          });
        });
      });
      setCategoryDetails(data.categoryProduct.results[0]);
      setCategoryAttributes(attributeMaster);
    },
  });

  const brandOptions =
    !brandLoading && !brandError
      ? brandData?.brandlist?.results.filter(
          (brand) => brand.brandStatus === "Live"
        )
      : [];

  const createBulkProduct = async (data) => {
    const formData = new FormData();
    formData.append("brand", data.brandOption);
    formData.append("category", data.selectOption);
    formData.append("file", data.fileUpload.file);
    formData.append("type", data?.onlineProduct ? "Online" : "Genysis");

    const response = await axios({
      url:
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/product-service/bulk-product`
          : `/product-service/bulk-product`,
      method: data.update ? "patch" : "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formData,
    });
    setProductContext({ ...productContext, addedBulkProduct: true });
    return response;
  };
  const generateExcelbuffer = (data) => {
    /* create a buffer */
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Style the headers for better visibility
    const headerStyle = {
      font: { bold: true },
      fill: { bgColor: { rgb: "FFFF00" } }, // Yellow background to draw attention
    };
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (R === 0) {
          // Assuming header is at row 0
          if (!ws[cell_ref]) ws[cell_ref] = {};
          ws[cell_ref].s = headerStyle;
        }
      }
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* generate XLSX file and send to client */
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "array",
    });
    return wbout;
  };
  const handleTemplteDownload = () => {
    if (!selectedBrand || !selectedCategory) {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Please select a Brand And Category",
      });
    }
    const broadwayBrandExcelTemplate = [
      // * product barcode which we will scan at ginesys
      "Parent Article",
      "Product EAN",
      // * admin will map product category node attribute dynamically to replace for all ammping field
      "cName3", // cName3
      "cName4", // cName4
      "cName5", // cName5
      "cName6", // cName6
      "desc1", // desc1
      "desc2", // desc2
      "desc4", // desc4
      "desc6", // desc6
      "udfString01", // udf1
      "udfString02", // category node code udf2
      "Unit of Measurement",
      "Scan Unit",
      "HSN / SAC Code",
      "Tax Rate",
      "Retail Selling Price",
      "Cost Price",
      "Maximum Retail Price",
      "Item Remarks",
      "Product Image",
      "Variant Image",
      "Short Name",
    ];

    const selectedOption = selectOptions?.find(
      (opt) => opt.category.id === selectedCategory
    ).category.categoryProductAttribute;
    if (!selectedOption) {
      return;
    }
    selectedOption.forEach((attribute) => {
      // * find attribute.fieldname in broadwayBrandExcelTemplate and replace with attribute.name at same index
      const index = broadwayBrandExcelTemplate.indexOf(attribute.fieldName);
      if (index >= 0) {
        broadwayBrandExcelTemplate[index] = attribute.attributeName;
      }
    });
    // Data for the first sheet
    const firstSheetData = [broadwayBrandExcelTemplate.filter((i) => i)];
    const secondSheetHeaders = selectedOption.map((attr) =>
      attr.isMandatory ? `${attr.attributeName} *` : attr.attributeName
    );

    // Initialize rows for the second sheet
    const secondSheetRows = [];
    secondSheetHeaders.forEach((header, colIndex) => {
      const values = selectedOption[colIndex].values;
      values.forEach((value, rowIndex) => {
        if (!secondSheetRows[rowIndex]) {
          secondSheetRows[rowIndex] = new Array(secondSheetHeaders.length).fill(
            ""
          );
        }
        secondSheetRows[rowIndex][colIndex] = value;
      });
    });

    // Combine headers and rows
    const secondSheetData = [secondSheetHeaders, ...secondSheetRows];
    // Data for the new first sheet
    const attributeSheetData = [["Attribute Name", "Description"]];
    selectedOption
      ?.filter((attribute) => attribute?.fieldName !== "online")
      .forEach((attribute) => {
        attributeSheetData.push([
          attribute.attributeName,
          attribute?.masterAttribute?.description || "",
        ]);
      });
    // Create the new worksheet
    const attributeSheet = XLSX.utils.aoa_to_sheet(attributeSheetData);
    // Create the first worksheet
    const firstSheet = XLSX.utils.aoa_to_sheet(firstSheetData);
    // Create the second worksheet
    const secondSheet = XLSX.utils.aoa_to_sheet(secondSheetData);

    attributeSheet["!cols"] = [{ wch: 50 }, { wch: 90 }];
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Append the new sheet to the workbook
    XLSX.utils.book_append_sheet(wb, attributeSheet, "Attributes");
    // Append the first sheet to the workbook
    XLSX.utils.book_append_sheet(wb, firstSheet, "Ginesys Product Template");
    // Append the second sheet to the workbook
    XLSX.utils.book_append_sheet(wb, secondSheet, "Refrence Data");

    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "array",
    });

    if (categoryDetails === "loading") {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Category details are loading, please try after few seconds",
      });
    }

    const division = categoryDetails?.parent?.parent?.parent?.title;
    const section = categoryDetails?.parent?.parent?.title;
    const department = categoryDetails?.parent?.title;
    const node = categoryDetails?.title;
    const brandName = brandOptions.find(
      (brand) => brand.id === selectedBrand
    ).name;

    saveAs(
      new Blob([wbout], {
        type: "application/octet-stream",
      }),
      `${division}-${section}-${department}-${node}-${brandName}.xlsx`
    );
  };
  const handleEcommerceTemplteDownload = async () => {
    if (!selectedBrand || !selectedCategory) {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Please select a Brand And Category",
      });
    }
    const tocken = localStorage.getItem("token");
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/product-service/bulk-product?category=${selectedCategory}&brand=${selectedBrand}`
        : `/product-service/bulk-product?category=${selectedCategory}&brand=${selectedBrand}`;

    if (categoryDetails === "loading") {
      return setNotification({
        color: "error",
        isVisible: true,
        message: "Category details are loading, please try after few seconds",
      });
    }

    setNotification({
      color: "info",
      isVisible: true,
      message:
        "Downloading started in background, please do not close or refresh this page",
    });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tocken}`,
        },
      });

      const division = categoryDetails?.parent?.parent?.parent?.title;
      const section = categoryDetails?.parent?.parent?.title;
      const department = categoryDetails?.parent?.title;
      const node = categoryDetails?.title;
      const brandName = brandOptions.find(
        (brand) => brand.id === selectedBrand
      ).name;

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${division}-${section}-${department}-${node}-${brandName}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download template");
      }

      setNotification({
        color: "success",
        isVisible: true,
        message: "Downloading completed for template",
      });
    } catch (error) {
      console.error(error);
      setNotification({
        color: "error",
        isVisible: true,
        message: "Failed to download template",
      });
    }
  };
  const formSubmitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsSubmittingForm(true);

    try {
      await createBulkProduct(values);
      setNotification({
        color: "success",
        isVisible: true,
        message: "Bulk product created succesfully",
      });
      setForm(false);
    } catch (error) {
      const response = error.response;
      let errorMessage = error?.message;

      if (response) {
        errorMessage = response.data.message;
      }

      setNotification({
        color: "error",
        isVisible: true,
        message: errorMessage,
      });
    } finally {
      setSubmitting(false);
      setIsSubmittingForm(false);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          pb: 2,
          pt: 2,
          pl: "10px",
          pr: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={formSubmitHandler}
            innerRef={formRef}
          >
            {({
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
              handleSubmit,
            }) => {
              useEffect(() => {
                if (isAdmin) return;

                if (brandLoading || brandError || !brandData) return;

                //auto select brand field if the user role is brand_admin
                const userBrandId = brandData?.brandlist?.results?.[0]?.id;
                setSelectedBrand(userBrandId);
                setFieldValue("brandOption", userBrandId);
              }, [brandLoading, brandData, brandError]);

              return (
                <Form
                  id={AvailableForms.nodeWiseBulkProduct}
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                      <FormControl fullWidth disabled={isSubmitting}>
                        <InputLabel>Select Brand</InputLabel>
                        <Field
                          as={Select}
                          name="brandOption"
                          label="Select Brand"
                          fullWidth
                          disabled={!isAdmin}
                          onChange={(event) => {
                            setFieldValue("brandOption", event.target.value);
                            const selectedBrandId = event.target.value;

                            const selectedBrand = brandOptions?.find(
                              (brand) => brand.id === selectedBrandId
                            );

                            setSelectedBrand(selectedBrand.id);
                          }}
                          sx={{
                            height: "40px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#7b809a !important",
                            },
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px" }}
                            />
                          )}
                        >
                          {!brandLoading ? (
                            (brandOptions || ).map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Loading brand...</MenuItem>
                          )}
                        </Field>
                        {touched.brandOption && errors.brandOption && (
                          <CustomErrorTextField>
                            {errors.brandOption}
                          </CustomErrorTextField>
                        )}

                        {brandError && (
                          <CustomErrorTextField>
                            Could not fetch the brands list
                          </CustomErrorTextField>
                        )}
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={12}>
                      <Autocomplete
                        id="tags-standard"
                        name="brand"
                        value={values.brand || null}
                        options={
                          (brandOptions || [])?.map((item) => ({
                            id: item.id,
                            label: `${item.name}`,
                          })) || []
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setFieldValue("brand", newValue);
                          setFieldValue("brandOption", newValue.id);
                          const selectedBrandId = newValue.id;

                          const selectedBrand = brandOptions?.find(
                            (brand) => brand.id === selectedBrandId
                          );

                          setSelectedBrand(selectedBrand.id);
                        }}
                        onInputChange={(event, newValue) => {
                          if (
                            (event && event?.type === "reset") ||
                            newValue === ""
                          ) {
                            setSearchBrandValues(""); // Clear search when input is cleared
                          } else if (event && event.type === "change") {
                            setSearchBrandValues(newValue);
                          }
                        }}
                        onBlur={() => {
                          setSearchBrandValues("");
                        }}
                        loading={brandLoading}
                        loadingText="Loading..."
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Brand"
                            helperText={touched.brand ? errors.brand : ""}
                            error={touched.brand && Boolean(errors.brand)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth disabled={isSubmitting}>
                        <InputLabel>Select Category Node</InputLabel>
                        <Field
                          as={Select}
                          name="selectOption"
                          label="Select Category Node"
                          fullWidth
                          onChange={(event) => {
                            setFieldValue("selectOption", event.target.value);
                            setSelectedCategory(event.target.value);
                          }}
                          sx={{
                            height: "40px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#7b809a !important",
                            },
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px" }}
                            />
                          )}
                        >
                          {!brandLoading ? (
                            values.brandOption ? (
                              selectOptions.map((option) => (
                                <MenuItem
                                  key={option.category.id}
                                  value={option.category.id}
                                >
                                  {getCategoryBreadcrumb(option.category.id)}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>Select a brand first</MenuItem>
                            )
                          ) : (
                            <MenuItem disabled> Loading categories...</MenuItem>
                          )}
                        </Field>
                        {touched.selectOption && errors.selectOption && (
                          <CustomErrorTextField>
                            {errors.selectOption}
                          </CustomErrorTextField>
                        )}

                        {brandError && (
                          <CustomErrorTextField>
                            Could not fetch the category list
                          </CustomErrorTextField>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          startIcon={
                            <img src={downloadIcon} alignItems={"center"} />
                          }
                          component={"label"}
                          disabled={isSubmitting}
                          onClick={handleTemplteDownload}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#000000",
                            }}
                          >
                            Ginesys Product Template
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          startIcon={
                            <img src={downloadIcon} alignItems={"center"} />
                          }
                          component={"label"}
                          disabled={isSubmitting}
                          onClick={handleEcommerceTemplteDownload}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#000000",
                            }}
                          >
                            Online Product Template
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                        }}
                      >
                        Online Product
                      </Typography>
                      <Switch
                        checked={values.onlineProduct}
                        onChange={(event) => {
                          if (values.update) {
                            setFieldValue("update", false);
                          }

                          setFieldValue("onlineProduct", event.target.checked);
                        }}
                        name="onlineProduct"
                        color="primary"
                      />
                      <Tooltip title="Switch On The Toggle To Upload Online Product">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                        }}
                      >
                        Update
                      </Typography>
                      <Switch
                        checked={values.update}
                        onChange={(event) => {
                          if (values.onlineProduct) {
                            setFieldValue("onlineProduct", false);
                          }
                          setFieldValue("update", event.target.checked);
                        }}
                        name="update"
                        color="primary"
                      />
                      <Tooltip title="Switch On The Toggle To Update Products">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="fileUpload">
                        {({ field, form }) => (
                          <Grid
                            container
                            justifyContent={"space-between"}
                            padding={"10px"}
                            sx={{
                              backgroundColor: "#F0F2F5",
                              borderRadius: "10px",
                            }}
                          >
                            <Grid item display={"flex"} alignItems={"center"}>
                              <Icon
                                component={() => <img src={galleryIcon} />}
                                variant="img"
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  marginLeft: "10px",
                                }}
                              >
                                Upload Excel file
                                <Typography
                                  display={"inline"}
                                  variant="h6"
                                  sx={{
                                    fontWeight: "500",
                                    opacity: "0.5",
                                  }}
                                >
                                  (Maximum 1 excel can be uploaded)
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item display={"flex"}>
                              <Button
                                startIcon={
                                  <img src={addIcon} alignItems={"center"} />
                                }
                                component={"label"}
                                htmlFor={"product-excel"}
                                disabled={isSubmitting}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "#E93165",
                                  }}
                                >
                                  CHOOSE FILE
                                </Typography>
                              </Button>
                            </Grid>

                            <input
                              type="file"
                              hidden
                              style={{
                                display: "none",
                              }}
                              accept=".xlsx, .xlx, .csv"
                              multiple
                              id="product-excel"
                              onChange={(event) => {
                                const allowedFileExt = ["xlsx", "xlx", "csv"];
                                const newFiles = Array.from(event.target.files)
                                  .map((file) => {
                                    const ext = file.name.split(".").pop();
                                    if (!allowedFileExt.includes(ext)) {
                                      setFieldError(
                                        field.name,
                                        "Only CSV or Excel files are allowed"
                                      );
                                      return null;
                                    }

                                    const mbToBytes = 1024 * 1024;

                                    if (file.size > 5 * mbToBytes) {
                                      setFieldError(
                                        field.name,
                                        "File size should not exceed 5MB"
                                      );
                                      return null;
                                    }

                                    return {
                                      file,
                                      filename: file.name,
                                    };
                                  })
                                  .filter((file) => file !== null);

                                setFieldValue(field.name, newFiles[0]);

                                event.target.value = "";
                              }}
                            />
                            {errors.fileUpload && (
                              <CustomErrorTextField
                                color="error"
                                sx={{ mt: 1 }}
                              >
                                {errors.fileUpload}
                              </CustomErrorTextField>
                            )}
                            <Box
                              sx={{
                                mt: 2,
                                display: "flex",
                                gap: 2,
                                width: "100%",
                              }}
                            >
                              {values.fileUpload && (
                                <Box
                                  sx={{
                                    width: 100,
                                    height: 100,
                                    objectFit: "cover",
                                    position: "relative",
                                    backgroundImage: `url(https://images.unsplash.com/photo-1658203897339-0b8c64a42fba?q=80&w=3262&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    border: "1px solid #F0F2F5",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                    }}
                                    onClick={() => {
                                      setFieldValue(field.name, null);
                                    }}
                                  >
                                    <img src={deleteIcon} />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        gap={2}
        sx={{ pr: 2, pb: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <MDButton
          variant="outlined"
          // style={{
          //   backgroundColor: "#FFFFFF",
          //   marginRight: "10px",
          //   border: "1px solid #E93165",
          //   borderRadius: "24px",
          //   color: "#E93165",
          // }}
          color={"black"}
          circular={true}
          onClick={() => {
            setForm(false);
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="contained"
          // style={{
          //   background: "#E93165",
          //   borderRadius: "24px",
          //   color: "#fff",
          // }}
          color={"black"}
          circular={true}
          onClick={() => {
            formRef.current.submitForm();
          }}
          disabled={isSubmittingForm}
        >
          {isSubmittingForm ? "Loading..." : "Save"}
        </MDButton>
      </Grid>
    </>
  );
}
