function getAllFilteredRows(gridApi) {
  // const filteredRows = [];
  const checkboxCol = gridApi
    .getColumns()
    .find((col) => col.colId == "checkbox")?.colDef;
  const selectCondition = checkboxCol?.selectCondition;
  // gridApi.getRenderedNodes().forEach((node) => {

  // });
  const currentPage = gridApi.paginationGetCurrentPage();
  const pageSize = gridApi.paginationGetPageSize();
  const startRow = currentPage * pageSize;
  const endRow = startRow + pageSize;

  const rows = [];
  for (let i = startRow; i < endRow; i++) {
    const node = gridApi.getDisplayedRowAtIndex(i);
    if (node) {
      rows.push(node);
    }
  }
  const filteredRows = rows
    .filter((node) => (selectCondition ? selectCondition(node) : true));
  return filteredRows;
}

const CustomHeaderCheckbox = (props) => {
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      props.api.selectAllFiltered();
      getAllFilteredRows(props.api).forEach((node) => node.setSelected(true));
    } else {
      props.api.deselectAll();
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        style={{
          width: 16,
          height: 16,
          cursor: "pointer",
        }}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default CustomHeaderCheckbox;
